export const formatDateTime = (date: Date) => {
  if (!date) {
    return ""
  }
  const hour = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")

  return `${hour}:${minutes}`
}

export const formatDate = (
  date: Date,
  options?: Intl.DateTimeFormatOptions
) => {
  if (!date) {
    return ""
  }

  return date.toLocaleDateString("id", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    ...options,
  })
}

export const getTimestampChat = (unixTime: number) => {
  const dateChat: Date = new Date(unixTime)
  const dateNow: Date = new Date()

  const diffTime: number = Math.abs(dateNow.getTime() - dateChat.getTime())
  const diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  const minutes = Math.ceil(diffTime / (1000 * 60))
  if (minutes < 60) {
    return `${minutes} mnt`
  }

  if (minutes >= 60 && minutes <= 24 * 60) {
    return formatDateTime(dateChat)
  }

  if (minutes > 24 * 60 && minutes <= 24 * 60 * 7) {
    return `${diffDays} hari lalu`
  }

  if (minutes > 24 * 60 * 7) {
    return formatDate(dateChat)
  }
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes"

  const units = ["bytes", "Kb", "Mb", "Gb", "Tb"]
  const dm = decimals < 0 ? 0 : decimals

  let convertedBytes = bytes
  let i = 0

  while (convertedBytes >= 1024 && i < units.length - 1) {
    convertedBytes /= 1024
    i++
  }

  const selectedUnit = units[i]

  if (typeof selectedUnit === "string") {
    return `${convertedBytes.toFixed(dm)} ${selectedUnit}`
  }

  return "0 Bytes"
}

export const getHrefUrl = (url?: string) => {
  if (!url) return ""

  if (!/^http(s)?:\/\//.test(url)) {
    return `https://${url}`
  } else if (url.startsWith("http://")) {
    return url.replace("http://", "https://")
  }
  return url
}

export const isMessageUrl = (message: string) => {
  const newUrl = getHrefUrl(message)

  const urlPattern =
    /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm
  return urlPattern.test(newUrl)
}

export const ALLOWED_DOC_EXTENSIONS = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "zip",
  "ppt",
  "pptx",
]
export const ALLOWED_IMAGE_EXTENSIONS = ["png", "jpg", "jpeg"]
export const ALLOWED_FILE_EXTENSIONS = [
  ...ALLOWED_DOC_EXTENSIONS,
  ...ALLOWED_IMAGE_EXTENSIONS,
]

export const ACCEPTED_INPUT = [
  ".pdf",
  ".xlsx",
  ".doc",
  ".docx",
  ".xls",
  "image/png",
  "image/jpeg",
  "application/zip",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
]

const getHeader = (buffer: ArrayBuffer) => {
  const view = new DataView(buffer)
  let header = ""

  for (let i = 0; i < 4; i++) {
    const byte = view.getUint8(i)
    header += byte.toString(16).padStart(2, "0")
  }

  return header
}

const getMimeType = (header: string) => {
  switch (header) {
    case "89504e47":
      return "image/png"
    case "47494638":
      return "image/gif"
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg"
    case "25504446":
      return "application/pdf"
    case "d0cf11e0":
      return "application/old-document"
    case "504b0304":
      return "application/modern-document"
    default:
      return null
  }
}

export const checkMimeType = async (file: File) => {
  try {
    const reader = new FileReader()
    const CHUNK_SIZE = 8 // Read enough bytes for detection.
    const oldDocumentExtensions = ["doc", "xls", "ppt"]
    const modernDocumentExtensions = ["docx", "xlsx", "pptx", "zip"]

    return new Promise<boolean>((resolve, reject) => {
      reader.onerror = () => {
        reject(new Error("Error reading file."))
      }

      reader.onloadend = () => {
        const header = getHeader(reader.result as ArrayBuffer)
        const mimeType = getMimeType(header)

        const fileExtension =
          file.name.split(".").pop()?.toLowerCase() ?? "unknown"

        switch (mimeType) {
          case "image/jpeg":
            resolve(fileExtension === "jpg" || fileExtension === "jpeg")
            break
          case "image/png":
          case "image/gif":
          case "application/pdf":
            resolve(mimeType.includes(fileExtension))
            break
          case "application/old-document":
            resolve(oldDocumentExtensions.includes(fileExtension))
            break
          case "application/modern-document":
            resolve(modernDocumentExtensions.includes(fileExtension))
            break
          case null:
            resolve(true)
            break
          default:
            resolve(false)
            break
        }
      }

      reader.readAsArrayBuffer(file.slice(0, CHUNK_SIZE))
    })
  } catch (error) {
    return false
  }
}

export const isFile = (filename: string) => {
  const extension = filename.split(".").pop()?.toLowerCase() ?? ""

  if (!ALLOWED_DOC_EXTENSIONS.includes(extension)) return false
  return true
}

export const MAX_FILE_IN_SINGLE_CHAT_UPLOAD = 4
