/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Time: any;
};

export type AcceptAdminTransferInput = {
  institutionId: Scalars['String'];
  isAccepted: Scalars['Boolean'];
};

export type AcceptAdminTransferRes = {
  __typename?: 'AcceptAdminTransferRes';
  status: RoleChangeStatusEnum;
};

export type AcceptAdminTransferResponse = AcceptAdminTransferRes | GenericError;

export type AcceptCompanyMembershipInput = {
  isAccepted: Scalars['Boolean'];
  personaId: Scalars['String'];
};

export type AcceptCompanyMembershipRes = {
  __typename?: 'AcceptCompanyMembershipRes';
  status: Scalars['String'];
};

export type AcceptCompanyMembershipResponse = AcceptCompanyMembershipRes | GenericError;

export type AcceptVerificationInput = {
  personaId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type AcceptVerificationResponse = AcceptVerificationSuccess | GenericError;

export type AcceptVerificationSuccess = {
  __typename?: 'AcceptVerificationSuccess';
  personaId?: Maybe<Scalars['String']>;
  status: VerificationStatus;
  userId: Scalars['String'];
};

export type AccountPagination = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type ActiveRegionPriceResponse = {
  __typename?: 'ActiveRegionPriceResponse';
  data: Array<UserLogisticRegionPriceWithRegionInfo>;
};

export type ActiveRegionPriceResult = ActiveRegionPriceResponse | GenericError;

export type Actor = {
  __typename?: 'Actor';
  personaId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AddBankAccountInput = {
  accountNo?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  isOTP?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['String']>;
};

export type AddBankAccountResponse = Error | SellerBankInfo;

export type AddBuyerAddressResponse = AddBuyerAddressResult | Error;

export type AddBuyerAddressResult = {
  __typename?: 'AddBuyerAddressResult';
  addressAdd?: Maybe<UpsertBuyerAddressId>;
};

export type AddCompanyBankInput = {
  accountNo: Scalars['String'];
  bankCode: Scalars['String'];
  branchName?: InputMaybe<Scalars['String']>;
  institusiId: Scalars['String'];
  isOTP: Scalars['Boolean'];
};

export type AddCompanyBankResponse = CompanyBankInfo | GenericError;

export enum AddOnObjectType {
  Insurance = 'INSURANCE',
  Product = 'PRODUCT',
  Service = 'SERVICE'
}

export type AddOnTotalSummaryInput = {
  ppnPercentage: Scalars['Float'];
  price: Scalars['Float'];
  qty: Scalars['Float'];
};

export enum AddOnType {
  EachOfProduct = 'EACH_OF_PRODUCT',
  GroupOfProduct = 'GROUP_OF_PRODUCT'
}

export type AddPersonaShippingAddressResponse = AddressesType | GenericError;

export type AddRolesResponse = Error | Roles;

export type AddUserAddressResponse = AddUserAddressResult | Error;

export type AddUserAddressResult = {
  __typename?: 'AddUserAddressResult';
  addressAdd: UpsertUserAddressId;
};

export type AddendumAdditionalData = {
  __typename?: 'AddendumAdditionalData';
  clauses?: Maybe<Array<Scalars['String']>>;
  reason?: Maybe<Scalars['String']>;
};

export enum AddendumDetailType {
  AddonPpnPercentage = 'ADDON_PPN_PERCENTAGE',
  AddonQty = 'ADDON_QTY',
  ProductPpnbmPercentage = 'PRODUCT_PPNBM_PERCENTAGE',
  ProductPpnPercentage = 'PRODUCT_PPN_PERCENTAGE',
  ProductQty = 'PRODUCT_QTY',
  ShipmentInsuranceFee = 'SHIPMENT_INSURANCE_FEE',
  ShipmentPpnPercentage = 'SHIPMENT_PPN_PERCENTAGE',
  ShipmentPrice = 'SHIPMENT_PRICE'
}

export type AddendumHistory = {
  __typename?: 'AddendumHistory';
  createdAt: Scalars['Time'];
  createdBy: Scalars['String'];
  notes: Scalars['String'];
  status: AddendumStatus;
};

export type AddendumItem = {
  __typename?: 'AddendumItem';
  componentId: Scalars['ID'];
  componentType: AddendumDetailType;
  newValue: Scalars['Float'];
};

export type AddendumOrderItem = {
  __typename?: 'AddendumOrderItem';
  addendumTypes?: Maybe<Array<AddendumDetailType>>;
  orderItem: OrderItem;
};

export type AddendumShipment = {
  __typename?: 'AddendumShipment';
  addendumTypes?: Maybe<Array<AddendumDetailType>>;
  shipment: OrderShipment;
};

export enum AddendumStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  EsignInProgress = 'ESIGN_IN_PROGRESS',
  New = 'NEW',
  Rejected = 'REJECTED'
}

export type AddendumStatusType = {
  __typename?: 'AddendumStatusType';
  value: AddendumStatus;
};

export type AdditionalFee = {
  __typename?: 'AdditionalFee';
  ppn?: Maybe<OrderPpn>;
  ppnBm?: Maybe<Scalars['Float']>;
};

export type Addon = {
  __typename?: 'Addon';
  DPP: Scalars['Float'];
  PPN: Scalars['Float'];
  name: Scalars['String'];
  ppnPercentage: Scalars['Float'];
  qty: Scalars['Float'];
  subTotal: Scalars['Float'];
};

export type Address = {
  __typename?: 'Address';
  buyerId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  formatAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceId?: Maybe<Scalars['String']>;
  provinceName?: Maybe<Scalars['String']>;
  receiverName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  villageId?: Maybe<Scalars['String']>;
  villageName?: Maybe<Scalars['String']>;
};

export type AddressCheckout = {
  __typename?: 'AddressCheckout';
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  villageAreaCode: Scalars['String'];
};

export type AddressesPublicType = {
  __typename?: 'AddressesPublicType';
  id?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  regionDetail: RegionDetail;
  villageAreaCode: Scalars['String'];
};

export type AddressesType = {
  __typename?: 'AddressesType';
  fullAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isMainAddress: Scalars['Boolean'];
  label: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  receiverName: Scalars['String'];
  regionDetail: RegionDetail;
  villageAreaCode: Scalars['String'];
};

export type AddressesTypeInput = {
  fullAddress: Scalars['String'];
  isMainAddress: Scalars['Boolean'];
  label: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  receiverName: Scalars['String'];
  villageAreaCode: Scalars['String'];
};

export type AdminRequestCancelInput = {
  chargedParties: Array<OrderRole>;
  chargedPartiesDetail?: InputMaybe<Scalars['String']>;
  documentFileName: Scalars['String'];
  documentToken?: InputMaybe<Scalars['String']>;
  escalationDetail?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  reasonCategory: Scalars['String'];
};

export type AdminRequestCancelResponse = {
  __typename?: 'AdminRequestCancelResponse';
  id: Scalars['ID'];
};

export type AktaInfo = {
  __typename?: 'AktaInfo';
  aktaOther?: Maybe<Array<Maybe<AktaOtherInfo>>>;
  aktaPendirian?: Maybe<Array<Maybe<AktaPendirianInfo>>>;
  aktaPerubahan?: Maybe<Array<Maybe<AktaPerubahanInfo>>>;
};

export type AktaOtherInfo = {
  __typename?: 'AktaOtherInfo';
  aktaCreatedAt?: Maybe<Scalars['String']>;
  aktaNo?: Maybe<Scalars['String']>;
  aktaType?: Maybe<Scalars['Int']>;
  aktaTypeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  notaris?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  sikapAktaId?: Maybe<Scalars['Int']>;
  sikapDeleted?: Maybe<Scalars['Boolean']>;
  sourceData?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type AktaPendirianInfo = {
  __typename?: 'AktaPendirianInfo';
  aktaCreatedAt?: Maybe<Scalars['String']>;
  aktaNo?: Maybe<Scalars['String']>;
  aktaType?: Maybe<Scalars['Int']>;
  aktaTypeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  notaris?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  sikapAktaId?: Maybe<Scalars['Int']>;
  sikapDeleted?: Maybe<Scalars['Boolean']>;
  sourceData?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type AktaPerubahanInfo = {
  __typename?: 'AktaPerubahanInfo';
  aktaCreatedAt?: Maybe<Scalars['String']>;
  aktaNo?: Maybe<Scalars['String']>;
  aktaType?: Maybe<Scalars['Int']>;
  aktaTypeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  notaris?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  sikapAktaId?: Maybe<Scalars['Int']>;
  sikapDeleted?: Maybe<Scalars['Boolean']>;
  sourceData?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type AllLogisticResponse = {
  __typename?: 'AllLogisticResponse';
  data?: Maybe<Array<LogisticData>>;
};

export type AllLogisticResult = AllLogisticResponse | GenericError;

export type AllMinifiedProductCategories = {
  __typename?: 'AllMinifiedProductCategories';
  items?: Maybe<Array<MinifiedProductCategory>>;
};

export type AllMinifiedProductCategoryInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  level?: Scalars['Int'];
  parentIds?: InputMaybe<Array<Scalars['ID']>>;
  productType?: InputMaybe<ProductType>;
};

export type AllMinifiedProductCategoryResponse = AllMinifiedProductCategories | GenericError;

export type AllRateResponse = {
  __typename?: 'AllRateResponse';
  data?: Maybe<Array<RateData>>;
};

export type AllRateResult = AllRateResponse | GenericError;

export enum AppType {
  Buyer = 'Buyer',
  Internal = 'Internal',
  Seller = 'Seller'
}

export type Application = {
  __typename?: 'Application';
  appTypeId: Scalars['String'];
  auth0ApplicationType: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  lpseId: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  url: Scalars['String'];
};

export type ApplicationFilterInput = {
  applicationTypeNames?: InputMaybe<Array<ApplicationTypeEnum>>;
  isPilot?: InputMaybe<Scalars['Boolean']>;
};

export type ApplicationList = {
  __typename?: 'ApplicationList';
  applications?: Maybe<Array<Application>>;
};

export enum ApplicationTypeEnum {
  CommandCenter = 'CommandCenter',
  DaftarHitam = 'DaftarHitam',
  EKatalogV5 = 'EKatalogV5',
  EKatalogV6Buyer = 'EKatalogV6Buyer',
  EKatalogV6Vms = 'EKatalogV6Vms',
  Lpse = 'LPSE',
  LpseSupport = 'LPSESupport',
  Sikap = 'SIKAP',
  Sirup = 'SIRUP',
  TokoDaring = 'TokoDaring'
}

export type ApplicationsResponse = ApplicationList | GenericError;

export type ApprovalInput = {
  approvalValue: ApprovalValue;
  orderId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export enum ApprovalLabel {
  BastEsignInProgress = 'BAST_ESIGN_IN_PROGRESS',
  BastGenerated = 'BAST_GENERATED',
  CancelledByBuyer = 'CANCELLED_BY_BUYER',
  Expired = 'EXPIRED',
  RejectedByReviewer = 'REJECTED_BY_REVIEWER',
  RejectedBySeller = 'REJECTED_BY_SELLER'
}

export type ApprovalResponse = {
  __typename?: 'ApprovalResponse';
  id: Scalars['ID'];
};

export enum ApprovalValue {
  Approve = 'APPROVE',
  Reject = 'REJECT'
}

export type ApproveNegotiationResponse = {
  __typename?: 'ApproveNegotiationResponse';
  id: Scalars['ID'];
};

export type AssignPaymentInput = {
  assignedTo: Scalars['String'];
  paymentId: Scalars['ID'];
  paymentMethodCode: PaymentMethodCode;
};

export type AssignPaymentRes = {
  __typename?: 'AssignPaymentRes';
  expiredTime: Scalars['DateTime'];
  orderId: Scalars['ID'];
  paymentId: Scalars['ID'];
  paymentMethodCode: PaymentMethodCode;
  status: PaymentStatus;
};

export type AssignPaymentResult = AssignPaymentRes | GenericError;

export type AuthProvider = {
  __typename?: 'AuthProvider';
  auth0Sub?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type AuthenticateStatus = {
  __typename?: 'AuthenticateStatus';
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastAuthAt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AutoCompleteProduct = {
  __typename?: 'AutoCompleteProduct';
  text: Scalars['String'];
};

export type AutoCompleteProductResponse = {
  __typename?: 'AutoCompleteProductResponse';
  items: Array<AutoCompleteProduct>;
};

export type AutoCompleteProductResult = AutoCompleteProductResponse | GenericError;

export type BastAddon = {
  __typename?: 'BASTAddon';
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  tax: Scalars['Float'];
  totalPrice: Scalars['Float'];
  unit: Scalars['String'];
};

export type BastBuyer = {
  __typename?: 'BASTBuyer';
  address: Scalars['String'];
  division: Scalars['String'];
  instanceName: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['String'];
  workUnit: Scalars['String'];
};

export type BastLetterhead = {
  __typename?: 'BASTLetterhead';
  bastNumber: Scalars['String'];
  confirmedAt: Scalars['String'];
  deliveredDateTime: Scalars['String'];
  documentNumber: Scalars['String'];
  handoverDateTime: Scalars['String'];
  orderDateTime: Scalars['String'];
  orderNumber: Scalars['String'];
  requestDateArrival: Scalars['String'];
};

export type BastNoInput = {
  bastNumber: Scalars['String'];
  deliveryOrderId: Scalars['ID'];
};

export type BastOrderItem = {
  __typename?: 'BASTOrderItem';
  addons: Array<BastAddon>;
  imageUrl: Scalars['String'];
  notes: Scalars['String'];
  pdn: Scalars['String'];
  preOrder: Scalars['Int'];
  price: Scalars['Float'];
  priceLabel: Array<Scalars['String']>;
  productName: Scalars['String'];
  productType: Scalars['String'];
  quantity: Scalars['Float'];
  sku: Scalars['String'];
  totalPrice: Scalars['Float'];
  unit: Scalars['String'];
  variant: Scalars['String'];
  weight: Scalars['String'];
  weightUnit: Scalars['String'];
};

export type BastResponse = {
  __typename?: 'BASTResponse';
  buyer: BastBuyer;
  letterhead: BastLetterhead;
  orderItem: Array<BastOrderItem>;
  seller: BastSeller;
  shipment: BastShipment;
};

export type BastResult = BastResponse | GenericError;

export type BastSeller = {
  __typename?: 'BASTSeller';
  address: Scalars['String'];
  company: Scalars['String'];
  isOfficialVendor: Scalars['Boolean'];
  isUKM: Scalars['Boolean'];
  name: Scalars['String'];
  position: Scalars['String'];
  taxpayerIdentityNumber: Scalars['String'];
};

export type BastShipment = {
  __typename?: 'BASTShipment';
  address: Scalars['String'];
  courier: Scalars['String'];
  label: Scalars['String'];
  phoneNumber: Scalars['String'];
  recipient: Scalars['String'];
  requestDateArrival: Scalars['String'];
};

export type BankAccountList = {
  __typename?: 'BankAccountList';
  banks: Array<CompanyBankDetail>;
};

export type BankAccountListResponse = BankAccountList | GenericError;

export type BankFilter = {
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type BaseProductFilter = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  logisticType?: InputMaybe<ShippingOptionType>;
  sellerId?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<ProductStatusType>>;
};

export type Brand = {
  __typename?: 'Brand';
  applicationNumber?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  fillingDate?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type BrandResult = Brand | GenericError;

export type BulkProductInput = {
  ids: Array<Scalars['ID']>;
  includeDeleted?: Scalars['Boolean'];
  regionCode?: InputMaybe<Scalars['String']>;
};

export type BulkProductSnapshotInput = {
  productId: Scalars['ID'];
  regionCode?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Buyer = {
  __typename?: 'Buyer';
  addressSatuanKerja?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  institutionGroup?: Maybe<Scalars['String']>;
  institutionID?: Maybe<Scalars['String']>;
  institutionName?: Maybe<Scalars['String']>;
  institutionType?: Maybe<Scalars['String']>;
  kodeSatuanKerjaAdj?: Maybe<Scalars['String']>;
  kodeSatuanKerjaEcatalog?: Maybe<Scalars['String']>;
  kodeSatuanKerjaSakti?: Maybe<Scalars['String']>;
  kodeSatuanKerjaSipd?: Maybe<Scalars['String']>;
  kodeSatuanKerjaSirup?: Maybe<Scalars['String']>;
  lpseId?: Maybe<Scalars['Int']>;
  nameSatuanKerja?: Maybe<Scalars['String']>;
  npwpSatuanKerja?: Maybe<Scalars['String']>;
  placeID?: Maybe<Scalars['String']>;
  placeIdAddressSatuanKerja?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<BuyerUser>>>;
};

export type BuyerAddress = {
  __typename?: 'BuyerAddress';
  buyerId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isMainAddress?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumberMember>;
  postalCode?: Maybe<Scalars['String']>;
  receiverName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  villageAreaCode?: Maybe<Scalars['String']>;
};

export type BuyerAddressInput = {
  fullAddress?: InputMaybe<Scalars['String']>;
  isMainAddress?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  receiverName?: InputMaybe<Scalars['String']>;
  villageAreaCode?: InputMaybe<Scalars['String']>;
};

export type BuyerAllResponse = BuyerList | Error;

export type BuyerFilter = {
  institutionIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  institutionNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  kodeSatuanKerjas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lpseIDs?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nameSatuanKerjas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BuyerList = {
  __typename?: 'BuyerList';
  buyers: Array<Buyer>;
};

export type BuyerPagination = {
  __typename?: 'BuyerPagination';
  currentPage?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<BuyerRole>>>;
  lastPage?: Maybe<Scalars['Int']>;
  nextPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  prevPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type BuyerPayment = {
  __typename?: 'BuyerPayment';
  address: Scalars['String'];
  institutionName: Scalars['String'];
  namaSatuanKerja: Scalars['String'];
  npwpSatuanKerja: Scalars['String'];
  user: UserPayment;
};

export type BuyerResponse = Buyer | Error;

export type BuyerRole = {
  __typename?: 'BuyerRole';
  buyer?: Maybe<Buyer>;
  user?: Maybe<User>;
};

export type BuyerRoleFilter = {
  filter?: InputMaybe<BuyerFilter>;
  pagination?: InputMaybe<MemberPagination>;
  search?: InputMaybe<Scalars['String']>;
};

export type BuyerUser = {
  __typename?: 'BuyerUser';
  buyerId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  employeeID?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  lpseId?: Maybe<Scalars['Int']>;
  lpseUsername?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Coa = {
  __typename?: 'COA';
  coa16seg: Scalars['ID'];
  itemCoaId: Scalars['String'];
  itemDesc: Scalars['String'];
  subKompDesc: Scalars['String'];
};

export type CancelAddendumInput = {
  addendumId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type CancelAddendumResponse = {
  __typename?: 'CancelAddendumResponse';
  id: Scalars['ID'];
};

export type CancelNegotiationInput = {
  orderNegotiationId: Scalars['ID'];
  reason: Scalars['String'];
  reasonDescription?: InputMaybe<Scalars['String']>;
};

export type CancelNegotiationResponse = {
  __typename?: 'CancelNegotiationResponse';
  id: Scalars['ID'];
};

export type CancellationDetail = {
  __typename?: 'CancellationDetail';
  chargedParties?: Maybe<Array<OrderRole>>;
  chargedPartiesDetail?: Maybe<Scalars['String']>;
  escalationDetail?: Maybe<Scalars['String']>;
  picInfo: PublicUserInfo;
  reason?: Maybe<Scalars['String']>;
  reasonCategory: Scalars['String'];
  rejectedAt?: Maybe<Scalars['Time']>;
  timestamp?: Maybe<Scalars['Time']>;
};

export type Cart = {
  __typename?: 'Cart';
  items: Array<CartItem>;
};

export type CartAddItemError = {
  __typename?: 'CartAddItemError';
  items: CartAddItemErrorSpec;
  reason: CartAddItemErrorReason;
};

export enum CartAddItemErrorReason {
  InvalidSeller = 'INVALID_SELLER',
  ItemLimitReached = 'ITEM_LIMIT_REACHED',
  ProductAddOnVariantNotFound = 'PRODUCT_ADD_ON_VARIANT_NOT_FOUND',
  ProductNotBuyable = 'PRODUCT_NOT_BUYABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  ProductVariantNotFound = 'PRODUCT_VARIANT_NOT_FOUND',
  QtyExceeded = 'QTY_EXCEEDED',
  SellerLimitReached = 'SELLER_LIMIT_REACHED',
  SystemError = 'SYSTEM_ERROR'
}

export type CartAddItemErrorSpec = {
  __typename?: 'CartAddItemErrorSpec';
  addOnVariantIds: Array<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  productVariantId: Scalars['ID'];
  qty: Scalars['Int'];
  sellerId: Scalars['ID'];
};

export type CartAddItemInput = {
  addOnVariantIds: Array<Scalars['ID']>;
  notes?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
  productVariantId: Scalars['ID'];
  qty: Scalars['Int'];
  sellerId: Scalars['ID'];
};

export type CartAddItemResult = CartAddItemError | CartItem;

export type CartAddItemV2Result = CartItem | GenericError;

export type CartAdditionalFee = {
  __typename?: 'CartAdditionalFee';
  meteraiFee: Scalars['Float'];
  serviceFee: Scalars['Float'];
  tax: Scalars['Float'];
};

export type CartCheckoutData = {
  __typename?: 'CartCheckoutData';
  additionalFee: CartAdditionalFee;
  currency: CheckoutCurrency;
  items: Array<ItemCheckout>;
  needNegotiation: Scalars['Boolean'];
  negotiatedTotal: Scalars['Float'];
  orderReviewer: OrderReviewer;
  rupCode: Scalars['Int'];
  sellerId: Scalars['ID'];
  shipments: Array<ShipmentCheckout>;
  source: CheckoutSource;
  total: Scalars['Float'];
};

export type CartCheckoutInput = {
  items: Array<ItemCheckoutInput>;
  shipments: Array<ShipmentCheckoutInput>;
  spec: CartCheckoutSpec;
  total: Scalars['Float'];
};

export type CartCheckoutMetadata = {
  __typename?: 'CartCheckoutMetadata';
  cart: Cart;
  currency: CheckoutCurrency;
  reviewer: OrderReviewer;
  rup: Rup;
  /** @deprecated Moved to rup */
  rupCode: Scalars['Int'];
  sellerId: Scalars['ID'];
  shipments: Array<ShipmentCheckoutMetadata>;
  source: CheckoutSource;
};

export type CartCheckoutMetadataError = {
  __typename?: 'CartCheckoutMetadataError';
  data: CartCheckoutMetadata;
  reason: CartCheckoutMetadataErrorReason;
};

export enum CartCheckoutMetadataErrorReason {
  ItemInvalid = 'ITEM_INVALID'
}

export type CartCheckoutMetadataV2Result = CartCheckoutMetadata | GenericError;

export type CartCheckoutNegotiationInput = {
  items: Array<ItemCheckoutNegotiationInput>;
  shipments: Array<ShipmentCheckoutNegotiationInput>;
  spec: CartCheckoutSpec;
  total: Scalars['Float'];
};

export type CartCheckoutSpec = {
  currency: CheckoutCurrency;
  orderReviewer: OrderReviewerInput;
  rupCode: Scalars['Int'];
  sellerId: Scalars['ID'];
  source: CheckoutSource;
};

export type CartCheckoutWithNegotiationV2Result = CartCheckoutData | GenericError;

export enum CartDeleteItemErrorReason {
  InvalidItem = 'INVALID_ITEM'
}

export type CartDeleteItemResponse = {
  __typename?: 'CartDeleteItemResponse';
  deletedItems: Array<CartDeleteItemResult>;
};

export type CartDeleteItemResult = {
  __typename?: 'CartDeleteItemResult';
  errorReason?: Maybe<CartDeleteItemErrorReason>;
  itemId: Scalars['ID'];
};

export type CartDeleteItemV2Result = CartDeleteItemResponse | GenericError;

export type CartDirectCheckoutResult = CartCheckoutData | GenericError;

export type CartItem = {
  __typename?: 'CartItem';
  company: PublicCompany;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  product: CartProduct;
  qty: Scalars['Float'];
  /** @deprecated Moved to company */
  seller: SellerMember;
};

export type CartListResult = Cart | GenericError;

export type CartProduct = {
  __typename?: 'CartProduct';
  actionReasons?: Maybe<Array<ProductActionReason>>;
  addOns?: Maybe<Array<CartProductAddOn>>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  images: Array<ProductImage>;
  isActive: Scalars['Boolean'];
  isBuyable: Scalars['Boolean'];
  name: Scalars['String'];
  preOrder?: Maybe<ProductPreOrder>;
  prices: ProductPrice;
  selectedAddOnVariantIds: Array<Maybe<Scalars['String']>>;
  selectedVariantId: Scalars['String'];
  shipping: ProductShipping;
  slug: Scalars['String'];
  status: ProductStatusType;
  stockUnit?: Maybe<ProductStockUnit>;
  tax: ProductTax;
  type: ProductType;
  updatedAt: Scalars['Time'];
  username: Scalars['String'];
  variant: ProductVariant;
};


export type CartProductPricesArgs = {
  regionCode?: InputMaybe<Scalars['String']>;
};

export type CartProductAddOn = {
  __typename?: 'CartProductAddOn';
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  tax: ProductAddOnTax;
  type: AddOnType;
  variant: ProductAddOnVariant;
};

export type CartSaveCheckoutMetadataResult = CartCheckoutMetadata | GenericError;

export type CartSettingResult = CartSettings | GenericError;

export type CartSettings = {
  __typename?: 'CartSettings';
  PPCheckoutLimit: Scalars['Float'];
  PPKCheckoutThreshold: Scalars['Float'];
  negotiationThreshold: Scalars['Float'];
};

export type CartUpdateItemError = {
  __typename?: 'CartUpdateItemError';
  itemId: Scalars['ID'];
  reason: CartUpdateItemErrorReason;
};

export enum CartUpdateItemErrorReason {
  InvalidItem = 'INVALID_ITEM',
  InvalidSeller = 'INVALID_SELLER',
  ProductVariantNotFound = 'PRODUCT_VARIANT_NOT_FOUND',
  QtyExceeded = 'QTY_EXCEEDED'
}

export type CartUpdateItemInput = {
  itemId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Float']>;
};

export type CartUpdateItemResponse = {
  __typename?: 'CartUpdateItemResponse';
  updatedItems: Array<CartUpdateItemResult>;
};

export type CartUpdateItemResult = CartItem | CartUpdateItemError;

export type CartUpdateItemV2Result = CartUpdateItemResponse | GenericError;

export type CartUserBuyer = {
  __typename?: 'CartUserBuyer';
  address?: Maybe<UserAddress>;
  buyerId: Scalars['ID'];
  institutionName?: Maybe<Scalars['String']>;
  lpseUsername?: Maybe<Scalars['String']>;
  nameSatuanKerja?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type CategoryAllLevelAggregation = {
  __typename?: 'CategoryAllLevelAggregation';
  children?: Maybe<Array<CategoryAllLevelAggregation>>;
  count: Scalars['Int'];
  curationEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CategoryAllLevelAggregations = {
  __typename?: 'CategoryAllLevelAggregations';
  categories?: Maybe<Array<CategoryAllLevelAggregation>>;
};

export enum CategoryCode {
  PembayaranLangsung = 'PEMBAYARAN_LANGSUNG',
  UangPersediaan = 'UANG_PERSEDIAAN'
}

export type CategoryFilterInput = {
  level?: InputMaybe<Scalars['Int']>;
  parentID?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type CategoryKbkiCodesInput = {
  classCodes?: InputMaybe<Array<Scalars['String']>>;
  commodityCodes?: InputMaybe<Array<Scalars['String']>>;
  commodityGroupCodes?: InputMaybe<Array<Scalars['String']>>;
  divisionCodes?: InputMaybe<Array<Scalars['String']>>;
  groupCodes?: InputMaybe<Array<Scalars['String']>>;
  sectionCodes?: InputMaybe<Array<Scalars['String']>>;
  subclassCodes?: InputMaybe<Array<Scalars['String']>>;
};

export enum CategoryType {
  General = 'GENERAL',
  Special = 'SPECIAL'
}

export type ChangeUserPasswordResponse = ChangeUserPasswordResult | Error;

export type ChangeUserPasswordResult = {
  __typename?: 'ChangeUserPasswordResult';
  status?: Maybe<Scalars['String']>;
};

export type ChatCompanyFilterInput = {
  institusiIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ChatCompanyRes = {
  __typename?: 'ChatCompanyRes';
  companies: Array<ChatCompanyType>;
  pageInfo: PaginationInfo;
  users: Array<ChatCompanyUser>;
};

export type ChatCompanyResponse = ChatCompanyRes | GenericError;

export type ChatCompanyType = {
  __typename?: 'ChatCompanyType';
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type ChatCompanyUser = {
  __typename?: 'ChatCompanyUser';
  id: Scalars['String'];
};

export type CheckMasterProductAvailabilityResult = GenericError | MasterProductAvailability;

export type CheckNibType = {
  __typename?: 'CheckNIBType';
  bentukUsaha?: Maybe<PenyediaBentukUsahaEnum>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  isUmk?: Maybe<Scalars['Boolean']>;
  kbli?: Maybe<Array<CompanyKbliType>>;
  nib?: Maybe<Scalars['String']>;
  skalaUsaha?: Maybe<UmkEnum>;
};

export enum CheckoutCurrency {
  Idr = 'IDR'
}

export enum CheckoutRequestDateType {
  Estimation = 'ESTIMATION',
  Fixed = 'FIXED'
}

export enum CheckoutSource {
  Cart = 'CART',
  Pdp = 'PDP'
}

export type ChildMasterProductRegionPriceInput = {
  id?: InputMaybe<Scalars['ID']>;
  maximumRetailPrice?: InputMaybe<Scalars['Float']>;
  parentRegionCode?: InputMaybe<Scalars['String']>;
  regionCode: Scalars['String'];
};

export type ChildRegionPrice = {
  __typename?: 'ChildRegionPrice';
  id: Scalars['ID'];
  maximumRetailPrice?: Maybe<Scalars['Float']>;
  parentRegionCode?: Maybe<Scalars['String']>;
  regionCode: Scalars['String'];
  regionLevel: Scalars['String'];
  regionName: Scalars['String'];
};

export type CompanyAddressesType = {
  __typename?: 'CompanyAddressesType';
  fullAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isMainAddress: Scalars['Boolean'];
  label: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  regionDetail: RegionDetail;
  villageAreaCode: Scalars['String'];
};

export type CompanyAddressesTypeInput = {
  fullAddress: Scalars['String'];
  isMainAddress: Scalars['Boolean'];
  label: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  villageAreaCode: Scalars['String'];
};

export type CompanyAktaType = {
  __typename?: 'CompanyAktaType';
  aktaDate?: Maybe<Scalars['String']>;
  aktaId?: Maybe<Scalars['Int']>;
  aktaNo?: Maybe<Scalars['String']>;
  aktaType?: Maybe<Scalars['Int']>;
  aktaTypeName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerify?: Maybe<Scalars['Boolean']>;
  notaris?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sourceData?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CompanyBankDetail = {
  __typename?: 'CompanyBankDetail';
  accountName: Scalars['String'];
  accountNo: Scalars['String'];
  bankCode: Scalars['String'];
  bankName: Scalars['String'];
  branchName?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  createdBy: Scalars['String'];
  id: Scalars['String'];
  isMain: Scalars['Boolean'];
  isVerify: Scalars['Boolean'];
  status: Scalars['Boolean'];
  updatedAt: Scalars['Time'];
  updatedBy: Scalars['String'];
};

export type CompanyBankInfo = {
  __typename?: 'CompanyBankInfo';
  banks: CompanyBankDetail;
  companyId: Scalars['String'];
  id: Scalars['String'];
};

export type CompanyFileUrlType = {
  __typename?: 'CompanyFileURLType';
  nibUrl?: Maybe<Scalars['String']>;
  npwpURL?: Maybe<Scalars['String']>;
  suratPKPUrl?: Maybe<Scalars['String']>;
};

export type CompanyFilterInput = {
  institusiIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  nibs?: InputMaybe<Array<Scalars['String']>>;
  npwps?: InputMaybe<Array<Scalars['String']>>;
  rekanIds?: InputMaybe<Array<Scalars['Int']>>;
  slugs?: InputMaybe<Array<Scalars['String']>>;
};

export type CompanyIzinKbliInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  expired?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  izinId?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  typeOss?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type CompanyIzinKbliType = {
  __typename?: 'CompanyIzinKbliType';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  izinId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeOss?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CompanyIzinUsahaOssType = {
  __typename?: 'CompanyIzinUsahaOssType';
  auditUpdate?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerify?: Maybe<Scalars['Boolean']>;
  izinUsahaDate?: Maybe<Scalars['String']>;
  izinUsahaExpired?: Maybe<Scalars['String']>;
  izinUsahaId?: Maybe<Scalars['Int']>;
  izinUsahaInstitusi?: Maybe<Scalars['String']>;
  izinUsahaNo?: Maybe<Scalars['String']>;
  jenisIzinId?: Maybe<Scalars['String']>;
  jenisIzinName?: Maybe<Scalars['String']>;
  klsId?: Maybe<Scalars['Int']>;
  klsName?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sourceData?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CompanyIzinUsahaType = {
  __typename?: 'CompanyIzinUsahaType';
  auditUpdate?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerify?: Maybe<Scalars['Boolean']>;
  izinUsahaDate?: Maybe<Scalars['String']>;
  izinUsahaExpired?: Maybe<Scalars['String']>;
  izinUsahaId?: Maybe<Scalars['Int']>;
  izinUsahaInstitusi?: Maybe<Scalars['String']>;
  izinUsahaNo?: Maybe<Scalars['String']>;
  jenisIzinId?: Maybe<Scalars['String']>;
  jenisIzinName?: Maybe<Scalars['String']>;
  klsId?: Maybe<Scalars['Int']>;
  klsName?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sourceData?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CompanyKbliInput = {
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  izin?: InputMaybe<CompanyIzinKbliInput>;
  kbli?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type CompanyKbliType = {
  __typename?: 'CompanyKbliType';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  izin?: Maybe<CompanyIzinKbliType>;
  kbli?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CompanyMemberList = {
  __typename?: 'CompanyMemberList';
  personas: Array<PersonaType>;
  users: Array<UserInfo>;
};

export type CompanyMemberListResponse = CompanyMemberList | GenericError;

export type CompanyOssType = {
  __typename?: 'CompanyOssType';
  companyEmail?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyNpwp?: Maybe<Scalars['String']>;
  companyType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  nib?: Maybe<Scalars['String']>;
  nibId?: Maybe<Scalars['String']>;
  nibPublicationDate?: Maybe<Scalars['String']>;
  nibSubmitDate?: Maybe<Scalars['String']>;
  nibSwitchDate?: Maybe<Scalars['String']>;
  ossId?: Maybe<Scalars['String']>;
  pelakuUsahaType?: Maybe<Scalars['String']>;
  perseroanAddress?: Maybe<Scalars['String']>;
  perseroanDaerahId?: Maybe<Scalars['String']>;
  perseroanName?: Maybe<Scalars['String']>;
  perseroanPhoneNumber?: Maybe<Scalars['String']>;
  perseroanRtRw?: Maybe<Scalars['String']>;
  project?: Maybe<Array<Maybe<CompanyProjectType>>>;
  skalaUsaha?: Maybe<Scalars['String']>;
  statusPenanamanModal?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CompanyPersonaClaimRes = {
  __typename?: 'CompanyPersonaClaimRes';
  bentukUsaha: Scalars['String'];
  companyAddresses: CompanyAddressesType;
  companyName: Scalars['String'];
  isCabang: Scalars['Boolean'];
  isPkp: Scalars['Boolean'];
  nib: Scalars['String'];
  npwp: Scalars['String'];
  pkpNumber: Scalars['String'];
};

export type CompanyProjectType = {
  __typename?: 'CompanyProjectType';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kbli?: Maybe<Array<Maybe<CompanyKbliType>>>;
  projectId?: Maybe<Scalars['String']>;
  riskCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CompanyPublicAddressesType = {
  __typename?: 'CompanyPublicAddressesType';
  fullAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  regionDetail: RegionDetail;
  villageAreaCode: Scalars['String'];
};

export type CompanyPublicFilter = {
  institusiIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CompanyPublicOssType = {
  __typename?: 'CompanyPublicOssType';
  project?: Maybe<Array<Maybe<CompanyProjectType>>>;
};

export type CompanyPublicResponse = CompanyPublicResult | GenericError;

export type CompanyPublicResult = {
  __typename?: 'CompanyPublicResult';
  companies?: Maybe<Array<Maybe<CompanyPublicType>>>;
};

export type CompanyPublicType = {
  __typename?: 'CompanyPublicType';
  bentukUsaha?: Maybe<Scalars['String']>;
  bidangIndustri?: Maybe<Scalars['String']>;
  companyAddresses: Array<Maybe<CompanyPublicAddressesType>>;
  id?: Maybe<Scalars['String']>;
  institusiId?: Maybe<Scalars['String']>;
  isUkm?: Maybe<Scalars['Boolean']>;
  isUmkk?: Maybe<Scalars['Boolean']>;
  jenisPerusahaan?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oss?: Maybe<CompanyPublicOssType>;
  shippingAddresses: Array<Maybe<AddressesPublicType>>;
  skalaUsaha?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyResponse = CompanyType | GenericError;

export type CompanyType = {
  __typename?: 'CompanyType';
  akta: Array<Maybe<CompanyAktaType>>;
  aktaNotarisNo?: Maybe<Scalars['String']>;
  beneficialOwnership?: Maybe<Scalars['String']>;
  bentukUsaha?: Maybe<Scalars['String']>;
  bidangIndustri?: Maybe<Scalars['String']>;
  companyAddresses: Array<Maybe<CompanyAddressesType>>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  institusiId?: Maybe<Scalars['String']>;
  isCabang?: Maybe<Scalars['Boolean']>;
  isPkp?: Maybe<Scalars['Boolean']>;
  isUkm?: Maybe<Scalars['Boolean']>;
  isUmkk?: Maybe<Scalars['Boolean']>;
  izin: Array<Maybe<CompanyIzinUsahaType>>;
  izinOSS: Array<Maybe<CompanyIzinUsahaOssType>>;
  jenisPerusahaan?: Maybe<Scalars['String']>;
  kswp?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nib?: Maybe<Scalars['String']>;
  nibToken?: Maybe<Scalars['String']>;
  noPkp?: Maybe<Scalars['String']>;
  npwp?: Maybe<Scalars['String']>;
  npwpToken?: Maybe<Scalars['String']>;
  oss?: Maybe<CompanyOssType>;
  picTtd?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  shippingAddresses: Array<Maybe<AddressesType>>;
  skalaUsaha?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  suratPKPToken?: Maybe<Scalars['String']>;
  telepon?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  virtualAccountBank?: Maybe<Scalars['String']>;
  virtualAccountNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyTypeList = {
  __typename?: 'CompanyTypeList';
  companies?: Maybe<Array<Maybe<CompanyType>>>;
};

export type CompleteNegotiationError = {
  __typename?: 'CompleteNegotiationError';
  id: Scalars['ID'];
  reason: CompleteNegotiationErrorReason;
};

export enum CompleteNegotiationErrorReason {
  RateIdUnavailable = 'RATE_ID_UNAVAILABLE',
  RequestArrivalDateInvalid = 'REQUEST_ARRIVAL_DATE_INVALID'
}

export type CompleteNegotiationResponse = CompleteNegotiationError | CompleteNegotiationResult;

export type CompleteNegotiationResult = {
  __typename?: 'CompleteNegotiationResult';
  id: Scalars['ID'];
  shipmentPriceChangeNotification?: Maybe<ShipmentPriceChangeNotification>;
};

export type ContractDocumentStatus = {
  __typename?: 'ContractDocumentStatus';
  /** @deprecated No longer supported */
  message?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  signedDocument?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export enum ContractEnterpriseStatus {
  Rejected = 'rejected',
  Verified = 'verified',
  WaitingVerification = 'waiting_verification'
}

export type ContractUser = {
  __typename?: 'ContractUser';
  id: Scalars['String'];
  provider: ContractProvider;
  providerUserEmail: Scalars['String'];
  providerUserId: Scalars['String'];
  providerUserName: Scalars['String'];
  providerUserNik: Scalars['String'];
  providerUserPhone: Scalars['String'];
  rejectReason: Scalars['String'];
  signToken: Scalars['String'];
  status: ContractEsignStatus;
  userId: Scalars['String'];
};

export type Countries = {
  __typename?: 'Countries';
  currentPage?: Maybe<Scalars['Int']>;
  items: Array<Country>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type CountriesInput = {
  filter: CountryFilter;
  pagination: PaginationInput;
};

export type CountriesResult = Countries | GenericError;

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CountryFilter = {
  codes?: InputMaybe<Array<Scalars['String']>>;
};

export type Courier = {
  AwbNumber: Scalars['String'];
  DriverName?: InputMaybe<Scalars['String']>;
  VehicleNumber?: InputMaybe<Scalars['String']>;
};

export type CreateCategoryInput = {
  additionalInfo?: InputMaybe<Array<CreateFormFieldInput>>;
  allowedPpnPercentage?: InputMaybe<Array<Scalars['Float']>>;
  curationEnabled: Scalars['Boolean'];
  documents?: InputMaybe<Array<CreateFormFieldInput>>;
  hasVat?: InputMaybe<Scalars['Boolean']>;
  hetPriceEnabled: Scalars['Boolean'];
  icon?: InputMaybe<Scalars['String']>;
  iconToken?: InputMaybe<Scalars['String']>;
  isActive?: Scalars['Boolean'];
  kbki: CategoryKbkiCodesInput;
  kbli?: InputMaybe<Array<Scalars['String']>>;
  level: Scalars['Int'];
  masterProductEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  nieEnabled: Scalars['Boolean'];
  parentId?: InputMaybe<Scalars['String']>;
  ppnPercentage?: Scalars['Float'];
  primaryInfo?: InputMaybe<Array<CreateFormFieldInput>>;
  productType?: InputMaybe<ProductType>;
  type?: InputMaybe<CategoryType>;
  zoningPriceEnabled: Scalars['Boolean'];
};

export type CreateFormFieldInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Int'];
  showOnLogin: Scalars['Boolean'];
  type: FormType;
};

export type CreateMasterProductImageInput = {
  imageToken?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type CreateMasterProductInformationDetailInput = {
  customFormFieldId: Scalars['ID'];
  name: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CreateMasterProductInformationInput = {
  additionalInformations?: InputMaybe<Array<CreateMasterProductInformationDetailInput>>;
  mainInformations?: InputMaybe<Array<CreateMasterProductInformationDetailInput>>;
};

export type CreateMasterProductInput = {
  brand?: InputMaybe<UpsertMasterProductBrandInput>;
  category: UpsertMasterProductCategoryInput;
  images: Array<CreateMasterProductImageInput>;
  informations: CreateMasterProductInformationInput;
  kbki: Scalars['String'];
  name: Scalars['String'];
  nationalMaximumRetailPrice?: InputMaybe<Scalars['Float']>;
  nie?: InputMaybe<UpsertMasterProductNieInput>;
  pdn: UpsertMasterProductPdnInput;
  regionPrices?: InputMaybe<Array<MasterProductRegionPriceInput>>;
  sni?: InputMaybe<UpsertMasterProductSniInput>;
  tkdn?: InputMaybe<UpsertMasterProductTkdnInput>;
  type?: InputMaybe<ProductType>;
  videoSource?: InputMaybe<VideoSourceType>;
  videoToken?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type CreateProductAddOnInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  objectType?: AddOnObjectType;
  ppnPercentage?: Scalars['Float'];
  productAddOnVariants: Array<CreateProductAddOnVariantInput>;
  type: AddOnType;
};

export type CreateProductAddOnVariantInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type CreateProductBulkActionInput = {
  fileName: Scalars['String'];
  token: Scalars['String'];
  type: ProductBulkActionType;
};

export type CreateProductBulkActionResponse = {
  __typename?: 'CreateProductBulkActionResponse';
  id: Scalars['ID'];
};

export type CreateProductBulkActionResult = CreateProductBulkActionResponse | GenericError;

export type CreateProductBulkUploadRequestInput = {
  filter: ListProductFilterInput;
};

export type CreateProductBulkUploadRequestResponse = {
  __typename?: 'CreateProductBulkUploadRequestResponse';
  id: Scalars['ID'];
  status: ProductBulkUploadRequestStatus;
};

export type CreateProductBulkUploadRequestResult = CreateProductBulkUploadRequestResponse | GenericError;

export type CreateProductCategoryResult = GenericError | ProductCategory;

export type CreateProductFromMasterProductInput = {
  masterProductId: Scalars['ID'];
  product: CreateSpecialProductInput;
};

export type CreateProductImageInput = {
  imageToken?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type CreateProductInformationDetailInput = {
  customFormFieldId: Scalars['ID'];
  name: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CreateProductInformationInput = {
  additionalInformations?: InputMaybe<Array<CreateProductInformationDetailInput>>;
  documents?: InputMaybe<Array<CreateProductInformationDetailInput>>;
  mainInformations?: InputMaybe<Array<CreateProductInformationDetailInput>>;
};

export type CreateProductInput = {
  brand?: InputMaybe<UpsertProductBrandInput>;
  category: UpsertProductCategoryInput;
  description?: InputMaybe<Scalars['String']>;
  images: Array<CreateProductImageInput>;
  isActive: Scalars['Boolean'];
  kbki: Scalars['String'];
  logisticConfigurations?: InputMaybe<Array<UpsertProductLogisticConfigurationInput>>;
  name: Scalars['String'];
  nie?: InputMaybe<UpsertProductNieInput>;
  pdn: UpsertProductPdnInput;
  preOrder?: InputMaybe<UpsertProductPreOrderInput>;
  prices: CreateProductPriceInput;
  productAddOns?: InputMaybe<Array<CreateProductAddOnInput>>;
  productInformations: CreateProductInformationInput;
  productOptions?: InputMaybe<Array<CreateProductOptionInput>>;
  shipping?: InputMaybe<UpsertProductShippingInput>;
  shippingOption?: InputMaybe<ShippingOptionType>;
  sni?: InputMaybe<UpsertProductSniInput>;
  stockUnit?: InputMaybe<UpsertProductStockUnitInput>;
  tax?: UpsertProductTax;
  tkdn?: InputMaybe<UpsertProductTkdnInput>;
  type?: InputMaybe<ProductType>;
  variants: Array<CreateProductVariantInput>;
  videoSource?: InputMaybe<VideoSourceType>;
  videoToken?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type CreateProductOptionInput = {
  option: Scalars['String'];
  optionLevel: Scalars['Int'];
  values: Array<Scalars['String']>;
};

export type CreateProductPriceInput = {
  minPurchase: Scalars['Int'];
  productRegionPrices?: InputMaybe<Array<CreateProductRegionPriceInput>>;
  productWholesalePrices?: InputMaybe<Array<CreateProductWholesalePriceInput>>;
};

export type CreateProductRegionPriceInput = {
  price: Scalars['Float'];
  regionCode: Scalars['String'];
};

export type CreateProductVariantInput = {
  imageUrl?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  optionValues?: InputMaybe<Array<Scalars['String']>>;
  options?: InputMaybe<Array<Scalars['String']>>;
  price: Scalars['Float'];
  sku?: InputMaybe<Scalars['String']>;
  stock: Scalars['Int'];
};

export type CreateProductWholesalePriceInput = {
  minQuantity: Scalars['Int'];
  price: Scalars['Float'];
};

export type CreateSpecialProductInput = {
  description?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<CreateProductInformationDetailInput>>;
  isActive: Scalars['Boolean'];
  logisticConfigurations?: InputMaybe<Array<UpsertProductLogisticConfigurationInput>>;
  preOrder?: InputMaybe<UpsertProductPreOrderInput>;
  prices: CreateProductPriceInput;
  productAddOns?: InputMaybe<Array<CreateProductAddOnInput>>;
  productOptions?: InputMaybe<Array<CreateProductOptionInput>>;
  shipping?: InputMaybe<UpsertProductShippingInput>;
  shippingOption?: InputMaybe<ShippingOptionType>;
  stockUnit?: InputMaybe<UpsertProductStockUnitInput>;
  tax?: UpsertProductTax;
  variants: Array<CreateProductVariantInput>;
};

export type CreateUserDataInput = {
  cfToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  username: Scalars['String'];
};

export type CreateUserDataResp = {
  __typename?: 'CreateUserDataResp';
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CreateUserDataResponse = CreateUserDataResp | GenericError;

export type CreditCard3dsRequired = {
  __typename?: 'CreditCard3dsRequired';
  redirectURL: Scalars['String'];
};

export enum Currency {
  Idr = 'IDR'
}

export type DelegatePersonaInput = {
  assigneeUsername: Scalars['String'];
  expiredAt: Scalars['String'];
  personaId: Scalars['String'];
  suratKuasaToken: Scalars['String'];
};

export type DeleteBuyerAddressResponse = DeleteBuyerAddressResult | Error;

export type DeleteBuyerAddressResult = {
  __typename?: 'DeleteBuyerAddressResult';
  status?: Maybe<Scalars['String']>;
};

export type DeleteCompanyBankResponse = CompanyBankInfo | GenericError;

export type DeleteInternalUserResponse = DeleteInternalUserResult | Error;

export type DeleteInternalUserResult = {
  __typename?: 'DeleteInternalUserResult';
  status?: Maybe<Scalars['String']>;
};

export type DeletePersonaShippingAddressResponse = DeletePersonaShippingAddressResult | GenericError;

export type DeletePersonaShippingAddressResult = {
  __typename?: 'DeletePersonaShippingAddressResult';
  status: Scalars['String'];
};

export type DeleteProductCategoryResult = GenericError | StatusResponse;

export type DeleteRolesResponse = DeleteRolesResult | Error;

export type DeleteRolesResult = {
  __typename?: 'DeleteRolesResult';
  status: Scalars['String'];
};

export type DeleteUserAddressResponse = DeleteUserAddressResult | Error;

export type DeleteUserAddressResult = {
  __typename?: 'DeleteUserAddressResult';
  status: Scalars['String'];
};

export type DeliveryOrder = {
  __typename?: 'DeliveryOrder';
  awbNumber?: Maybe<Scalars['String']>;
  bastNumber?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  destination: LogisticDeliveryAddress;
  detailPrice: DetailPrice;
  documentActivities: LogisticDocumentActivitiesResult;
  documentMetadata?: Maybe<DocumentMetadata>;
  documentNumber?: Maybe<Scalars['String']>;
  documentToken?: Maybe<Scalars['String']>;
  documentUrl?: Maybe<Scalars['String']>;
  estimationTime: LogisticDeliveryEstimation;
  externalId: Scalars['ID'];
  id: Scalars['ID'];
  isInsurance: Scalars['Boolean'];
  items: Array<Item>;
  metadata?: Maybe<DeliveryOrderMetadata>;
  oldDeliveryOrderId?: Maybe<Scalars['ID']>;
  orderId: Scalars['ID'];
  origin: LogisticSimpleDeliveryAddress;
  pickupCode?: Maybe<Scalars['String']>;
  pickupTime?: Maybe<Scalars['String']>;
  prices: LogisticDeliveryPrices;
  proofOfDelivery?: Maybe<LogisticDeliveryProofOfDelivery>;
  provider?: Maybe<RateProvider>;
  rateId: Scalars['ID'];
  requestDateArrival: Scalars['String'];
  requestDateArrivalNotes: Scalars['String'];
  requestDateType: Scalars['String'];
  requestPickupTime?: Maybe<Scalars['String']>;
  shippingLabelId?: Maybe<Scalars['String']>;
  status: LogisticDeliveryOrderStatus;
  updatedAt: Scalars['String'];
  volumetric?: Maybe<LogisticDeliveryVolumetric>;
};

export type DeliveryOrderConfirmation = {
  __typename?: 'DeliveryOrderConfirmation';
  confirmedAt?: Maybe<Scalars['String']>;
};

export type DeliveryOrderConfirmationResult = DeliveryOrderConfirmation | GenericError;

export type DeliveryOrderMetadata = {
  __typename?: 'DeliveryOrderMetadata';
  document?: Maybe<Document>;
  isAddendum?: Maybe<Scalars['Boolean']>;
};

export type DeliveryOrderResult = DeliveryOrder | GenericError;

export type DeliveryOrderStatusHistory = {
  __typename?: 'DeliveryOrderStatusHistory';
  createdAt: Scalars['String'];
  deliveryOrderId: Scalars['ID'];
  externalStatusCode: Scalars['String'];
  externalStatusDescription: Scalars['String'];
  externalStatusName: Scalars['String'];
  id: Scalars['ID'];
  metadata?: Maybe<StatusHistoryMetadata>;
  providerStatusCode: Scalars['String'];
  providerStatusDescription: Scalars['String'];
  providerStatusName: Scalars['String'];
  source: Scalars['String'];
  status: LogisticDeliveryOrderStatus;
  statusCreatedAt: Scalars['String'];
  statusDescription: Scalars['String'];
};

export type DeliveryOrderStatusHistoryResponse = {
  __typename?: 'DeliveryOrderStatusHistoryResponse';
  data?: Maybe<Tracking>;
};

export type Destination = {
  __typename?: 'Destination';
  addressCheckout: AddressCheckout;
  buyerId: Scalars['ID'];
};

export type DetailPrice = {
  __typename?: 'DetailPrice';
  basePrice: Scalars['Float'];
  discount: Scalars['Float'];
  finalPrice: Scalars['Float'];
  finalPriceWithoutPPN: Scalars['Float'];
  insuranceFee: Scalars['Float'];
  insuranceFeeWithoutPPN: Scalars['Float'];
  ppnPercentage: Scalars['Float'];
  ppnPercentageInsurance: Scalars['Float'];
  ppnValue: Scalars['Float'];
  shipmentFee: Scalars['Float'];
  shipmentFeeWithoutPPN: Scalars['Float'];
  surchargeFee: Scalars['Float'];
};

export type DetailTransaksi = {
  __typename?: 'DetailTransaksi';
  namaPaket: Scalars['String'];
  nominalPaket: Scalars['Float'];
  nomorDokumenPenerimaan: Scalars['String'];
  nomorPaket: Scalars['String'];
  nomorSuratPesanan: Scalars['String'];
  sumberDana: Scalars['String'];
};

export type DisbursementDeduction = {
  __typename?: 'DisbursementDeduction';
  amount: Scalars['Float'];
  category: Scalars['String'];
  deductionCode: Scalars['String'];
  deductionName: Scalars['String'];
  id: Scalars['ID'];
};

export type Document = {
  __typename?: 'Document';
  buyerSign: Sign;
  sellerSign: Sign;
};

export enum DocumentCategory {
  SuratAdendum = 'SURAT_ADENDUM',
  SuratPembatalan = 'SURAT_PEMBATALAN',
  SuratPesanan = 'SURAT_PESANAN'
}

export type DocumentMetadata = {
  __typename?: 'DocumentMetadata';
  buyer?: Maybe<DocumentSign>;
  seller?: Maybe<DocumentSign>;
};

export type DocumentRejection = {
  document: TFileUpload;
  reason: Scalars['String'];
};

export type DocumentSign = {
  __typename?: 'DocumentSign';
  documentErrorReason?: Maybe<Scalars['String']>;
};

export enum DocumentStatus {
  AdditionalClausesAdded = 'ADDITIONAL_CLAUSES_ADDED',
  BuyerRequestSign = 'BUYER_REQUEST_SIGN',
  BuyerSigned = 'BUYER_SIGNED',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Failed = 'FAILED',
  Generated = 'GENERATED',
  PrivyBlocked = 'PRIVY_BLOCKED',
  PrivyError = 'PRIVY_ERROR',
  Rejected = 'REJECTED',
  SellerExpiredSignUrl = 'SELLER_EXPIRED_SIGN_URL',
  SellerRejectSignPrivy = 'SELLER_REJECT_SIGN_PRIVY',
  SellerRequestSignUrl = 'SELLER_REQUEST_SIGN_URL',
  SellerSigned = 'SELLER_SIGNED',
  Uploaded = 'UPLOADED'
}

export enum DocumentType {
  FakturPajak = 'FAKTUR_PAJAK',
  FakturPajakReplacement = 'FAKTUR_PAJAK_REPLACEMENT'
}

export type DownloadAccount = {
  __typename?: 'DownloadAccount';
  signedUrl?: Maybe<Array<Scalars['String']>>;
};

export type DownloadAccountResponse = DownloadAccount | GenericError;

export type EchoRawResult = {
  __typename?: 'EchoRawResult';
  headers: Array<Scalars['String']>;
};

export type EchoResult = {
  __typename?: 'EchoResult';
  headers: Array<HeaderItem>;
};

export type EligibleApp = {
  __typename?: 'EligibleApp';
  appTypeId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lpseId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tncRequired?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  reqId?: Maybe<Scalars['ID']>;
};

export type Eselon = {
  __typename?: 'Eselon';
  createdAt: Scalars['String'];
  kodeEselon: Scalars['String'];
  kodeEselonSakti?: Maybe<Scalars['String']>;
  kodeKlpd: Scalars['String'];
  namaEselon: Scalars['String'];
  namaEselonSakti?: Maybe<Scalars['String']>;
};

export type EselonFilter = {
  kodeEselons?: InputMaybe<Array<Scalars['String']>>;
  kodeEselonsSakti?: InputMaybe<Array<Scalars['String']>>;
  kodeKlpds?: InputMaybe<Array<Scalars['String']>>;
  namaEselon?: InputMaybe<Scalars['String']>;
};

export type EselonFilterMnC = {
  kodeEselons?: InputMaybe<Array<Scalars['String']>>;
  kodeEselonsSakti?: InputMaybe<Array<Scalars['String']>>;
  kodeKlpds?: InputMaybe<Array<Scalars['String']>>;
  namaEselon?: InputMaybe<Scalars['String']>;
};

export type EselonResponse = EselonResult | GenericError;

export type EselonResponseMnC = Error | EselonResultMnC;

export type EselonResult = {
  __typename?: 'EselonResult';
  items: Array<Eselon>;
  paginationInfo: PaginationInfo;
};

export type EselonResultMnC = {
  __typename?: 'EselonResultMnC';
  items: Array<Eselon>;
  paginationInfo: PaginationInfo;
};

export type FailedBankConfig = {
  __typename?: 'FailedBankConfig';
  bankID: Scalars['ID'];
  reason: Scalars['String'];
};

export type FailedPaymentMethodConfig = {
  __typename?: 'FailedPaymentMethodConfig';
  code: Scalars['ID'];
  reason: Scalars['String'];
};

export enum FeatureCategoryType {
  CategoryList = 'CategoryList',
  RoleManagement = 'RoleManagement',
  Transaction = 'Transaction',
  UserManagement = 'UserManagement'
}

export type File = {
  __typename?: 'File';
  token?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Filter = {
  userIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FilterLowestProductSoldPriceInput = {
  productId: Scalars['ID'];
  productVariantId: Scalars['ID'];
};

export type FormFieldResponse = {
  __typename?: 'FormFieldResponse';
  data?: Maybe<ProductCategory>;
  errors?: Maybe<Array<Error>>;
};

export enum FormType {
  Checkbox = 'CHECKBOX',
  File = 'FILE',
  Number = 'NUMBER',
  Text = 'TEXT'
}

export type FundingSourceDetail = {
  __typename?: 'FundingSourceDetail';
  mak: Scalars['String'];
  name: Scalars['String'];
};

export type GenerateInterviewScheduleResponse = GenericError | InterviewSchedule;

export type GenerateLetterInput = {
  additionalClauses?: InputMaybe<Array<Scalars['String']>>;
  documentCategory: DocumentCategory;
  orderId: Scalars['String'];
};

export type GenerateLetterResponse = {
  __typename?: 'GenerateLetterResponse';
  orderId: Scalars['String'];
};

export type GenerateLetterResult = GenerateLetterResponse | GenericError;

export type GenerateMandiriBillSuccess = {
  __typename?: 'GenerateMandiriBillSuccess';
  bankId: Scalars['String'];
  billKey: Scalars['String'];
  billerCode: Scalars['String'];
  orderId: Scalars['ID'];
  paymentId: Scalars['ID'];
  paymentSummaryDetails: Array<PaymentSummaryDetail>;
  totalAmount: Scalars['Int'];
  vaDeadlineAt: Scalars['DateTime'];
};

export type GenerateQrisInput = {
  paymentId: Scalars['ID'];
};

export type GenerateQrisResponse = GenerateQrisSuccess | GenericError;

export type GenerateQrisSuccess = {
  __typename?: 'GenerateQRISSuccess';
  orderId: Scalars['ID'];
  paymentId: Scalars['ID'];
  paymentSummaryDetails: Array<PaymentSummaryDetail>;
  qrCode: Scalars['String'];
  totalAmount: Scalars['Float'];
};

export type GenerateVaInput = {
  bankId: Scalars['ID'];
  paymentId: Scalars['ID'];
};

export type GenerateVaResponse = GenerateMandiriBillSuccess | GenerateVaSuccess | GenericError;

export type GenerateVaSuccess = {
  __typename?: 'GenerateVASuccess';
  bankId: Scalars['String'];
  orderId: Scalars['ID'];
  paymentId: Scalars['ID'];
  paymentSummaryDetails: Array<PaymentSummaryDetail>;
  totalAmount: Scalars['Int'];
  vaDeadlineAt: Scalars['DateTime'];
  vaNumber: Scalars['String'];
};

export type GenericError = {
  __typename?: 'GenericError';
  code: Scalars['String'];
  message: Scalars['String'];
  reqId: Scalars['String'];
};

export type GetAllProductInput = {
  includeDeleted: Scalars['Boolean'];
  productIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetBuyerFilter = {
  buyerIDs?: InputMaybe<Array<Scalars['ID']>>;
  userIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetBuyerRoleByFilterResponse = BuyerPagination | Error;

export type GetCoaRes = {
  __typename?: 'GetCOARes';
  items: Array<Coa>;
};

export type GetCoaResponse = GenericError | GetCoaRes;

export type GetCompanyByFilterResponse = CompanyTypeList | GenericError;

export type GetContractDocumentStatusInput = {
  documentTokenProvider: Scalars['String'];
  process?: InputMaybe<ContractProcess>;
};

export type GetEligibleAppsFilter = {
  applicationType?: InputMaybe<Array<InputMaybe<ApplicationTypeEnum>>>;
};

export type GetEligibleAppsResponse = GenericError | GetEligibleAppsResult;

export type GetEligibleAppsResult = {
  __typename?: 'GetEligibleAppsResult';
  applications?: Maybe<Array<Maybe<EligibleApp>>>;
};

export type GetInstitutionByFilterResponse = GenericError | ListInstitution;

export type GetInstitutionByIdResponse = GenericError | Institution;

export type GetInternalUserListByFilterResponse = Error | GetInternalUserListByFilterResult;

export type GetInternalUserListByFilterResult = {
  __typename?: 'GetInternalUserListByFilterResult';
  items?: Maybe<Array<Maybe<InternalUser>>>;
  paginationInfo: PaginationInfo;
};

export type GetInterviewScheduleResponse = GenericError | InterviewSchedule;

export type GetOnboardingStatus = {
  __typename?: 'GetOnboardingStatus';
  nextStep?: Maybe<OnboardingStep>;
};

export type GetPersonaListForApplicationResponse = GenericError | GetPersonaListForApplicationResult;

export type GetPersonaListForApplicationResult = {
  __typename?: 'GetPersonaListForApplicationResult';
  persona?: Maybe<Array<Maybe<PersonaListData>>>;
};

export type GetPersonaNonPenyediaByIdResponse = GenericError | PersonaNonPenyedia;

export type GetPersonaPenyediaByIdResponse = GenericError | PersonaPenyedia;

export type GetPriceByDeliveryOrderIdInput = {
  isAddendum?: InputMaybe<Scalars['Boolean']>;
  items: Array<GetPriceByDeliveryOrderIdItem>;
};

export type GetPriceByDeliveryOrderIdItem = {
  orderItemId: Scalars['String'];
  ppnPercentage: Scalars['Float'];
  price: Scalars['Float'];
  qty: Scalars['Int'];
};

export type GetPriceByDeliveryOrderIdResult = DeliveryOrder | GenericError;

export type GetPrivyLinkResponse = {
  __typename?: 'GetPrivyLinkResponse';
  registrationUrl: Scalars['String'];
};

export type GetProductBySlugInput = {
  slug: Scalars['String'];
  username: Scalars['String'];
};

export type GetProductRegionpriceResult = GenericError | ProductRegionPrices;

export type GetProfileOutput = {
  __typename?: 'GetProfileOutput';
  address?: Maybe<Array<Maybe<Address>>>;
  birthdate?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  familyName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberVerified?: Maybe<Scalars['Boolean']>;
  picture?: Maybe<Scalars['String']>;
  prefferedUsername?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zoneInfo?: Maybe<Scalars['String']>;
};

export type GetSellerBankBySellerIdResponse = Error | SellerBankInfo;

export type GetShippingLabelResponse = {
  __typename?: 'GetShippingLabelResponse';
  shippingLabelUrl: Scalars['String'];
};

export type GetShippingLabelResult = GenericError | GetShippingLabelResponse;

export type GetSignImageResponse = {
  __typename?: 'GetSignImageResponse';
  publicUrl: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type GetSkalaUsahaPenyediaResponse = Error | SkalaUsahaPenyedia;

export type GetStatusEnterpriseInput = {
  institutionId: Scalars['String'];
};

export type GetStatusEnterpriseResponse = {
  __typename?: 'GetStatusEnterpriseResponse';
  bidangIndustri: Scalars['String'];
  companyName: Scalars['String'];
  enterpriseId: Scalars['String'];
  rejectReason?: Maybe<Scalars['String']>;
  status: ContractEnterpriseStatus;
};

export type GetStatusOnboardingResponse = GenericError | StatusOnBoardingInfo;

export type GetStoreNotesFilter = {
  id?: InputMaybe<Scalars['ID']>;
  sellerID?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<StoreNoteType>;
};

export type GetStoreNotesResponse = Error | StoreNotesList;

export type GetUploadStatusInput = {
  token: Scalars['ID'];
};

export type GetUserByFilterResult = Error | UserList;

export type GetVerificationStatusResponse = GenericError | VerificationStatusSuccess;

export enum GroupKlpdOption {
  KementerianLembaga = 'KEMENTERIAN_LEMBAGA',
  Lainnya = 'LAINNYA',
  PemerintahDaerah = 'PEMERINTAH_DAERAH'
}

export enum GroupKlpdOptionMnC {
  KementerianLembaga = 'KEMENTERIAN_LEMBAGA',
  Lainnya = 'LAINNYA',
  PemerintahDaerah = 'PEMERINTAH_DAERAH'
}

export type GuardianAuthenticator = {
  __typename?: 'GuardianAuthenticator';
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastAuthAt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type HeaderItem = {
  __typename?: 'HeaderItem';
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type InaprocAddAuthenticatorEmailInput = {
  email: Scalars['String'];
};

export type InaprocAddAuthenticatorEmailResponse = Error | InaprocAddAuthenticatorEmailResult;

export type InaprocAddAuthenticatorEmailResult = {
  __typename?: 'InaprocAddAuthenticatorEmailResult';
  mfaToken: Scalars['String'];
  oobCode: Scalars['String'];
};

export type InaprocVerifyAuthenticatorEmailInput = {
  bindingCode: Scalars['String'];
  mfaToken: Scalars['String'];
  oobCode: Scalars['String'];
};

export type InaprocVerifyAuthenticatorEmailResponse = Error | InaprocVerifyAuthenticatorEmailResult;

export type InaprocVerifyAuthenticatorEmailResult = {
  __typename?: 'InaprocVerifyAuthenticatorEmailResult';
  status: Scalars['String'];
};

export type InsertAdditionalClausesInput = {
  additionalClauses: Array<Scalars['String']>;
  orderId: Scalars['String'];
};

export type InsertAdditionalClausesResponse = {
  __typename?: 'InsertAdditionalClausesResponse';
  orderId: Scalars['String'];
};

export type Institution = {
  __typename?: 'Institution';
  company?: Maybe<CompanyType>;
  institution?: Maybe<InstitutionType>;
  klpd?: Maybe<Klpd>;
  satker?: Maybe<Satker>;
};

export type InstitutionFilter = {
  id?: InputMaybe<Scalars['String']>;
  institutionType?: InputMaybe<Scalars['String']>;
};

export type InstitutionType = {
  __typename?: 'InstitutionType';
  auth0OrgId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  institutionType?: Maybe<Scalars['String']>;
};

export type InternalListFilter = {
  createdAt?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  roleIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortAsc?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  status?: InputMaybe<Array<InputMaybe<InternalUserStatus>>>;
};

export type InternalUser = {
  __typename?: 'InternalUser';
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum InternalUserStatus {
  Aktif = 'AKTIF',
  TidakAktif = 'TIDAK_AKTIF',
  VerifikasiUser = 'VERIFIKASI_USER'
}

export type InterviewSchedule = {
  __typename?: 'InterviewSchedule';
  endTime: Scalars['String'];
  startTime: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type InvoiceDetail = {
  __typename?: 'InvoiceDetail';
  buyer: BuyerPayment;
  createdDate: Scalars['String'];
  finalPrice: Scalars['Float'];
  finalPriceInWords: Scalars['String'];
  invoiceNumber: Scalars['String'];
  is3PL: Scalars['Boolean'];
  items: Array<ItemInvoice>;
  otherParty: OtherParty;
  paymentDetail: InvoicePaymentDetail;
  paymentType: PaymentTypeCode;
  pph?: Maybe<PPhInvoice>;
  purchasedDate: Scalars['String'];
  seller: SellerPayment;
  sellerCourier?: Maybe<Array<SellerCourier>>;
  suratPesananNumber: Scalars['String'];
  totalPPn: Scalars['Float'];
  totalPPnBM: Scalars['Float'];
  viaNegotiation: Scalars['Boolean'];
};

export type InvoiceResponse = GenericError | InvoiceDetail;

export type InvoiceWithTokenInput = {
  paymentToken: Scalars['ID'];
  paymentType: PaymentTypeCode;
};

export type Item = {
  __typename?: 'Item';
  deliveryOrderId: Scalars['ID'];
  id: Scalars['ID'];
  item: ProductItem;
};

export type ItemCoaInput = {
  coa16seg: Scalars['String'];
  itemCoaId: Scalars['String'];
  itemDesc: Scalars['String'];
  itemId: Scalars['String'];
  subKompDesc: Scalars['String'];
};

export type ItemCheckout = {
  __typename?: 'ItemCheckout';
  Notes?: Maybe<Scalars['String']>;
  Qty: Scalars['Float'];
  itemId: Scalars['ID'];
  selectedProductDetail: ProductCheckout;
};

export type ItemCheckoutInput = {
  selectedProductDetail: ProductCheckoutInput;
  spec: ItemCheckoutSpec;
};

export type ItemCheckoutNegotiationInput = {
  selectedProductDetail: ProductCheckoutNegotiationInput;
  spec: ItemCheckoutSpec;
};

export type ItemCheckoutSpec = {
  Notes?: InputMaybe<Scalars['String']>;
  Qty: Scalars['Float'];
  itemId: Scalars['ID'];
};

export type ItemInvoice = {
  __typename?: 'ItemInvoice';
  DPP: Scalars['Float'];
  PDN: Pdn;
  PPN: Scalars['Float'];
  PPnBM: Scalars['Float'];
  addOns: Array<Addon>;
  image: Scalars['String'];
  labels?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  note: Scalars['String'];
  ppnBMPercentage: Scalars['Float'];
  ppnPercentage: Scalars['Float'];
  preOrder?: Maybe<PreOrder>;
  qty: Scalars['Float'];
  qtyType: Scalars['String'];
  regionName: Scalars['String'];
  subTotal: Scalars['Float'];
  type: Scalars['String'];
  url: Scalars['String'];
  variant: Scalars['String'];
  weight: Scalars['String'];
};

export type ItemVolumetric = {
  __typename?: 'ItemVolumetric';
  height: Scalars['Float'];
  length: Scalars['Float'];
  weight: Scalars['Float'];
  width: Scalars['Float'];
};

export type IzinInfo = {
  __typename?: 'IzinInfo';
  KlsName?: Maybe<Scalars['String']>;
  UpdatedAt?: Maybe<Scalars['String']>;
  activeUntil?: Maybe<Scalars['String']>;
  auditUpdate?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instansi?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  izinUsahaDate?: Maybe<Scalars['String']>;
  izinUsahaId?: Maybe<Scalars['Int']>;
  jenisIzinId?: Maybe<Scalars['String']>;
  klsId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  sikapCreatedAt?: Maybe<Scalars['String']>;
  sourceData?: Maybe<Scalars['String']>;
};

export enum JenisKlpdOption {
  Bumd = 'BUMD',
  Bumn = 'BUMN',
  Kabupaten = 'KABUPATEN',
  Kementerian = 'KEMENTERIAN',
  Kota = 'KOTA',
  Lainnya = 'LAINNYA',
  Lembaga = 'LEMBAGA',
  Provinsi = 'PROVINSI',
  Ptnbh = 'PTNBH',
  Swasta = 'SWASTA'
}

export enum JenisKlpdOptionMnC {
  Bumd = 'BUMD',
  Bumn = 'BUMN',
  Kabupaten = 'KABUPATEN',
  Kementerian = 'KEMENTERIAN',
  Kota = 'KOTA',
  Lainnya = 'LAINNYA',
  Lembaga = 'LEMBAGA',
  Provinsi = 'PROVINSI',
  Swasta = 'SWASTA'
}

export type Kbki = {
  __typename?: 'KBKI';
  classification?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<KbkiLevel>;
  parentCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum KbkiLevel {
  Class = 'CLASS',
  Commodity = 'COMMODITY',
  CommodityGroup = 'COMMODITY_GROUP',
  Division = 'DIVISION',
  Group = 'GROUP',
  Section = 'SECTION',
  Subclass = 'SUBCLASS'
}

export type KbkiList = {
  __typename?: 'KBKIList';
  currentPage?: Maybe<Scalars['Int']>;
  items: Array<Kbki>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type Kbli = {
  __typename?: 'KBLI';
  classification?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<KbliLevel>;
  parentCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type KbliInfo = {
  __typename?: 'KBLIInfo';
  categoryId: Scalars['String'];
  createdAt: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  jenisIzin: Scalars['String'];
  rekanId: Scalars['Int'];
  sellerId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum KbliLevel {
  Category = 'CATEGORY',
  Class = 'CLASS',
  Group = 'GROUP',
  Maingroup = 'MAINGROUP',
  Subgroup = 'SUBGROUP'
}

export type KbliList = {
  __typename?: 'KBLIList';
  currentPage?: Maybe<Scalars['Int']>;
  items: Array<Kbli>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type Kanwil = {
  __typename?: 'Kanwil';
  kodeKanwil: Scalars['String'];
  kodeKanwilAdj: Scalars['String'];
  namaKanwil: Scalars['String'];
};

export enum KetSatkerOption {
  Blud = 'BLUD',
  OpdNonBlu = 'OPD_NON_BLU',
  Satker = 'SATKER'
}

export type Klpd = {
  __typename?: 'Klpd';
  createdAt: Scalars['String'];
  groupKlpd: Scalars['String'];
  jenisKlpd: JenisKlpdOption;
  kodeGroupKlpd: Scalars['String'];
  kodeJenisKlpd: Scalars['String'];
  kodeKlpd: Scalars['String'];
  kodeKlpdEcatalog?: Maybe<Scalars['String']>;
  kodeKlpdPadi?: Maybe<Scalars['String']>;
  kodeKlpdSakti?: Maybe<Scalars['String']>;
  kodeKlpdSipd?: Maybe<Scalars['String']>;
  kodeKlpdSirup?: Maybe<Scalars['String']>;
  namaKlpd: Scalars['String'];
  namaKlpdEcatalog?: Maybe<Scalars['String']>;
  namaKlpdPadi?: Maybe<Scalars['String']>;
  namaKlpdSakti?: Maybe<Scalars['String']>;
  namaKlpdSipd?: Maybe<Scalars['String']>;
  namaKlpdSirup?: Maybe<Scalars['String']>;
};

export type KlpdFilter = {
  groupKlpd?: InputMaybe<GroupKlpdOption>;
  jenisKlpd?: InputMaybe<JenisKlpdOption>;
  kodeGroupKlpd?: InputMaybe<Scalars['String']>;
  kodeJenisKlpd?: InputMaybe<Scalars['String']>;
  kodeKlpds?: InputMaybe<Array<Scalars['String']>>;
  kodeKlpdsSakti?: InputMaybe<Array<Scalars['String']>>;
  kodeKlpdsSirup?: InputMaybe<Array<Scalars['String']>>;
  namaKlpd?: InputMaybe<Scalars['String']>;
};

export type KlpdFilterMnC = {
  groupKlpd?: InputMaybe<GroupKlpdOptionMnC>;
  jenisKlpd?: InputMaybe<JenisKlpdOptionMnC>;
  kodeGroupKlpd?: InputMaybe<Scalars['String']>;
  kodeJenisKlpd?: InputMaybe<Scalars['String']>;
  kodeKlpds?: InputMaybe<Array<Scalars['String']>>;
  kodeKlpdsSakti?: InputMaybe<Array<Scalars['String']>>;
  kodeKlpdsSirup?: InputMaybe<Array<Scalars['String']>>;
  namaKlpd?: InputMaybe<Scalars['String']>;
};

export type KlpdMnC = {
  __typename?: 'KlpdMnC';
  createdAt: Scalars['String'];
  groupKlpd: Scalars['String'];
  jenisKlpd: JenisKlpdOptionMnC;
  kodeGroupKlpd: Scalars['String'];
  kodeJenisKlpd: Scalars['String'];
  kodeKlpd: Scalars['String'];
  kodeKlpdEcatalog?: Maybe<Scalars['String']>;
  kodeKlpdPadi?: Maybe<Scalars['String']>;
  kodeKlpdSakti?: Maybe<Scalars['String']>;
  kodeKlpdSipd?: Maybe<Scalars['String']>;
  kodeKlpdSirup?: Maybe<Scalars['String']>;
  namaKlpd: Scalars['String'];
  namaKlpdEcatalog?: Maybe<Scalars['String']>;
  namaKlpdPadi?: Maybe<Scalars['String']>;
  namaKlpdSakti?: Maybe<Scalars['String']>;
  namaKlpdSipd?: Maybe<Scalars['String']>;
  namaKlpdSirup?: Maybe<Scalars['String']>;
};

export type KlpdResponse = GenericError | KlpdResult;

export type KlpdResponseMnC = Error | KlpdResultMnC;

export type KlpdResult = {
  __typename?: 'KlpdResult';
  items: Array<Klpd>;
  paginationInfo: PaginationInfo;
};

export type KlpdResultMnC = {
  __typename?: 'KlpdResultMnC';
  items: Array<KlpdMnC>;
  paginationInfo: PaginationInfo;
};

export type Kppn = {
  __typename?: 'Kppn';
  kodeKppn: Scalars['String'];
  kodeKppnAdj: Scalars['String'];
  namaKppn: Scalars['String'];
};

export enum Logistic_Service {
  Cargo = 'CARGO',
  Custom = 'CUSTOM',
  Express = 'EXPRESS',
  Instant = 'INSTANT',
  Regular = 'REGULAR',
  SameDay = 'SAME_DAY'
}

export type LegacyAppPersonaClaimRes = {
  __typename?: 'LegacyAppPersonaClaimRes';
  email?: Maybe<Scalars['String']>;
  instansiId?: Maybe<Scalars['String']>;
  instansiJenis?: Maybe<Scalars['String']>;
  instansiNama?: Maybe<Scalars['String']>;
  lpseId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pegAlamat?: Maybe<Scalars['String']>;
  pegId?: Maybe<Scalars['Int']>;
  pegNik?: Maybe<Scalars['String']>;
  pegNip?: Maybe<Scalars['String']>;
  pegNoPbj?: Maybe<Scalars['String']>;
  pegTelp?: Maybe<Scalars['String']>;
  userAccess?: Maybe<NonPenyediaRoleEnum>;
  userData?: Maybe<UserDataType>;
  username?: Maybe<Scalars['String']>;
};

export type LegacyAppPersonaClaimResponse = GenericError | LegacyAppPersonaClaimRes;

export type ListCategoryInput = {
  filter: CategoryFilterInput;
  pagination: PaginationInput;
};

export type ListInstitution = {
  __typename?: 'ListInstitution';
  institution: Array<Maybe<Institution>>;
  paginationInfo: PaginationInfo;
};

export type ListMasterProductRes = {
  __typename?: 'ListMasterProductRes';
  items?: Maybe<Array<MasterProduct>>;
};

export type ListMasterProductResult = GenericError | ListMasterProductRes;

export type ListPaymentInput = {
  filter?: InputMaybe<PaymentFilter>;
  pagination?: InputMaybe<PageInput>;
  sort?: InputMaybe<Array<PaymentSort>>;
};

export type ListPaymentResponse = {
  __typename?: 'ListPaymentResponse';
  currentPage?: Maybe<Scalars['Int']>;
  items: Array<PaymentDetail>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ListPersonaFilterInput = {
  statuses?: InputMaybe<Array<PersonaStatus>>;
};

export type ListPersonaNonPenyedia = {
  __typename?: 'ListPersonaNonPenyedia';
  persona: Array<Maybe<UserPersonaNonPenyedia>>;
};

export type ListPersonaNonPenyediaResponse = GenericError | ListPersonaNonPenyedia;

export type ListPersonaPenyedia = {
  __typename?: 'ListPersonaPenyedia';
  persona: Array<Maybe<UserPersonaPenyedia>>;
};

export type ListPersonaPenyediaResponse = GenericError | ListPersonaPenyedia;

export type ListPpn = {
  __typename?: 'ListPpn';
  items: Array<ListPpnItem>;
};

export type ListPpnItem = {
  __typename?: 'ListPpnItem';
  description?: Maybe<Scalars['String']>;
  ppnPercentage: Scalars['Float'];
};

export type ListPpnResult = GenericError | ListPpn;

export type ListProductBulkActionDetailItemInput = {
  id: Scalars['ID'];
  pagination: PaginationInput;
};

export type ListProductBulkActionDetailItemResponse = {
  __typename?: 'ListProductBulkActionDetailItemResponse';
  currentPage?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<ProductBulkActionDetailItem>>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProductBulkActionDetailItemResult = GenericError | ListProductBulkActionDetailItemResponse;

export type ListProductBulkActionInput = {
  pagination: PaginationInput;
};

export type ListProductBulkActionResponse = {
  __typename?: 'ListProductBulkActionResponse';
  currentPage?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<ProductBulkAction>>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProductBulkActionResult = GenericError | ListProductBulkActionResponse;

export type ListProductCategoryResult = GenericError | ProductCategories;

export type ListProductChanges = {
  __typename?: 'ListProductChanges';
  currentPage: Scalars['Int'];
  items?: Maybe<Array<ProductChangesSummary>>;
  lastPage: Scalars['Int'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListProductChangesResult = GenericError | ListProductChanges;

export type ListProductFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  keyword?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  maxPrice?: InputMaybe<Scalars['Float']>;
  minPrice?: InputMaybe<Scalars['Float']>;
  productTypes?: InputMaybe<Array<ProductType>>;
  rateTypes?: InputMaybe<Array<RateType>>;
  regionCode?: InputMaybe<Scalars['String']>;
  sellerRegionCodes?: InputMaybe<Array<Scalars['String']>>;
  sellerTypes?: InputMaybe<Array<SellerType>>;
  status?: InputMaybe<Array<ProductStatusType>>;
  tkdnBmp?: InputMaybe<Scalars['Float']>;
};

export type ListProductInput = {
  filter?: InputMaybe<ListProductFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SearchProductSortInput>>;
};

export type ListProductRes = {
  __typename?: 'ListProductRes';
  currentPage?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Product>>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProductResult = GenericError | ListProductRes;

export type ListRupFilter = {
  fiscalYear?: InputMaybe<Scalars['Int']>;
  fundingSource?: InputMaybe<Scalars['String']>;
  instituteId?: InputMaybe<Scalars['String']>;
  instituteType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  procurementMethod?: InputMaybe<Scalars['String']>;
  procurementType?: InputMaybe<Scalars['String']>;
  rupCode?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  workUnitId?: InputMaybe<Scalars['Int']>;
};

export type ListRupInput = {
  filter?: InputMaybe<ListRupFilter>;
  page?: InputMaybe<PageInput>;
};

export type ListRupResponse = {
  __typename?: 'ListRupResponse';
  currentPage: Scalars['Int'];
  items?: Maybe<Array<Rup>>;
  lastPage: Scalars['Int'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListSearchProductResponse = {
  __typename?: 'ListSearchProductResponse';
  aggregations?: Maybe<Array<ProductSearchAggregation>>;
  currentPage?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<SearchProduct>>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ListSearchProductResult = GenericError | ListSearchProductResponse;

export type ListTkdn = {
  __typename?: 'ListTKDN';
  items?: Maybe<Array<Tkdn>>;
};

export type ListTkdnResult = GenericError | ListTkdn;

export type LoginInaprocMetaDataInfo = {
  __typename?: 'LoginInaprocMetaDataInfo';
  institutionID?: Maybe<Scalars['String']>;
  lpseID?: Maybe<Scalars['Int']>;
  lpseUsername?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type LoginInaprocOutput = {
  __typename?: 'LoginInaprocOutput';
  accessToken?: Maybe<Scalars['String']>;
  expiresIn?: Maybe<Scalars['Int']>;
  metadata?: Maybe<LoginInaprocMetaDataInfo>;
  onBoarding?: Maybe<StatusOnBoardingInfoMnC>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type Logistic = {
  __typename?: 'Logistic';
  code: Scalars['String'];
  id: Scalars['ID'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
};

export type LogisticConfiguration = {
  __typename?: 'LogisticConfiguration';
  code: Scalars['String'];
  id: Scalars['ID'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  rates: Array<LogisticRateConfiguration>;
};

export type LogisticConfigurationResponse = {
  __typename?: 'LogisticConfigurationResponse';
  data?: Maybe<Array<LogisticConfiguration>>;
};

export type LogisticConfigurationResult = GenericError | LogisticConfigurationResponse;

export type LogisticData = {
  __typename?: 'LogisticData';
  code: Scalars['String'];
  id: Scalars['ID'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  rates: Array<LogisticRate>;
};

export type LogisticDeliveryAddress = {
  __typename?: 'LogisticDeliveryAddress';
  address: Scalars['String'];
  buyerId: Scalars['ID'];
  cityName: Scalars['String'];
  districtName: Scalars['String'];
  label: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceName: Scalars['String'];
  villageAreaCode: Scalars['ID'];
  villageName: Scalars['String'];
};

export type LogisticDeliveryEstimation = {
  __typename?: 'LogisticDeliveryEstimation';
  maxDay?: Maybe<Scalars['Int']>;
  minDay?: Maybe<Scalars['Int']>;
};

export enum LogisticDeliveryOrderStatus {
  Canceled = 'CANCELED',
  CanceledAddendum = 'CANCELED_ADDENDUM',
  Completed = 'COMPLETED',
  Delivered = 'DELIVERED',
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Locked = 'LOCKED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Revised = 'REVISED',
  Shipping = 'SHIPPING'
}

export type LogisticDeliveryPrices = {
  __typename?: 'LogisticDeliveryPrices';
  basePrice: Scalars['Float'];
  discount: Scalars['Float'];
  finalPrice: Scalars['Float'];
  insuranceFee: Scalars['Float'];
  surchargeFee: Scalars['Float'];
};

export type LogisticDeliveryProofOfDelivery = {
  __typename?: 'LogisticDeliveryProofOfDelivery';
  photo?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
};

export type LogisticDeliveryVolumetric = {
  __typename?: 'LogisticDeliveryVolumetric';
  dimensionUnit: ProductDimensionUnit;
  height: Scalars['Float'];
  length: Scalars['Float'];
  volume: Scalars['Float'];
  weight: Scalars['Float'];
  weightUnit: ProductWeightUnit;
  width: Scalars['Float'];
};

export type LogisticDocumentActivities = {
  __typename?: 'LogisticDocumentActivities';
  activity: Scalars['String'];
  bastNumber: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  deliveryOrderId: Scalars['String'];
  documentNumber: Scalars['String'];
  documentToken: Scalars['String'];
  documentType: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type LogisticDocumentActivitiesArr = {
  __typename?: 'LogisticDocumentActivitiesArr';
  logisticDocumentActivitiesArr?: Maybe<Array<Maybe<LogisticDocumentActivities>>>;
};

export type LogisticDocumentActivitiesResult = GenericError | LogisticDocumentActivitiesArr;

export type LogisticRate = {
  __typename?: 'LogisticRate';
  id: Scalars['ID'];
  maxWeight: Scalars['Int'];
  minWeight: Scalars['Int'];
  name: Scalars['String'];
  provider: Scalars['String'];
  providerRateId: Scalars['String'];
  type: Logistic_Service;
  volumetricFactor: Scalars['Int'];
};

export type LogisticRateConfiguration = {
  __typename?: 'LogisticRateConfiguration';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isShown: Scalars['Boolean'];
  name: Scalars['String'];
  type: Logistic_Service;
};

export type LogisticResult = GenericError | LogisticData;

export type LogisticSimpleDeliveryAddress = {
  __typename?: 'LogisticSimpleDeliveryAddress';
  cityName: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  provinceName: Scalars['String'];
  sellerId: Scalars['ID'];
};

export type LogisticUpdateUserLogisticConfiguration = {
  __typename?: 'LogisticUpdateUserLogisticConfiguration';
  active: Scalars['Boolean'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  rateId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type LowestProductSoldPrice = {
  __typename?: 'LowestProductSoldPrice';
  orderedAt?: Maybe<Scalars['Time']>;
  productId: Scalars['ID'];
  productVariantId: Scalars['ID'];
  quantityPurchase?: Maybe<Scalars['Int']>;
  region?: Maybe<RegionTraceParent>;
  soldPrice?: Maybe<Scalars['Float']>;
  stockUnit?: Maybe<ProductStockUnit>;
};

export type LowestProductSoldPriceRes = {
  __typename?: 'LowestProductSoldPriceRes';
  items?: Maybe<Array<LowestProductSoldPrice>>;
};

export type LowestProductSoldPriceResult = GenericError | LowestProductSoldPriceRes;

export type MasterProduct = {
  __typename?: 'MasterProduct';
  brand?: Maybe<Brand>;
  category: MasterProductCategorySpec;
  id: Scalars['ID'];
  images: Array<MasterProductImage>;
  informations: MasterProductInformation;
  kbki: Scalars['String'];
  name: Scalars['String'];
  nationalMaximumRetailPrice?: Maybe<Scalars['Float']>;
  nie?: Maybe<Nie>;
  pdn: ProductPdn;
  regionPrices?: Maybe<Array<MasterProductRegionPrice>>;
  sni?: Maybe<Sni>;
  tkdn?: Maybe<Tkdn>;
  type: ProductType;
  videoSource?: Maybe<VideoSourceType>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type MasterProductAvailability = {
  __typename?: 'MasterProductAvailability';
  available: Scalars['Boolean'];
};

export type MasterProductCategorySpec = {
  __typename?: 'MasterProductCategorySpec';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MasterProductImage = {
  __typename?: 'MasterProductImage';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type MasterProductInformation = {
  __typename?: 'MasterProductInformation';
  additionalInformations?: Maybe<Array<MasterProductInformationDetail>>;
  mainInformations?: Maybe<Array<MasterProductInformationDetail>>;
};

export type MasterProductInformationDetail = {
  __typename?: 'MasterProductInformationDetail';
  createdAt: Scalars['Time'];
  customFormFieldId: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
  value: Scalars['String'];
};

export type MasterProductRegionPrice = {
  __typename?: 'MasterProductRegionPrice';
  childRegionPrices?: Maybe<Array<ChildRegionPrice>>;
  id: Scalars['ID'];
  maximumRetailPrice?: Maybe<Scalars['Float']>;
  parentRegionCode?: Maybe<Scalars['String']>;
  regionCode: Scalars['String'];
  regionLevel: Scalars['String'];
  regionName: Scalars['String'];
};

export type MasterProductRegionPriceInput = {
  childRegionPrices?: InputMaybe<Array<ChildMasterProductRegionPriceInput>>;
  id?: InputMaybe<Scalars['ID']>;
  maximumRetailPrice?: InputMaybe<Scalars['Float']>;
  parentRegionCode?: InputMaybe<Scalars['String']>;
  regionCode: Scalars['String'];
};

export type MasterProductResponse = {
  __typename?: 'MasterProductResponse';
  data?: Maybe<MasterProduct>;
  errors?: Maybe<Array<Error>>;
};

export type MasterProductResult = GenericError | MasterProduct;

export type MasterProductStatusResponse = GenericError | StatusResponse;

export type MeResponse = GenericError | UserInfo;

export type MemberFile = {
  __typename?: 'MemberFile';
  publicUrl?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MemberMessageResponse = {
  __typename?: 'MemberMessageResponse';
  message?: Maybe<Scalars['String']>;
};

export type MemberPagination = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type MessageRes = {
  __typename?: 'MessageRes';
  message?: Maybe<Scalars['String']>;
};

export type MessageResponse = GenericError | MessageRes;

export type Metadata = {
  __typename?: 'Metadata';
  bankName?: Maybe<Scalars['String']>;
  billKey?: Maybe<Scalars['String']>;
  billerCode?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  qrCode?: Maybe<Scalars['String']>;
  reasonNotUploadFakturPajak?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  vaNumber?: Maybe<Scalars['String']>;
};

export type MinifiedProductCategory = {
  __typename?: 'MinifiedProductCategory';
  children?: Maybe<Array<MinifiedProductCategory>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  productType?: Maybe<ProductType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  DeliveryOrderConfirmation: DeliveryOrderConfirmationResult;
  acceptAdminTransfer: AcceptAdminTransferResponse;
  acceptCompanyMembership: AcceptCompanyMembershipResponse;
  acceptVerification: AcceptVerificationResponse;
  addCompanyBank: AddCompanyBankResponse;
  addPersonaShippingAddress: AddPersonaShippingAddressResponse;
  assignPayment: AssignPaymentResult;
  cartAddItemV2: CartAddItemV2Result;
  cartCheckoutWithNegotiationV2: CartCheckoutWithNegotiationV2Result;
  cartDeleteItemsV2: CartDeleteItemV2Result;
  cartDirectCheckout: CartDirectCheckoutResult;
  cartSaveCheckoutMetadata: CartSaveCheckoutMetadataResult;
  cartUpdateItemsV2: CartUpdateItemV2Result;
  createAgreement?: Maybe<GenericError>;
  createCategory: CreateProductCategoryResult;
  createMasterProduct: MasterProductResult;
  createProduct?: Maybe<ProductResult>;
  createProductBulkAction: CreateProductBulkActionResult;
  createProductBulkUploadRequest: CreateProductBulkUploadRequestResult;
  createProductFromMasterProduct?: Maybe<ProductResult>;
  createQRIS: GenerateQrisResponse;
  createQRISWithToken: GenerateQrisResponse;
  createQuestionnaire: QuestionnaireResponse;
  createUserData: CreateUserDataResponse;
  createVA: GenerateVaResponse;
  delegatePersona: MessageResponse;
  deleteCategory: DeleteProductCategoryResult;
  deleteCompanyBank: DeleteCompanyBankResponse;
  deleteMasterProduct: MasterProductStatusResponse;
  deletePersonaShippingAddress: DeletePersonaShippingAddressResponse;
  deleteProduct?: Maybe<ProductStatusResponse>;
  eSignBAST: ESignBastResult;
  /** @deprecated Moved to orderGenerateDocument */
  generateLetter: GenerateLetterResult;
  negotiationApprove: NegotiationApproveResult;
  negotiationCancel: NegotiationCancelResult;
  negotiationComplete: NegotiationCompleteResult;
  negotiationPropose: NegotiationProposeResult;
  negotiationReject: NegotiationRejectResult;
  orderCancel: OrderApprovalResult;
  orderCancelAddendum: OrderCancelAddendumResult;
  orderGenerateDocument: OrderGenerateDocumentResult;
  orderProposeAddendum: OrderProposeAddendumResult;
  orderRejectAddendum: OrderRejectAddendumResult;
  orderRejectDocument: OrderApprovalResult;
  orderRequestCancel: OrderApprovalResult;
  orderRequestCancelAdmin: OrderRequestCancelAdminResult;
  orderRequestCancelAdminApproval: OrderApprovalResult;
  orderRequestCancelApproval: OrderApprovalResult;
  orderRequestSignedUrl: OrderRequestSignedUrlResult;
  orderSignDocumentBuyer: OrderSignDocumentResult;
  orderSignDocumentSeller: OrderSignDocumentResult;
  orderUpdate: OrderUpdateResult;
  orderUpdateReviewer: OrderUpdateReviewerResult;
  payByCreditCard: PayByCreditCardResult;
  payByCreditCardWithToken: PayByCreditCardResult;
  payByLS: PaymentResult;
  /** @deprecated Moved to assignPayment */
  payByVA: PaymentResult;
  penyediaAdminTransfer: PenyediaAdminTransferResponse;
  personaRegisterNonPenyedia: PersonaResponse;
  personaRegisterNonRegisteredPenyedia: PersonaResponse;
  personaRegisterRegisteredPenyedia: PersonaResponse;
  pickUp: PickUpResult;
  publishProductBulkAction: ProductBulkActionResult;
  rejectPayment: RejectPaymentResult;
  rejectVerification: RejectVerificationResponse;
  removeCompanyMembership: RemoveCompanyMembershipResponse;
  /** @deprecated Moved to assignPayment */
  reqPaymentCreditCard: ReqPaymentCreditCardResult;
  reuploadEnterpriseFile: ReuploadEnterpriseFileResponse;
  reuploadFileSoftReject: ReuploadFileSoftRejectResponse;
  setCompanyPICTtd: CompanyResponse;
  /** @deprecated No longer supported */
  setContractAuthCode?: Maybe<ContractUser>;
  /** @deprecated No longer supported */
  setContractAuthCodeAccount?: Maybe<ContractUser>;
  setMainCompanyBank: SetMainCompanyBankResponse;
  /** @deprecated No longer supported */
  unsetContractAuthCode: Scalars['String'];
  updateBASTNo: UpdateBastNoResult;
  updateBankConfig: UpdateBankConfigResponse;
  updateCOA: UpdateCoaResponse;
  updateCategory: UpdateProductCategoryResult;
  updateCompanyBankStatus: UpdateCompanyBankStatusResponse;
  updateCompanyShippingAddress: UpdateCompanyShippingAddressResponse;
  updateDeliveryHistorySellerCourier: UpdateDeliveryHistorySellerCourierResult;
  updateDeviceToken: NotificationUpdateDeviceTokenResponse;
  updateDocumentPaymentStatus: UpdateDocumentPaymentStatusResponse;
  updateMasterProduct: MasterProductResult;
  updateNextOnboardingStep: UpdateNextOnboardingStepResponse;
  updatePaymentMethod: UpdatePaymentMethodResult;
  updatePaymentMethodConfig: UpdatePaymentMethodConfigResponse;
  updatePaymentStatusWithSimulator: UpdatePaymentStatusWithSimulatorResponse;
  updatePersonaShippingAddress: UpdatePersonaShippingAddressResponse;
  updateProduct?: Maybe<ProductResult>;
  updateProductActiveStatus?: Maybe<ProductStatusResponse>;
  updateProductFromMasterProduct?: Maybe<ProductResult>;
  updateProductReview: UpdateProductReviewResponse;
  updateUserLogisticConfiguration: UpdateUserLogisticConfigurationResult;
  updateUserLogisticRegionPrice: UpdateUserLogisticRegionPriceResult;
  updateVerificationProcess?: Maybe<GenericError>;
  uploadAccount: UploadAccountResponse;
  uploadChatFile: UploadChatFileResponse;
  uploadChatUrl: UploadChatUrlResponse;
  /** @deprecated Moved to uploadDocumentPaymentWithReason */
  uploadDocumentPayment: UploadDocumentPaymentResponse;
  uploadDocumentPaymentWithReason: UploadDocumentPaymentResponse;
  /** @deprecated No longer supported */
  uploadEsign?: Maybe<UpdateSignResponse>;
  uploadMember: UploadmemberResponse;
  upsertStoreInformation: UpsertStoreInformationResult;
  upsertStoreNotes: UpsertStoreNotesResponse;
};


export type MutationDeliveryOrderConfirmationArgs = {
  confirmedAt: Scalars['String'];
  deliveryOrderId: Scalars['String'];
};


export type MutationAcceptAdminTransferArgs = {
  input: AcceptAdminTransferInput;
};


export type MutationAcceptCompanyMembershipArgs = {
  input: AcceptCompanyMembershipInput;
};


export type MutationAcceptVerificationArgs = {
  input: AcceptVerificationInput;
};


export type MutationAddCompanyBankArgs = {
  input: AddCompanyBankInput;
};


export type MutationAddPersonaShippingAddressArgs = {
  input: AddressesTypeInput;
  personaId: Scalars['String'];
};


export type MutationAssignPaymentArgs = {
  input: AssignPaymentInput;
};


export type MutationCartAddItemV2Args = {
  input: CartAddItemInput;
};


export type MutationCartCheckoutWithNegotiationV2Args = {
  input: CartCheckoutNegotiationInput;
};


export type MutationCartDeleteItemsV2Args = {
  itemIds: Array<Scalars['ID']>;
};


export type MutationCartDirectCheckoutArgs = {
  input: CartCheckoutInput;
};


export type MutationCartSaveCheckoutMetadataArgs = {
  input: SaveCartCheckoutMetadataInput;
};


export type MutationCartUpdateItemsV2Args = {
  inputs: Array<CartUpdateItemInput>;
};


export type MutationCreateAgreementArgs = {
  appId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateMasterProductArgs = {
  input?: InputMaybe<CreateMasterProductInput>;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateProductBulkActionArgs = {
  input: CreateProductBulkActionInput;
};


export type MutationCreateProductBulkUploadRequestArgs = {
  input: CreateProductBulkUploadRequestInput;
};


export type MutationCreateProductFromMasterProductArgs = {
  input: CreateProductFromMasterProductInput;
};


export type MutationCreateQrisArgs = {
  input: GenerateQrisInput;
};


export type MutationCreateQrisWithTokenArgs = {
  paymentToken: Scalars['ID'];
};


export type MutationCreateQuestionnaireArgs = {
  input?: InputMaybe<Array<InputMaybe<QuestionnaireInput>>>;
};


export type MutationCreateUserDataArgs = {
  input?: InputMaybe<CreateUserDataInput>;
};


export type MutationCreateVaArgs = {
  input: GenerateVaInput;
};


export type MutationDelegatePersonaArgs = {
  input: DelegatePersonaInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanyBankArgs = {
  id: Scalars['String'];
  institusiId: Scalars['String'];
};


export type MutationDeleteMasterProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePersonaShippingAddressArgs = {
  id: Scalars['String'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationESignBastArgs = {
  input: ESignBastInput;
};


export type MutationGenerateLetterArgs = {
  input: GenerateLetterInput;
};


export type MutationNegotiationApproveArgs = {
  id: Scalars['ID'];
};


export type MutationNegotiationCancelArgs = {
  input: CancelNegotiationInput;
};


export type MutationNegotiationCompleteArgs = {
  id: Scalars['ID'];
};


export type MutationNegotiationProposeArgs = {
  input: ProposeNegotiationInput;
};


export type MutationNegotiationRejectArgs = {
  input: RejectNegotiationInput;
};


export type MutationOrderCancelArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationOrderCancelAddendumArgs = {
  input: CancelAddendumInput;
};


export type MutationOrderGenerateDocumentArgs = {
  input: GenerateLetterInput;
};


export type MutationOrderProposeAddendumArgs = {
  input: ProposeAddendumInput;
};


export type MutationOrderRejectAddendumArgs = {
  input: RejectAddendumInput;
};


export type MutationOrderRejectDocumentArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationOrderRequestCancelArgs = {
  input: RequestCancelInput;
};


export type MutationOrderRequestCancelAdminArgs = {
  input: AdminRequestCancelInput;
};


export type MutationOrderRequestCancelAdminApprovalArgs = {
  input: ApprovalInput;
};


export type MutationOrderRequestCancelApprovalArgs = {
  input: ApprovalInput;
};


export type MutationOrderRequestSignedUrlArgs = {
  input: OrderRequestUploadSignedUrlInput;
};


export type MutationOrderSignDocumentBuyerArgs = {
  id: Scalars['ID'];
  passphrase: Scalars['String'];
};


export type MutationOrderSignDocumentSellerArgs = {
  id: Scalars['ID'];
};


export type MutationOrderUpdateArgs = {
  input: OrderUpdateInput;
};


export type MutationOrderUpdateReviewerArgs = {
  input: OrderUpdateReviewerInput;
};


export type MutationPayByCreditCardArgs = {
  input: PayByCreditCardInput;
};


export type MutationPayByCreditCardWithTokenArgs = {
  input: PayByCreditCardWithTokenInput;
};


export type MutationPayByLsArgs = {
  input: PaymentDetailInput;
};


export type MutationPayByVaArgs = {
  input: PayByVaInput;
};


export type MutationPenyediaAdminTransferArgs = {
  input: PenyediaAdminTransferInput;
};


export type MutationPersonaRegisterNonPenyediaArgs = {
  input: PersonaRegisterNonPenyediaInput;
};


export type MutationPersonaRegisterNonRegisteredPenyediaArgs = {
  input?: InputMaybe<PersonaRegisterNonRegisteredPenyediaInput>;
};


export type MutationPersonaRegisterRegisteredPenyediaArgs = {
  input?: InputMaybe<PersonaRegisterRegisteredPenyediaInput>;
};


export type MutationPickUpArgs = {
  input: Array<PickUpInput>;
};


export type MutationPublishProductBulkActionArgs = {
  input: PublishProductBulkActionInput;
};


export type MutationRejectPaymentArgs = {
  input: RejectPaymentInput;
};


export type MutationRejectVerificationArgs = {
  input?: InputMaybe<RejectVerificationInput>;
};


export type MutationRemoveCompanyMembershipArgs = {
  input: RemoveCompanyMembershipInput;
};


export type MutationReqPaymentCreditCardArgs = {
  input: ReqPaymentCreditCardInput;
};


export type MutationReuploadEnterpriseFileArgs = {
  input: ReuploadEnterpriseFileInput;
};


export type MutationReuploadFileSoftRejectArgs = {
  input: ReuploadFileSoftRejectRequest;
};


export type MutationSetCompanyPicTtdArgs = {
  input: SetCompanyPicTtdInput;
};


export type MutationSetContractAuthCodeArgs = {
  input?: InputMaybe<SetAuthCodeRequest>;
};


export type MutationSetContractAuthCodeAccountArgs = {
  input?: InputMaybe<SetAuthCodeRequest>;
};


export type MutationSetMainCompanyBankArgs = {
  id: Scalars['String'];
  institusiId: Scalars['String'];
};


export type MutationUnsetContractAuthCodeArgs = {
  provider?: InputMaybe<ContractProvider>;
};


export type MutationUpdateBastNoArgs = {
  input: BastNoInput;
};


export type MutationUpdateBankConfigArgs = {
  input: Array<UpdateBankConfigInput>;
};


export type MutationUpdateCoaArgs = {
  input: UpdateCoaInput;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  input: UpdateCategoryInput;
};


export type MutationUpdateCompanyBankStatusArgs = {
  input: UpdateCompanyBankStatusInput;
};


export type MutationUpdateCompanyShippingAddressArgs = {
  companyId: Scalars['String'];
  id: Scalars['String'];
  input: AddressesTypeInput;
};


export type MutationUpdateDeliveryHistorySellerCourierArgs = {
  input: UpdateDeliveryHistorySellerCourierInput;
};


export type MutationUpdateDeviceTokenArgs = {
  deviceTokens: Array<Scalars['ID']>;
};


export type MutationUpdateDocumentPaymentStatusArgs = {
  input: UpdateDocumentPaymentStatusInput;
};


export type MutationUpdateMasterProductArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdateMasterProductInput>;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdatePaymentMethodConfigArgs = {
  input: Array<UpdatePaymentMethodConfigInput>;
};


export type MutationUpdatePaymentStatusWithSimulatorArgs = {
  input: UpdatePaymentStatusWithSimulatorInput;
};


export type MutationUpdatePersonaShippingAddressArgs = {
  id: Scalars['String'];
  input: AddressesTypeInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['ID'];
  input: UpdateProductInput;
};


export type MutationUpdateProductActiveStatusArgs = {
  ids: Array<Scalars['ID']>;
  isActive: Scalars['Boolean'];
};


export type MutationUpdateProductFromMasterProductArgs = {
  id: Scalars['ID'];
  input: UpdateProductFromMasterProductInput;
};


export type MutationUpdateProductReviewArgs = {
  id: Scalars['ID'];
  input: ProductReviewInput;
};


export type MutationUpdateUserLogisticConfigurationArgs = {
  input: Array<UserLogisticConfigurationInput>;
};


export type MutationUpdateUserLogisticRegionPriceArgs = {
  input: Array<UpdateUserLogisticRegionPriceInput>;
};


export type MutationUpdateVerificationProcessArgs = {
  input: UpdateVerificationProcessInput;
};


export type MutationUploadAccountArgs = {
  input?: InputMaybe<Array<InputMaybe<UploadAccountRequest>>>;
};


export type MutationUploadChatFileArgs = {
  input: UploadChatFileInput;
};


export type MutationUploadChatUrlArgs = {
  input: UploadChatUrlInput;
};


export type MutationUploadDocumentPaymentArgs = {
  input: UploadDocumentPaymentInput;
};


export type MutationUploadDocumentPaymentWithReasonArgs = {
  input: UploadDocumentPaymentWithReasonInput;
};


export type MutationUploadEsignArgs = {
  input?: InputMaybe<UpdateSignRequest>;
  userRole?: InputMaybe<UserRole>;
};


export type MutationUploadMemberArgs = {
  input?: InputMaybe<Array<UploadMemberRequest>>;
};


export type MutationUpsertStoreInformationArgs = {
  input: StoreInformationInput;
};


export type MutationUpsertStoreNotesArgs = {
  input?: InputMaybe<Array<UpsertStoreNoteInput>>;
};

export type Nie = {
  __typename?: 'NIE';
  category: Scalars['String'];
  class: Scalars['String'];
  companyName: Scalars['String'];
  expirationDate: Scalars['String'];
  hsCode?: Maybe<Scalars['String']>;
  manufacturerAddress?: Maybe<Scalars['String']>;
  manufacturerCountry?: Maybe<Scalars['String']>;
  manufacturerName?: Maybe<Scalars['String']>;
  nib: Scalars['String'];
  nie: Scalars['String'];
  npwp: Scalars['String'];
  packaging?: Maybe<Scalars['String']>;
  permitClassification: Scalars['String'];
  productName: Scalars['String'];
  productType: Scalars['String'];
  publishedDate: Scalars['String'];
  riskClass: Scalars['String'];
  size?: Maybe<Scalars['String']>;
  subCategory: Scalars['String'];
};

export type NieFilterInput = {
  nie: Scalars['String'];
};

export type NieResult = GenericError | Nie;

export type NegotiationApproveResult = ApproveNegotiationResponse | GenericError;

export type NegotiationCancelResult = CancelNegotiationResponse | GenericError;

export type NegotiationCompleteResult = CompleteNegotiationResult | GenericError;

export type NegotiationDetailInput = {
  orderId: Scalars['String'];
  orderKey: Scalars['String'];
  orderNegotiationId: Scalars['String'];
};

export type NegotiationDetailResponse = {
  __typename?: 'NegotiationDetailResponse';
  expiryTime?: Maybe<Scalars['Time']>;
  latestTotal: Scalars['Float'];
  negotiation: NegotiationHistory;
  order: Order;
  orderNegotiationId: Scalars['String'];
  originalTotal: Scalars['Float'];
  reasons: NegotiationReason;
  status: NegotiationStatus;
  timestamp: Scalars['Time'];
};

export type NegotiationDetailResult = GenericError | NegotiationDetailResponse;

export type NegotiationFilter = {
  endDate?: InputMaybe<Scalars['Time']>;
  startDate?: InputMaybe<Scalars['Time']>;
  status?: InputMaybe<Array<NegotiationStatus>>;
  text?: InputMaybe<Scalars['String']>;
};

export type NegotiationHistory = {
  __typename?: 'NegotiationHistory';
  items: Array<NegotiationHistoryItem>;
  shipments: Array<NegotiationHistoryShipment>;
};

export type NegotiationHistoryAddOn = {
  __typename?: 'NegotiationHistoryAddOn';
  addOnDetail: OrderProductAddOn;
  agreementPrice?: Maybe<Scalars['Float']>;
  componentId: Scalars['String'];
  history: Array<NegotiationHistoryDetail>;
  type: NegotiationType;
};

export type NegotiationHistoryDetail = {
  __typename?: 'NegotiationHistoryDetail';
  createdAt: Scalars['Time'];
  isNewProposal: Scalars['Boolean'];
  negotiationId: Scalars['String'];
  newPrice: Scalars['Float'];
  oldPrice: Scalars['Float'];
  proposerRole: NegotiationRole;
};

export type NegotiationHistoryItem = {
  __typename?: 'NegotiationHistoryItem';
  addOns?: Maybe<Array<NegotiationHistoryAddOn>>;
  agreementPrice?: Maybe<Scalars['Float']>;
  componentId: Scalars['String'];
  history: Array<NegotiationHistoryDetail>;
  itemDetail: OrderItem;
  type: NegotiationType;
};

export type NegotiationHistoryShipment = {
  __typename?: 'NegotiationHistoryShipment';
  agreementPrice?: Maybe<Scalars['Float']>;
  componentId: Scalars['String'];
  history: Array<NegotiationHistoryDetail>;
  shipmentDetail: OrderShipment;
  type: NegotiationType;
};

export type NegotiationListInput = {
  filter?: InputMaybe<NegotiationFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<NegotiationSort>;
};

export type NegotiationListResponse = {
  __typename?: 'NegotiationListResponse';
  currentPage?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<OrderNegotiationListSpec>>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type NegotiationListResult = GenericError | NegotiationListResponse;

export type NegotiationProposeResult = GenericError | ProposeNegotiationResponse;

export type NegotiationReason = {
  __typename?: 'NegotiationReason';
  cancelReason?: Maybe<NegotiationReasonSpec>;
  rejectReason?: Maybe<NegotiationReasonSpec>;
};

export type NegotiationReasonSpec = {
  __typename?: 'NegotiationReasonSpec';
  reason?: Maybe<Scalars['String']>;
  reasonDescription?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Time']>;
};

export type NegotiationRejectResult = GenericError | RejectNegotiationResponse;

export enum NegotiationRole {
  Buyer = 'BUYER',
  Seller = 'SELLER'
}

export type NegotiationSort = {
  direction: SortDirection;
  field: NegotiationSortField;
};

export enum NegotiationSortField {
  CreatedAt = 'CREATED_AT'
}

export enum NegotiationStatus {
  BuyerApproved = 'BUYER_APPROVED',
  BuyerOnNegotiation = 'BUYER_ON_NEGOTIATION',
  Cancelled = 'CANCELLED',
  CompletedOrderCancelledOnReview = 'COMPLETED_ORDER_CANCELLED_ON_REVIEW',
  CompletedOrderCreated = 'COMPLETED_ORDER_CREATED',
  CompletedOrderExpiredOnReview = 'COMPLETED_ORDER_EXPIRED_ON_REVIEW',
  CompletedOrderOnReview = 'COMPLETED_ORDER_ON_REVIEW',
  Expired = 'EXPIRED',
  New = 'NEW',
  Rejected = 'REJECTED',
  SellerApproved = 'SELLER_APPROVED',
  SellerCancelled = 'SELLER_CANCELLED',
  SellerOnNegotiation = 'SELLER_ON_NEGOTIATION'
}

export type NegotiationStatusType = {
  __typename?: 'NegotiationStatusType';
  value: NegotiationStatus;
};

export enum NegotiationType {
  AddOn = 'ADD_ON',
  Product = 'PRODUCT',
  Shipment = 'SHIPMENT'
}

export enum NonPenyediaRoleEnum {
  AdminAgencySpse = 'ADMIN_AGENCY_SPSE',
  AdminDaftarHitam = 'ADMIN_DAFTAR_HITAM',
  AdminLokalSektoral = 'ADMIN_LOKAL_SEKTORAL',
  AdminMonev = 'ADMIN_MONEV',
  AdminNasionalV5 = 'ADMIN_NASIONAL_V5',
  AdminPengelola = 'ADMIN_PENGELOLA',
  AdminPpeSirup = 'ADMIN_PPE_SIRUP',
  AdminPpeSpse = 'ADMIN_PPE_SPSE',
  AdminRup = 'ADMIN_RUP',
  AgenHelpdeskV5 = 'AGEN_HELPDESK_V5',
  Apip = 'APIP',
  AuditorEkatalogV5 = 'AUDITOR_EKATALOG_V5',
  AuditorSpse = 'AUDITOR_SPSE',
  Bendahara = 'BENDAHARA',
  CategoryManager = 'CATEGORY_MANAGER',
  Cms = 'CMS',
  CmsMonev = 'CMS_MONEV',
  Direktur = 'DIREKTUR',
  FinOps = 'FIN_OPS',
  HelpdeskL0 = 'HELPDESK_L0',
  HelpdeskL1 = 'HELPDESK_L1',
  InternalTelkom = 'INTERNAL_TELKOM',
  KepalaUkpbj = 'KEPALA_UKPBJ',
  Kpa = 'KPA',
  KuppbjSpse = 'KUPPBJ_SPSE',
  Lkpp = 'LKPP',
  NonGovtBuyer = 'NON_GOVT_BUYER',
  Pa = 'PA',
  Pelapor = 'PELAPOR',
  PengelolaLokal = 'PENGELOLA_LOKAL',
  Pmep = 'PMEP',
  Pokja = 'POKJA',
  Pp = 'PP',
  Ppk = 'PPK',
  SekretariatKlpd = 'SEKRETARIAT_KLPD',
  StafPmep = 'STAF_PMEP',
  SuperAdminSirup = 'SUPER_ADMIN_SIRUP',
  SuperAdminV5 = 'SUPER_ADMIN_V5',
  Trainer = 'TRAINER',
  VerifikatorSpse = 'VERIFIKATOR_SPSE'
}

export type NotificationChannelCredential = {
  __typename?: 'NotificationChannelCredential';
  deviceTokens?: Maybe<Array<Maybe<Scalars['String']>>>;
  providerId: Scalars['String'];
};

export type NotificationSubscriberHash = {
  __typename?: 'NotificationSubscriberHash';
  subscriberHash: Scalars['String'];
  subscriberId: Scalars['String'];
};

export type NotificationUpdateDeviceTokenResponse = {
  __typename?: 'NotificationUpdateDeviceTokenResponse';
  credentials?: Maybe<Array<Maybe<NotificationChannelCredential>>>;
  userId: Scalars['String'];
};

export type OnBoardingInfo = {
  __typename?: 'OnBoardingInfo';
  app?: Maybe<AppType>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  historyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isOnboarding?: Maybe<Scalars['Boolean']>;
  relationId?: Maybe<Scalars['String']>;
  ruleId?: Maybe<Scalars['String']>;
  ruleName?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  stepId?: Maybe<Scalars['String']>;
  stepName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type OnBoardingRuleInfo = {
  __typename?: 'OnBoardingRuleInfo';
  app?: Maybe<AppType>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  stepId?: Maybe<Scalars['String']>;
  stepName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export enum OnboardingStep {
  Done = 'DONE',
  LinkPrivy = 'LINK_PRIVY',
  PickSchedule = 'PICK_SCHEDULE',
  UploadNikNip = 'UPLOAD_NIK_NIP'
}

export type Order = {
  __typename?: 'Order';
  addenda?: Maybe<Array<OrderAddendum>>;
  addendumStatus?: Maybe<AddendumStatus>;
  additionalFee?: Maybe<AdditionalFee>;
  approvalLabel?: Maybe<ApprovalLabel>;
  buyerInfo: OrderBuyerData;
  cancellationDetail?: Maybe<CancellationDetail>;
  company: PublicCompany;
  currency: Currency;
  discount?: Maybe<OrderDiscount>;
  documents?: Maybe<Array<OrderDocument>>;
  expiryTime?: Maybe<Scalars['Time']>;
  hasLuxuryItem: Scalars['Boolean'];
  id: Scalars['ID'];
  isCOAFilled: Scalars['Boolean'];
  isFtz: Scalars['Boolean'];
  isOrderTest: Scalars['Boolean'];
  items: Array<OrderItem>;
  longestPreorderSLA?: Maybe<Scalars['Int']>;
  orderKey: Scalars['String'];
  orderNegotiationIds?: Maybe<Array<Scalars['ID']>>;
  orderNumber: Scalars['String'];
  originalTotal: Scalars['Float'];
  originalTotalWithoutTax: Scalars['Float'];
  paymentStatus: OrderPaymentStatus;
  payments: Array<OrderPayment>;
  reason?: Maybe<Scalars['String']>;
  sellerInfo: OrderSellerInfo;
  shipmentStatus: OrderShipmentStatus;
  shipments: Array<OrderShipment>;
  status: OrderStatus;
  timestamp: Scalars['Time'];
  total: Scalars['Float'];
  totalWithoutTax: Scalars['Float'];
  updatedAt: Scalars['Time'];
  viaNegotiation: Scalars['Boolean'];
};

export type OrderAddOnTotalSummary = {
  __typename?: 'OrderAddOnTotalSummary';
  totalPpn: Scalars['Float'];
  totalPrice: Scalars['Float'];
  totalQty: Scalars['Float'];
};

export type OrderAddendum = {
  __typename?: 'OrderAddendum';
  addendumNumber: Scalars['String'];
  additionalFee?: Maybe<AdditionalFee>;
  clauses?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['Time'];
  createdBy: Scalars['String'];
  discount?: Maybe<OrderDiscount>;
  histories: Array<AddendumHistory>;
  id: Scalars['ID'];
  newTotal: Scalars['Float'];
  newTotalWithoutTax: Scalars['Float'];
  oldTotal: Scalars['Float'];
  oldTotalWithoutTax: Scalars['Float'];
  orderItems?: Maybe<Array<AddendumOrderItem>>;
  reason?: Maybe<Scalars['String']>;
  revisionNumber: Scalars['Int'];
  shipments?: Maybe<Array<AddendumShipment>>;
  status: AddendumStatus;
  updatedAt: Scalars['Time'];
  updatedBy: Scalars['String'];
};

export type OrderApprovalResult = ApprovalResponse | GenericError;

export type OrderBuyerData = {
  __typename?: 'OrderBuyerData';
  assignedPPK: OrderBuyerInfo;
  assignedUser: OrderBuyerInfo;
};

export type OrderBuyerInfo = {
  __typename?: 'OrderBuyerInfo';
  buyerSnapshot: OrderBuyerSnapshotData;
  userInfo: PublicUserInfo;
};

export enum OrderBuyerRole {
  Bendahara = 'BENDAHARA',
  Pp = 'PP',
  Ppk = 'PPK'
}

export type OrderBuyerSnapshotData = {
  __typename?: 'OrderBuyerSnapshotData';
  alamatSatker: Scalars['String'];
  buyerId: Scalars['String'];
  institutionName: Scalars['String'];
  namaUser: Scalars['String'];
  nameSatuanKerja: Scalars['String'];
  npwp: Scalars['String'];
  personaId: Scalars['String'];
  personaRole: OrderBuyerRole;
  unit: Scalars['String'];
};

export type OrderBuyerUserDataInput = {
  buyerID: Scalars['String'];
  personaID: Scalars['String'];
  userID: Scalars['String'];
};

export type OrderCoa = {
  __typename?: 'OrderCOA';
  coa16Seg: Scalars['ID'];
  itemDesc: Scalars['String'];
  itemId: Scalars['ID'];
  subKompDesc: Scalars['String'];
};

export type OrderCancelAddendumResult = CancelAddendumResponse | GenericError;

export type OrderCompany = {
  __typename?: 'OrderCompany';
  address: OrderCompanyAddress;
  id: Scalars['ID'];
  isUKM?: Maybe<Scalars['Boolean']>;
  isUMKK?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  npwp: Scalars['String'];
  slug: Scalars['String'];
};

export type OrderCompanyAddress = {
  __typename?: 'OrderCompanyAddress';
  address: Scalars['String'];
  city: Scalars['String'];
  id: Scalars['ID'];
  isMainAddress: Scalars['Boolean'];
  postalCode: Scalars['String'];
  villageAreaCode: Scalars['String'];
};

export type OrderDetailResult = GenericError | Order;

export type OrderDiscount = {
  __typename?: 'OrderDiscount';
  freeTax?: Maybe<Scalars['Float']>;
};

export type OrderDocument = {
  __typename?: 'OrderDocument';
  AdditionalClauses?: Maybe<Array<Scalars['String']>>;
  buyerUrl?: Maybe<Scalars['String']>;
  category: DocumentCategory;
  createdAt: Scalars['Time'];
  documentTokenProvider?: Maybe<Scalars['String']>;
  errorReason?: Maybe<Scalars['String']>;
  expiry: Scalars['Int'];
  fileType: Scalars['String'];
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  name: Scalars['String'];
  sellerUrl?: Maybe<Scalars['String']>;
  status: DocumentStatus;
  updatedAt: Scalars['Time'];
};

export type OrderDocumentDetailResult = GenericError | Order;

export type OrderFilter = {
  dateField?: InputMaybe<OrderFilterDateField>;
  endDate?: InputMaybe<Scalars['Time']>;
  shipmentService?: InputMaybe<OrderShipmentService>;
  startDate?: InputMaybe<Scalars['Time']>;
  status?: InputMaybe<Array<OrderStatus>>;
  text?: InputMaybe<Scalars['String']>;
};

export enum OrderFilterDateField {
  CreatedAt = 'CREATED_AT',
  OrderedAt = 'ORDERED_AT'
}

export type OrderGenerateDocumentResult = GenerateLetterResponse | GenericError;

export type OrderInput = {
  id: Scalars['String'];
  orderKey: Scalars['String'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  addOns: Array<OrderProductAddOn>;
  coa?: Maybe<OrderCoa>;
  id: Scalars['ID'];
  lastPrice: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  originalQty: Scalars['Float'];
  productId: Scalars['ID'];
  productLabels: Array<Scalars['String']>;
  productVariantId: Scalars['ID'];
  qty: Scalars['Float'];
  snapshot: OrderProductSnapshot;
  subTotal: Scalars['Float'];
  subTotalWithoutTax: Scalars['Float'];
  tax: OrderTax;
};

export type OrderListInput = {
  filter?: InputMaybe<OrderFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<OrderSort>;
};

export type OrderListResponse = {
  __typename?: 'OrderListResponse';
  currentPage?: Maybe<Scalars['Int']>;
  items: Array<OrderSummary>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type OrderListResult = GenericError | OrderListResponse;

export type OrderNegotiationListSpec = {
  __typename?: 'OrderNegotiationListSpec';
  buyer: OrderBuyerInfo;
  company: PublicCompany;
  expiryTime?: Maybe<Scalars['Time']>;
  firstItem: OrderItem;
  id: Scalars['ID'];
  latestTotal: Scalars['Float'];
  latestTotalWithoutTax: Scalars['Float'];
  longestPreorderSLA?: Maybe<Scalars['Int']>;
  orderId: Scalars['ID'];
  orderKey: Scalars['String'];
  orderNumber: Scalars['String'];
  originalTotal: Scalars['Float'];
  originalTotalWithoutTax: Scalars['Float'];
  reasons: NegotiationReason;
  rowItemCount: Scalars['Int'];
  sellerInfo: OrderSellerInfo;
  shipments: Array<OrderSummaryShipment>;
  status: NegotiationStatus;
  timestamp: Scalars['Time'];
  version: Scalars['Int'];
};

export type OrderPpn = {
  __typename?: 'OrderPPN';
  addOn?: Maybe<Scalars['Float']>;
  product?: Maybe<Scalars['Float']>;
  shipment?: Maybe<Scalars['Float']>;
  shipmentInsurance?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type OrderPayment = {
  __typename?: 'OrderPayment';
  createdAt: Scalars['Time'];
  createdBy: Scalars['String'];
  fee: Scalars['Float'];
  id: Scalars['String'];
  method: OrderPaymentMethod;
  orderId: Scalars['ID'];
  paidAt?: Maybe<Scalars['Time']>;
  paymentDetail?: Maybe<PaymentDetailResp>;
  pic?: Maybe<Scalars['String']>;
  rup: Rup;
  status: OrderPaymentStatus;
  total: Scalars['Float'];
  uniqueCode: Scalars['Int'];
  updatedAt: Scalars['Time'];
  updatedBy: Scalars['String'];
};

export enum OrderPaymentMethod {
  KartuKreditPemerintah = 'KARTU_KREDIT_PEMERINTAH',
  Qris = 'QRIS',
  SekaliBayar = 'SEKALI_BAYAR',
  VirtualAccount = 'VIRTUAL_ACCOUNT'
}

export enum OrderPaymentStatus {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  OnProcess = 'ON_PROCESS',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Waiting = 'WAITING',
  WaitingDocs = 'WAITING_DOCS'
}

export type OrderPaymentUpdateInput = {
  paymentId: Scalars['String'];
  rupCode?: InputMaybe<Scalars['Int']>;
};

export enum OrderPpnType {
  Ppn = 'PPN',
  Ppnbm = 'PPNBM'
}

export type OrderProductAddOn = {
  __typename?: 'OrderProductAddOn';
  addOnVariantId: Scalars['String'];
  id: Scalars['ID'];
  lastPrice: Scalars['Float'];
  originalPrice: Scalars['Float'];
  qty: Scalars['Float'];
  subTotal: Scalars['Float'];
  subTotalWithoutTax: Scalars['Float'];
  tax: OrderTax;
  type: AddOnType;
  updatedAt: Scalars['Time'];
  updatedBy: Scalars['String'];
};

export type OrderProductSnapshot = {
  __typename?: 'OrderProductSnapshot';
  addOns: Array<ProductSnapshotAddOn>;
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  id: Scalars['ID'];
  images: Array<ProductSnapshotImage>;
  name: Scalars['String'];
  pdn: ProductSnapshotPdn;
  preOrder?: Maybe<ProductSnapshotPreOrder>;
  prices: ProductSnapshotPrice;
  shipping: ProductSnapshotShipping;
  slug: Scalars['String'];
  stockUnit: ProductSnapshotStockUnit;
  tax: OrderProductSnapshotTax;
  type: OrderProductType;
  username: Scalars['String'];
  variants: Array<ProductSnapshotVariant>;
};

export type OrderProductSnapshotAddOnTax = {
  __typename?: 'OrderProductSnapshotAddOnTax';
  ppnPercentage: Scalars['Float'];
  ppnTypes: Array<OrderPpnType>;
};

export type OrderProductSnapshotTax = {
  __typename?: 'OrderProductSnapshotTax';
  ppnBmPercentage: Scalars['Float'];
  ppnPercentage: Scalars['Float'];
  ppnTypes: Array<OrderPpnType>;
};

export type OrderProductTotalSummary = {
  __typename?: 'OrderProductTotalSummary';
  totalPpn: Scalars['Float'];
  totalPpnBm: Scalars['Float'];
  totalPrice: Scalars['Float'];
  totalQty: Scalars['Float'];
};

export enum OrderProductType {
  Digital = 'DIGITAL',
  Physical = 'PHYSICAL',
  Service = 'SERVICE'
}

export type OrderProposeAddendumResult = GenericError | ProposeAddendumResponse;

export type OrderRejectAddendumResult = GenericError | RejectAddendumResponse;

export type OrderRequestCancelAdminResult = AdminRequestCancelResponse | GenericError;

export type OrderRequestSignedUrlResult = GenericError | OrderRequestUploadSignedUrlResponse;

export type OrderRequestUploadSignedUrlInput = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  identifier: Scalars['String'];
  type: DocumentCategory;
};

export type OrderRequestUploadSignedUrlResponse = {
  __typename?: 'OrderRequestUploadSignedUrlResponse';
  expiry: Scalars['Int'];
  identifier: Scalars['String'];
  signedUrl: Scalars['String'];
  token: Scalars['String'];
};

export type OrderReviewer = {
  __typename?: 'OrderReviewer';
  /** @deprecated Moved to userBuyer */
  buyerId: Scalars['ID'];
  buyerPersona: PublicPersonaNonPenyedia;
  /** @deprecated Moved to buyerPersona */
  userBuyer: CartUserBuyer;
  /** @deprecated Moved to userBuyer */
  userId: Scalars['ID'];
};

export type OrderReviewerInput = {
  buyerId: Scalars['ID'];
  personaId: Scalars['ID'];
  userId: Scalars['ID'];
};

export enum OrderRole {
  Buyer = 'BUYER',
  Seller = 'SELLER'
}

export type OrderSellerInfo = {
  __typename?: 'OrderSellerInfo';
  company: OrderCompany;
  sellerId: Scalars['ID'];
  signer: OrderSellerSigner;
};

export type OrderSellerSigner = {
  __typename?: 'OrderSellerSigner';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['String'];
};

export type OrderShipment = {
  __typename?: 'OrderShipment';
  awbNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  createdBy: Scalars['String'];
  deliveryOrder: DeliveryOrder;
  id: Scalars['String'];
  items: Array<OrderShipmentItem>;
  lastFee: Scalars['Float'];
  lastInsuranceFee: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  originalFee: Scalars['Float'];
  originalInsuranceFee: Scalars['Float'];
  provider: Scalars['String'];
  requestArrivalDate: Scalars['Time'];
  requestDateType: RequestDateType;
  service: Scalars['String'];
  shipmentTax: OrderShipmentTax;
  status: OrderShipmentStatus;
  subTotal: Scalars['Float'];
  subTotalInsuranceFee: Scalars['Float'];
  updatedAt: Scalars['Time'];
  updatedBy: Scalars['String'];
};

export type OrderShipmentItem = {
  __typename?: 'OrderShipmentItem';
  orderDetailId: Scalars['String'];
  productVariantId: Scalars['ID'];
  qty: Scalars['Float'];
};

export enum OrderShipmentService {
  Cargo = 'CARGO',
  Custom = 'CUSTOM',
  Express = 'EXPRESS',
  Instant = 'INSTANT',
  NoShipment = 'NO_SHIPMENT',
  Regular = 'REGULAR',
  SameDay = 'SAME_DAY'
}

export enum OrderShipmentStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Delivered = 'DELIVERED',
  Draft = 'DRAFT',
  OnProcess = 'ON_PROCESS',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  Pending = 'PENDING'
}

export type OrderShipmentTax = {
  __typename?: 'OrderShipmentTax';
  ppn: Scalars['Float'];
  ppnInsuranceFee: Scalars['Float'];
  ppnPercentage?: Maybe<Scalars['Float']>;
  ppnPercentageInsurance?: Maybe<Scalars['Float']>;
};

export type OrderShipmentTotalSummary = {
  __typename?: 'OrderShipmentTotalSummary';
  totalInsuranceFee: Scalars['Float'];
  totalPpn: Scalars['Float'];
  totalPpnInsuranceFee: Scalars['Float'];
  totalPrice: Scalars['Float'];
};

export type OrderShipmentUpdateInput = {
  requestArrivalDate?: InputMaybe<Scalars['Time']>;
  shipmentId: Scalars['String'];
};

export type OrderSignDocumentResult = GenericError | SignResponse;

export type OrderSort = {
  direction: SortDirection;
  field: SortField;
};

export enum OrderStatus {
  AddendumDraft = 'ADDENDUM_DRAFT',
  AddendumEsignInProgress = 'ADDENDUM_ESIGN_IN_PROGRESS',
  AddendumNew = 'ADDENDUM_NEW',
  BastEsignInProgress = 'BAST_ESIGN_IN_PROGRESS',
  BastGenerated = 'BAST_GENERATED',
  Cancelled = 'CANCELLED',
  CancelledOnNegotiation = 'CANCELLED_ON_NEGOTIATION',
  CancelledOnReviewByBuyer = 'CANCELLED_ON_REVIEW_BY_BUYER',
  CancelledOnReviewByReviewer = 'CANCELLED_ON_REVIEW_BY_REVIEWER',
  CancelledViaResolution = 'CANCELLED_VIA_RESOLUTION',
  Completed = 'COMPLETED',
  Delivered = 'DELIVERED',
  DeliveryFailed = 'DELIVERY_FAILED',
  EsignInProgress = 'ESIGN_IN_PROGRESS',
  Expired = 'EXPIRED',
  ExpiredOnReview = 'EXPIRED_ON_REVIEW',
  OnDelivery = 'ON_DELIVERY',
  OnNegotiation = 'ON_NEGOTIATION',
  OnProcess = 'ON_PROCESS',
  Paid = 'PAID',
  PaymentExpired = 'PAYMENT_EXPIRED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentOnProcess = 'PAYMENT_ON_PROCESS',
  PaymentOnProcessCc = 'PAYMENT_ON_PROCESS_CC',
  PaymentOutsideSystem = 'PAYMENT_OUTSIDE_SYSTEM',
  PaymentRejected = 'PAYMENT_REJECTED',
  Rejected = 'REJECTED',
  RequestCancel = 'REQUEST_CANCEL',
  RequestCancelViaResolution = 'REQUEST_CANCEL_VIA_RESOLUTION',
  RequestPickUp = 'REQUEST_PICK_UP',
  WaitingDocument = 'WAITING_DOCUMENT',
  WaitingPayment = 'WAITING_PAYMENT',
  WaitingPaymentCc = 'WAITING_PAYMENT_CC',
  WaitingPaymentMethod = 'WAITING_PAYMENT_METHOD',
  WaitingPaymentVa = 'WAITING_PAYMENT_VA',
  WaitingPpkReview = 'WAITING_PPK_REVIEW',
  WaitingSellerConfirmation = 'WAITING_SELLER_CONFIRMATION'
}

export type OrderStatusHistory = {
  __typename?: 'OrderStatusHistory';
  reason?: Maybe<Scalars['String']>;
  reasonDescription?: Maybe<Scalars['String']>;
  status: OrderStatusHistoryResult;
  timestamp: Scalars['Time'];
};

export type OrderStatusHistoryResult = AddendumStatusType | NegotiationStatusType | OrderStatusType;

export type OrderStatusHistorySummaryResponse = {
  __typename?: 'OrderStatusHistorySummaryResponse';
  orderStatus: Array<OrderStatusHistory>;
};

export type OrderStatusType = {
  __typename?: 'OrderStatusType';
  value: OrderStatus;
};

export type OrderSummary = {
  __typename?: 'OrderSummary';
  assignedPPK: Scalars['String'];
  buyer: OrderBuyerInfo;
  company: PublicCompany;
  currency: Currency;
  documents: Array<OrderSummaryDocument>;
  expiryTime?: Maybe<Scalars['Time']>;
  firstItem: OrderItem;
  id: Scalars['ID'];
  isCOAFilled: Scalars['Boolean'];
  isOrderTest: Scalars['Boolean'];
  longestPreorderSLA?: Maybe<Scalars['Int']>;
  orderKey: Scalars['String'];
  orderNumber: Scalars['String'];
  originalTotal: Scalars['Float'];
  paymentMethods: Array<OrderPaymentMethod>;
  paymentStatus: OrderPaymentStatus;
  payments: Array<OrderSummaryPayment>;
  rowItemCount: Scalars['Int'];
  sellerInfo: OrderSellerInfo;
  shipmentStatus: OrderShipmentStatus;
  shipments: Array<OrderSummaryShipment>;
  status: OrderStatus;
  timestamp: Scalars['Time'];
  total: Scalars['Float'];
  updatedAt: Scalars['Time'];
  viaNegotiation: Scalars['Boolean'];
};

export type OrderSummaryDocument = {
  __typename?: 'OrderSummaryDocument';
  category: DocumentCategory;
  id: Scalars['ID'];
};

export type OrderSummaryPayment = {
  __typename?: 'OrderSummaryPayment';
  id: Scalars['ID'];
  method: OrderPaymentMethod;
  paymentId: Scalars['ID'];
};

export type OrderSummaryShipment = {
  __typename?: 'OrderSummaryShipment';
  awbNumber?: Maybe<Scalars['String']>;
  deliveryOrderId: Scalars['ID'];
  id: Scalars['String'];
  provider: Scalars['String'];
  requestArrivalDate: Scalars['Time'];
  requestDateType: RequestDateType;
  service: Scalars['String'];
  status: OrderShipmentStatus;
};

export type OrderTax = {
  __typename?: 'OrderTax';
  ppn: Scalars['Float'];
  ppnBm: Scalars['Float'];
  ppnBmPercentage?: Maybe<Scalars['Float']>;
  ppnPercentage?: Maybe<Scalars['Float']>;
};

export type OrderTotalSummaryInput = {
  addOns?: InputMaybe<Array<InputMaybe<AddOnTotalSummaryInput>>>;
  isFtz: Scalars['Boolean'];
  products: Array<ProductTotalSummaryInput>;
  shipments?: InputMaybe<Array<InputMaybe<ShipmentTotalSummaryInput>>>;
};

export type OrderTotalSummaryResponse = {
  __typename?: 'OrderTotalSummaryResponse';
  grandTotalAfterTax: Scalars['Float'];
  grandTotalBeforeTax: Scalars['Float'];
  totalAddOn: OrderAddOnTotalSummary;
  totalAllPpn: Scalars['Float'];
  totalAllPpnBm: Scalars['Float'];
  totalFinalTax: Scalars['Float'];
  totalFreeTax: Scalars['Float'];
  totalProduct: OrderProductTotalSummary;
  totalShipment: OrderShipmentTotalSummary;
};

export type OrderTotalSummaryResult = GenericError | OrderTotalSummaryResponse;

export type OrderUpdateInput = {
  id: Scalars['ID'];
  orderKey: Scalars['ID'];
  payments?: InputMaybe<Array<OrderPaymentUpdateInput>>;
  shipments?: InputMaybe<Array<OrderShipmentUpdateInput>>;
};

export type OrderUpdateResult = GenericError | Order;

export type OrderUpdateReviewerInput = {
  assignedReviewer: OrderBuyerUserDataInput;
  orderId: Scalars['String'];
  orderKey: Scalars['String'];
};

export type OrderUpdateReviewerResult = GenericError | UpdateOrderReviewerResponse;

export type Origin = {
  __typename?: 'Origin';
  addressCheckout: AddressCheckout;
  sellerId: Scalars['ID'];
};

export type OtherParty = {
  __typename?: 'OtherParty';
  address: Scalars['String'];
  name: Scalars['String'];
  npwp: Scalars['String'];
};

export type Pdn = {
  __typename?: 'PDN';
  type: Scalars['String'];
};

export enum PdnDetailType {
  All = 'ALL',
  None = 'NONE',
  Partial = 'PARTIAL'
}

export enum PdnType {
  Import = 'IMPORT',
  Local = 'LOCAL'
}

export type PnbpRes = {
  __typename?: 'PNBPRes';
  clientID: Scalars['String'];
  detailTransaksi: Array<DetailTransaksi>;
  maximalTarifPNBP: Scalars['Float'];
  noDokumen: Scalars['String'];
  nominalTarifPNBP: Scalars['Float'];
  pembeli: Pembeli;
  penyedia: Penyedia;
  persentaseTarifPNBP: Scalars['Float'];
  tanggalPembuatan: Scalars['String'];
  terbilang: Scalars['String'];
  totalPotongan: Scalars['Float'];
};

export type PnbpTransactionResponse = GenericError | PnbpRes;

export type PPhInvoice = {
  __typename?: 'PPhInvoice';
  items: Array<PPhItems>;
  pphPercentage: Scalars['Float'];
  pphType: Scalars['Int'];
  totalDPP: Scalars['Float'];
  totalPPh: Scalars['Float'];
};

export type PPhItems = {
  __typename?: 'PPhItems';
  DPP: Scalars['Float'];
  PPh: Scalars['Float'];
  name: Scalars['String'];
};

export type PageInput = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type Pagination = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  currentPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type PaginationInput = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type PajakInfo = {
  __typename?: 'PajakInfo';
  auditUpdate?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  jnpId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxCreatedAt?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['Int']>;
  taxMonth?: Maybe<Scalars['Int']>;
  taxNumber?: Maybe<Scalars['String']>;
  taxPeriode?: Maybe<Scalars['String']>;
  taxYear?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type PayByCreditCardInput = {
  detailPayment: PaymentDetailInput;
  tokenId: Scalars['String'];
};

export type PayByCreditCardResult = CreditCard3dsRequired | GenericError;

export type PayByCreditCardWithTokenInput = {
  paymentToken: Scalars['ID'];
  tokenId: Scalars['String'];
};

export type PayByVaInput = {
  assignedTo: Scalars['String'];
  detailPayment: PaymentDetailInput;
};

export type PaymentDetail = {
  __typename?: 'PaymentDetail';
  UpdatedAt: Scalars['Time'];
  assignedTo?: Maybe<Scalars['String']>;
  bankID?: Maybe<Scalars['ID']>;
  bankName?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  orderID: Scalars['String'];
  paymentMethodCode: PaymentMethodCode;
  status: PaymentStatus;
  totalAmount: Scalars['Float'];
};

export type PaymentDetailByTokenResult = GenericError | PaymentDetailResp;

export type PaymentDetailInput = {
  paymentId?: InputMaybe<Scalars['ID']>;
};

export type PaymentDetailResp = {
  __typename?: 'PaymentDetailResp';
  disbursementDeductions: Array<DisbursementDeduction>;
  documents: Array<PaymentDocument>;
  metadata?: Maybe<Metadata>;
  orderId: Scalars['ID'];
  orderKey: Scalars['String'];
  paymentId: Scalars['ID'];
  paymentMethodCode: PaymentMethodCode;
  paymentPic?: Maybe<Scalars['String']>;
  paymentSummaryDetails: Array<PaymentSummaryDetail>;
  reason?: Maybe<Scalars['String']>;
  status: PaymentStatus;
  totalAmount: Scalars['Float'];
  totalDisbursement: Scalars['Float'];
};

export type PaymentDetailResponse = GenericError | PaymentDetailResp;

export type PaymentDocument = {
  __typename?: 'PaymentDocument';
  documentFileName: Scalars['String'];
  documentNumber: Scalars['String'];
  documentType: Scalars['String'];
  documentURL: Scalars['String'];
  id: Scalars['ID'];
  paymentId: Scalars['ID'];
  updatedAt: Scalars['Time'];
};

export enum PaymentDocumentStatus {
  UploadOnMalwareScanning = 'UPLOAD_ON_MALWARE_SCANNING',
  UploadOnMetadataValidation = 'UPLOAD_ON_METADATA_VALIDATION',
  UploadSuccess = 'UPLOAD_SUCCESS',
  UploadValidationFailed = 'UPLOAD_VALIDATION_FAILED',
  UploadVirusDetected = 'UPLOAD_VIRUS_DETECTED'
}

export type PaymentFilter = {
  assignedTo?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['ID']>;
  paymentMethodCode?: InputMaybe<Array<InputMaybe<PaymentMethodCode>>>;
  status?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  categoryCode: CategoryCode;
  description: Scalars['String'];
  id: PaymentMethodCode;
  isActive: Scalars['Boolean'];
  maxAmount: Scalars['Float'];
  minAmount: Scalars['Float'];
  name: Scalars['String'];
  reason: Scalars['String'];
};

export type PaymentMethodAvailabilityRes = {
  __typename?: 'PaymentMethodAvailabilityRes';
  paymentMethods: Array<PaymentMethod>;
};

export type PaymentMethodAvailabilityResponse = GenericError | PaymentMethodAvailabilityRes;

export enum PaymentMethodCode {
  KartuKreditPemerintah = 'KARTU_KREDIT_PEMERINTAH',
  Qris = 'QRIS',
  SekaliBayar = 'SEKALI_BAYAR',
  VirtualAccount = 'VIRTUAL_ACCOUNT'
}

export type PaymentMethodFilter = {
  category?: InputMaybe<Array<Scalars['String']>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentMethodResponse = {
  __typename?: 'PaymentMethodResponse';
  paymentMethods: Array<PaymentMethod>;
};

export type PaymentResult = GenericError | PaymentSuccess;

export type PaymentSort = {
  field: PaymentSortField;
  order: SortBy;
};

export enum PaymentSortField {
  AssignedTo = 'ASSIGNED_TO',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  OrderId = 'ORDER_ID',
  PaymentMethodCode = 'PAYMENT_METHOD_CODE',
  Status = 'STATUS'
}

export enum PaymentStatus {
  BuyerPaid = 'BUYER_PAID',
  Created = 'CREATED',
  DocsUploaded = 'DOCS_UPLOADED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Waiting = 'WAITING',
  WaitingDocs = 'WAITING_DOCS'
}

export type PaymentSuccess = {
  __typename?: 'PaymentSuccess';
  orderId?: Maybe<Scalars['String']>;
  paymentId: Scalars['String'];
  paymentMethodCode?: Maybe<PaymentMethodCode>;
};

export type PaymentSummaryDetail = {
  __typename?: 'PaymentSummaryDetail';
  amount: Scalars['Float'];
  name: Scalars['String'];
};

export enum PaymentTypeCode {
  Ls = 'LS',
  Up = 'UP'
}

export enum PaymentUserRole {
  AdminCompany = 'ADMIN_COMPANY',
  Bendahara = 'BENDAHARA',
  InternalTelkom = 'INTERNAL_TELKOM',
  MemberCompany = 'MEMBER_COMPANY',
  Pp = 'PP',
  Ppk = 'PPK'
}

export type Pembeli = {
  __typename?: 'Pembeli';
  jenisInstansi: Scalars['String'];
  namaInstansi: Scalars['String'];
  namaSatker: Scalars['String'];
  namaUnit: Scalars['String'];
};

export type PemilikInfo = {
  __typename?: 'PemilikInfo';
  address?: Maybe<Scalars['String']>;
  auditUpdate?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  jenisPemilik?: Maybe<Scalars['String']>;
  jenisPemilikId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  npwp?: Maybe<Scalars['String']>;
  numberOfStock?: Maybe<Scalars['Int']>;
  rekanId?: Maybe<Scalars['Int']>;
  satuan?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  sikapCreatedAt?: Maybe<Scalars['String']>;
  sikapPemilikId?: Maybe<Scalars['Int']>;
  sourceData?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userToken?: Maybe<Scalars['String']>;
};

export type PendingPersonaNonPenyediaData = {
  __typename?: 'PendingPersonaNonPenyediaData';
  interviewSchedule?: Maybe<InterviewSchedule>;
  persona?: Maybe<PersonaNonPenyedia>;
};

export type PendingPersonaNonPenyediaVerificationResponse = GenericError | PendingPersonaNonPenyediaVerificationResult;

export type PendingPersonaNonPenyediaVerificationResult = {
  __typename?: 'PendingPersonaNonPenyediaVerificationResult';
  data?: Maybe<Array<Maybe<PendingPersonaNonPenyediaData>>>;
  paginationInfo: PaginationInfo;
};

export type PengalamanInfo = {
  __typename?: 'PengalamanInfo';
  address?: Maybe<Scalars['String']>;
  auditUpdate?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  contractEndAt?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  contractStartAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  kegiatan?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  pemberiTugas?: Maybe<Scalars['String']>;
  progressDate?: Maybe<Scalars['String']>;
  progressPercentage?: Maybe<Scalars['Float']>;
  rekanId?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  sikapPglId?: Maybe<Scalars['Int']>;
  sourceData?: Maybe<Scalars['String']>;
  stakeholderPhone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PengurusInfo = {
  __typename?: 'PengurusInfo';
  address?: Maybe<Scalars['String']>;
  auditUpdate?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  npwp?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  sikapCreatedAt?: Maybe<Scalars['String']>;
  sikapPengurusID?: Maybe<Scalars['Int']>;
  sourceData?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userToken?: Maybe<Scalars['String']>;
};

export type Penyedia = {
  __typename?: 'Penyedia';
  bentukUsaha: Scalars['String'];
  namaPerusahaan: Scalars['String'];
  tipePenyedia: Scalars['String'];
};

export type PenyediaAdminTransferInput = {
  institusiId: Scalars['String'];
  userIdSource: Scalars['String'];
  userIdTarget: Scalars['String'];
};

export type PenyediaAdminTransferRes = {
  __typename?: 'PenyediaAdminTransferRes';
  id: Scalars['String'];
};

export type PenyediaAdminTransferResponse = GenericError | PenyediaAdminTransferRes;

export enum PenyediaBentukUsahaEnum {
  BadanHukum = 'BADAN_HUKUM',
  BadanLayananUmum = 'BADAN_LAYANAN_UMUM',
  BadanOperasiBersama = 'BADAN_OPERASI_BERSAMA',
  BadanUsahaLainnya = 'BADAN_USAHA_LAINNYA',
  BadanUsahaMilikDesa = 'BADAN_USAHA_MILIK_DESA',
  BadanUsahaMilikDesaBersama = 'BADAN_USAHA_MILIK_DESA_BERSAMA',
  BadanUsahaPemerintah = 'BADAN_USAHA_PEMERINTAH',
  BadanUsahaPerwakilan = 'BADAN_USAHA_PERWAKILAN',
  BentukUsahaTetap = 'BENTUK_USAHA_TETAP',
  CommanditerVenoschaap = 'COMMANDITER_VENOSCHAAP',
  Firma = 'FIRMA',
  Koperasi = 'KOPERASI',
  PedagangBerjangkaAsing = 'PEDAGANG_BERJANGKA_ASING',
  Perorangan = 'PERORANGAN',
  PersekutuanPerdata = 'PERSEKUTUAN_PERDATA',
  PerseroanTerbatasPerorangan = 'PERSEROAN_TERBATAS_PERORANGAN',
  PerusahaanDaerah = 'PERUSAHAAN_DAERAH',
  PerusahaanTerbatas = 'PERUSAHAAN_TERBATAS',
  PerusahaanUmum = 'PERUSAHAAN_UMUM',
  PerusahaanUmumDaerah = 'PERUSAHAAN_UMUM_DAERAH',
  Yayasan = 'YAYASAN'
}

export enum PenyediaJabatanEnum {
  Admin = 'ADMIN',
  Bendahara = 'BENDAHARA',
  Direktur = 'DIREKTUR',
  Lainnya = 'LAINNYA',
  Sekretaris = 'SEKRETARIS'
}

export enum PenyediaJenisPerusahaanEnum {
  Cabang = 'CABANG',
  Pusat = 'PUSAT'
}

export enum PenyediaRoleEnum {
  AdminCompany = 'ADMIN_COMPANY',
  MemberCompany = 'MEMBER_COMPANY'
}

export enum PenyediaSkalaUsahaEnum {
  NonUmk = 'NON_UMK',
  Umk = 'UMK'
}

export type PeralatanInfo = {
  __typename?: 'PeralatanInfo';
  auditUpdate?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jenis?: Maybe<Scalars['String']>;
  jumlah?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  ownership?: Maybe<Scalars['String']>;
  productionYear?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  sikapAltId?: Maybe<Scalars['Int']>;
  sikapCreatedAt?: Maybe<Scalars['String']>;
  sikapDeleted?: Maybe<Scalars['Boolean']>;
  sikapId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Permissions = {
  __typename?: 'Permissions';
  auth0ResourceServerIdentifier: Scalars['String'];
  auth0ResourceServerName: Scalars['String'];
  createdAt: Scalars['String'];
  featureCategory?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  permissionDescription?: Maybe<Scalars['String']>;
  permissionName: Scalars['String'];
  subfeatureCategory?: Maybe<Scalars['String']>;
};

export type PermissionsFilter = {
  featureCategory?: InputMaybe<FeatureCategoryType>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  permissionName?: InputMaybe<Scalars['String']>;
  subfeatureCategory?: InputMaybe<SubfeatureCategoryType>;
};

export type PermissionsList = {
  __typename?: 'PermissionsList';
  permissions: Array<Permissions>;
};

export type PermissionsResponse = Error | PermissionsList;

export type Persona = {
  __typename?: 'Persona';
  appId?: Maybe<Scalars['String']>;
  appRole?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type PersonaFileUrlType = {
  __typename?: 'PersonaFileURLType';
  bntURL?: Maybe<Scalars['String']>;
  suratKeteranganKerjaURL?: Maybe<Scalars['String']>;
  suratKuasaURL?: Maybe<Scalars['String']>;
};

export type PersonaFilterInput = {
  Statuses?: InputMaybe<Array<PersonaStatus>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  institusiIds?: InputMaybe<Array<Scalars['String']>>;
  isDelegation?: InputMaybe<Scalars['Boolean']>;
  isPenyedia?: InputMaybe<Scalars['Boolean']>;
  roleIds?: InputMaybe<Array<Scalars['String']>>;
  roleNames?: InputMaybe<Array<PersonaRoleEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum PersonaGolonganEnum {
  Iia = 'IIA',
  Iib = 'IIB',
  Iic = 'IIC',
  Iid = 'IID',
  Iiia = 'IIIA',
  Iiib = 'IIIB',
  Iiic = 'IIIC',
  Iiid = 'IIID',
  Iva = 'IVA',
  Ivb = 'IVB',
  Ivc = 'IVC',
  Ivd = 'IVD',
  Ive = 'IVE'
}

export type PersonaListData = {
  __typename?: 'PersonaListData';
  appRole: Scalars['String'];
  companyName: Scalars['String'];
  institutionName: Scalars['String'];
  isPenyedia: Scalars['Boolean'];
  klpdName: Scalars['String'];
  personaId: Scalars['String'];
  satkerName: Scalars['String'];
};

export type PersonaNonPenyedia = {
  __typename?: 'PersonaNonPenyedia';
  application?: Maybe<Application>;
  klpd?: Maybe<Klpd>;
  persona?: Maybe<PersonaType>;
  personaFile?: Maybe<PersonaFileUrlType>;
  personaShippingAddress?: Maybe<Array<AddressesType>>;
  satker?: Maybe<Satker>;
};

export type PersonaNonPenyediaInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  kodeKlpd?: InputMaybe<Array<Scalars['String']>>;
  kodeSatuanKerjaAdj?: InputMaybe<Array<Scalars['String']>>;
  roleNames?: InputMaybe<Array<NonPenyediaRoleEnum>>;
  search?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type PersonaNonPenyediaPage = {
  __typename?: 'PersonaNonPenyediaPage';
  pageInfo: PaginationInfo;
  personas: Array<PersonaNonPenyedia>;
  users: Array<UserInfo>;
};

export type PersonaPenyedia = {
  __typename?: 'PersonaPenyedia';
  penyedia?: Maybe<CompanyType>;
  penyediaFile?: Maybe<CompanyFileUrlType>;
  persona?: Maybe<PersonaType>;
  personaFile?: Maybe<PersonaFileUrlType>;
  personaShippingAddress?: Maybe<Array<AddressesType>>;
};

export type PersonaRegisterNonPenyediaInput = {
  appId: Scalars['String'];
  appUserName?: InputMaybe<Scalars['String']>;
  blu?: InputMaybe<Scalars['Boolean']>;
  bntToken?: InputMaybe<Scalars['String']>;
  golongan?: InputMaybe<PersonaGolonganEnum>;
  institutionType: JenisKlpdOption;
  kodeKLPD: Scalars['String'];
  lpse?: InputMaybe<Scalars['Int']>;
  masaBerlakuJabatan?: InputMaybe<Scalars['String']>;
  nip?: InputMaybe<Scalars['String']>;
  noPPSDM?: InputMaybe<Scalars['String']>;
  npwpSatker?: InputMaybe<Scalars['String']>;
  pengaktifanRUP?: InputMaybe<Scalars['Boolean']>;
  roles: NonPenyediaRoleEnum;
  satkerAddress?: InputMaybe<Scalars['String']>;
  satuanKerja: Scalars['String'];
  suratKeteranganKerjaToken?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type PersonaRegisterNonRegisteredPenyediaInput = {
  aktaNotarisNo: Scalars['String'];
  beneficialOwnership: Scalars['String'];
  bentukUsaha: PenyediaBentukUsahaEnum;
  bidangIndustri: Scalars['String'];
  companyAddresses: Array<InputMaybe<CompanyAddressesTypeInput>>;
  companyName: Scalars['String'];
  isPICTandaTangan: Scalars['Boolean'];
  isPKP: Scalars['Boolean'];
  jabatan: PenyediaJabatanEnum;
  jenisPerusahaan: PenyediaJenisPerusahaanEnum;
  kbli: Array<InputMaybe<CompanyKbliInput>>;
  kswp: Scalars['String'];
  nib: Scalars['String'];
  nibToken: Scalars['String'];
  npwp: Scalars['String'];
  npwpToken: Scalars['String'];
  pkpNumber?: InputMaybe<Scalars['String']>;
  shippingAddresses: Array<InputMaybe<AddressesTypeInput>>;
  skalaUsaha: PenyediaSkalaUsahaEnum;
  suratKeteranganKerjaToken: Scalars['String'];
  suratKuasaToken: Scalars['String'];
  suratPKPToken?: InputMaybe<Scalars['String']>;
  umkType?: InputMaybe<UmkEnum>;
  username: Scalars['String'];
};

export type PersonaRegisterRegisteredPenyediaInput = {
  nib: Scalars['String'];
  suratKeteranganKerjaToken: Scalars['String'];
};

export type PersonaRes = {
  __typename?: 'PersonaRes';
  id?: Maybe<Scalars['String']>;
};

export type PersonaResponse = GenericError | PersonaRes;

export enum PersonaRoleEnum {
  AdminAgencySpse = 'ADMIN_AGENCY_SPSE',
  AdminCompany = 'ADMIN_COMPANY',
  AdminDaftarHitam = 'ADMIN_DAFTAR_HITAM',
  AdminLokalSektoral = 'ADMIN_LOKAL_SEKTORAL',
  AdminMonev = 'ADMIN_MONEV',
  AdminNasionalV5 = 'ADMIN_NASIONAL_V5',
  AdminPengelola = 'ADMIN_PENGELOLA',
  AdminPpeSirup = 'ADMIN_PPE_SIRUP',
  AdminPpeSpse = 'ADMIN_PPE_SPSE',
  AdminRup = 'ADMIN_RUP',
  AgenHelpdeskV5 = 'AGEN_HELPDESK_V5',
  AuditorEkatalogV5 = 'AUDITOR_EKATALOG_V5',
  AuditorSpse = 'AUDITOR_SPSE',
  Bendahara = 'BENDAHARA',
  CategoryManager = 'CATEGORY_MANAGER',
  Cms = 'CMS',
  CmsMonev = 'CMS_MONEV',
  Direktur = 'DIREKTUR',
  FinOps = 'FIN_OPS',
  HelpdeskL0 = 'HELPDESK_L0',
  HelpdeskL1 = 'HELPDESK_L1',
  InternalTelkom = 'INTERNAL_TELKOM',
  KepalaUkpbj = 'KEPALA_UKPBJ',
  Kpa = 'KPA',
  KuppbjSpse = 'KUPPBJ_SPSE',
  Lkpp = 'LKPP',
  MemberCompany = 'MEMBER_COMPANY',
  NonGovtBuyer = 'NON_GOVT_BUYER',
  Pa = 'PA',
  Pelapor = 'PELAPOR',
  PengelolaLokal = 'PENGELOLA_LOKAL',
  Pmep = 'PMEP',
  Pokja = 'POKJA',
  Pp = 'PP',
  Ppk = 'PPK',
  SekretariatKlpd = 'SEKRETARIAT_KLPD',
  StafPmep = 'STAF_PMEP',
  SuperAdminSirup = 'SUPER_ADMIN_SIRUP',
  SuperAdminV5 = 'SUPER_ADMIN_V5',
  Trainer = 'TRAINER',
  VerifikatorSpse = 'VERIFIKATOR_SPSE'
}

export enum PersonaStatus {
  PersonaConnected = 'PERSONA_CONNECTED',
  PersonaExpired = 'PERSONA_EXPIRED',
  PersonaHardRejected = 'PERSONA_HARD_REJECTED',
  PersonaNonAktif = 'PERSONA_NON_AKTIF',
  PersonaRejected = 'PERSONA_REJECTED',
  PersonaSoftRejected = 'PERSONA_SOFT_REJECTED',
  PersonaVerified = 'PERSONA_VERIFIED',
  PersonaWaitingVerification = 'PERSONA_WAITING_VERIFICATION'
}

export type PersonaType = {
  __typename?: 'PersonaType';
  address?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  appRole?: Maybe<PersonaRoleEnum>;
  appUserData?: Maybe<UserDataType>;
  appUserName?: Maybe<Scalars['String']>;
  blu?: Maybe<Scalars['Boolean']>;
  bntToken?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  institusiId?: Maybe<Scalars['String']>;
  institusiMemberId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  isPenyedia?: Maybe<Scalars['Boolean']>;
  noPPSDM?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  pengaktifanRUP?: Maybe<Scalars['Boolean']>;
  penyediaJabatan?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  status?: Maybe<PersonaStatus>;
  suratKeteranganKerjaToken?: Maybe<Scalars['String']>;
  suratKuasaToken?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleId?: Maybe<Scalars['String']>;
  verifikasiPersona?: Maybe<Scalars['String']>;
};

export type PersonaValidateNpwpRes = {
  __typename?: 'PersonaValidateNPWPRes';
  companyPersonaClaim?: Maybe<CompanyPersonaClaimRes>;
  isRegistered: Scalars['Boolean'];
};

export type PersonaValidateNpwpResponse = GenericError | PersonaValidateNpwpRes;

export type PhoneNumberInput = {
  isWhatsApp?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

export type PhoneNumberMember = {
  __typename?: 'PhoneNumberMember';
  isWhatsApp?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type PickUp = {
  __typename?: 'PickUp';
  DeliveryOrderId: Scalars['String'];
  OrderId: Scalars['String'];
};

export type PickUpResponse = {
  __typename?: 'PickUpResponse';
  data?: Maybe<Array<PickUp>>;
};

export type PickUpResult = GenericError | PickUpResponse;

export type PpnBm = {
  __typename?: 'PpnBm';
  items: Array<PpnBmItem>;
};

export type PpnBmInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  level?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
};

export type PpnBmItem = {
  __typename?: 'PpnBmItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  level: Scalars['Int'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type PpnBmResult = GenericError | PpnBm;

export enum PpnBmType {
  Aircraft = 'AIRCRAFT',
  AirBalloon = 'AIR_BALLOON',
  Bullet = 'BULLET',
  Firearm = 'FIREARM',
  LuxuryResidence = 'LUXURY_RESIDENCE',
  Vehicle = 'VEHICLE',
  Yacht = 'YACHT'
}

export enum PpnType {
  Ppn = 'PPN',
  Ppnbm = 'PPNBM'
}

export type PreOrder = {
  __typename?: 'PreOrder';
  sla: Scalars['Int'];
};

export type PriceTransactionLog = {
  __typename?: 'PriceTransactionLog';
  basePrice?: Maybe<Scalars['Int']>;
  baseTax?: Maybe<Scalars['Int']>;
  serviceFee?: Maybe<Scalars['Int']>;
  serviceFeeTax?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PricingAddressInput = {
  lat?: InputMaybe<Scalars['String']>;
  long?: InputMaybe<Scalars['String']>;
  villageAreaCode: Scalars['String'];
};

export type PricingData = {
  __typename?: 'PricingData';
  name: Scalars['String'];
  priceMax: Scalars['Float'];
  priceMin: Scalars['Float'];
  rateType: Logistic_Service;
  rates: Array<PricingRate>;
  slaMax: Scalars['Int'];
  slaMin: Scalars['Int'];
};

export type PricingInput = {
  destination: PricingAddressInput;
  items: Array<PricingItems>;
  origin: PricingAddressInput;
  sellerId: Scalars['ID'];
};

export type PricingItems = {
  dimensionUnit?: InputMaybe<ProductDimensionUnit>;
  height?: InputMaybe<Scalars['Float']>;
  length?: InputMaybe<Scalars['Float']>;
  ppnPercentage: Scalars['Float'];
  price: Scalars['Float'];
  productId: Scalars['String'];
  productVariantId: Scalars['String'];
  qty: Scalars['Int'];
  weight?: InputMaybe<Scalars['Float']>;
  weightUnit?: InputMaybe<ProductWeightUnit>;
  width?: InputMaybe<Scalars['Float']>;
};

export type PricingRate = {
  __typename?: 'PricingRate';
  basePrice: Scalars['Float'];
  detailPrice: DetailPrice;
  finalPrice: Scalars['Float'];
  insuranceApplied: Scalars['Boolean'];
  insuranceFee: Scalars['Float'];
  mustUseInsurance: Scalars['Boolean'];
  name: Scalars['String'];
  rateId: Scalars['ID'];
  slaMax: Scalars['Int'];
  slaMin: Scalars['Int'];
};

export type PricingResponse = {
  __typename?: 'PricingResponse';
  data?: Maybe<Array<PricingData>>;
};

export type PricingResult = GenericError | PricingResponse;

export type Product = {
  __typename?: 'Product';
  actionReasons?: Maybe<Array<ProductActionReason>>;
  actionReasonsNotes?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  category: ProductCategorySpec;
  categoryType: CategoryType;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['String']>;
  hasVat: Scalars['Boolean'];
  id: Scalars['ID'];
  images: Array<ProductImage>;
  isActive: Scalars['Boolean'];
  isSellerUMKK: Scalars['Boolean'];
  kbki: Scalars['String'];
  labels?: Maybe<Array<Scalars['String']>>;
  masterProductId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  nie?: Maybe<Nie>;
  pdn: ProductPdn;
  preOrder?: Maybe<ProductPreOrder>;
  prices: ProductPrice;
  productAddOns?: Maybe<Array<ProductAddOn>>;
  productInformations: ProductInformation;
  productOptions?: Maybe<Array<ProductOption>>;
  sellerId: Scalars['ID'];
  sellerLocation?: Maybe<RegionTraceParent>;
  sellerName: Scalars['String'];
  sellerVillageAreaCode: Scalars['String'];
  shipping: ProductShipping;
  shippingOption: ShippingOptionType;
  slug: Scalars['String'];
  sni?: Maybe<Sni>;
  status: ProductStatusType;
  stockAccumulation: Scalars['Int'];
  stockUnit?: Maybe<ProductStockUnit>;
  tax: ProductTax;
  tkdn?: Maybe<Tkdn>;
  type: ProductType;
  unitSold: Scalars['Int'];
  updatedAt: Scalars['Time'];
  username: Scalars['String'];
  variants: Array<ProductVariant>;
  version: Scalars['Int'];
  videoSource?: Maybe<VideoSourceType>;
  videoUrl?: Maybe<Scalars['String']>;
};


export type ProductPricesArgs = {
  regionCode?: InputMaybe<Scalars['String']>;
};

export type ProductActionReason = {
  __typename?: 'ProductActionReason';
  description?: Maybe<Scalars['String']>;
  enum?: Maybe<ProductActionReasonEnum>;
  reason: Scalars['String'];
};

export enum ProductActionReasonEnum {
  CategoryMatch = 'CATEGORY_MATCH',
  CategoryNotMatch = 'CATEGORY_NOT_MATCH',
  Forbidden = 'FORBIDDEN',
  InvalidProduct = 'INVALID_PRODUCT',
  InvalidProductInformation = 'INVALID_PRODUCT_INFORMATION',
  InvalidTkdn = 'INVALID_TKDN',
  MissingCertificates = 'MISSING_CERTIFICATES',
  NoTrademarkInfringement = 'NO_TRADEMARK_INFRINGEMENT',
  Others = 'OTHERS',
  ReasonablePrice = 'REASONABLE_PRICE',
  SpecificationNotMatch = 'SPECIFICATION_NOT_MATCH',
  TrademarkInfringement = 'TRADEMARK_INFRINGEMENT',
  UnreasonablePrice = 'UNREASONABLE_PRICE',
  ValidProduct = 'VALID_PRODUCT',
  ValidProductInformation = 'VALID_PRODUCT_INFORMATION',
  ValidTkdn = 'VALID_TKDN'
}

export type ProductActionReasons = {
  __typename?: 'ProductActionReasons';
  notes: Scalars['String'];
  reasons?: Maybe<Array<ProductActionReason>>;
};

export type ProductAddOn = {
  __typename?: 'ProductAddOn';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  objectType: AddOnObjectType;
  productAddOnVariants: Array<ProductAddOnVariant>;
  tax: ProductAddOnTax;
  type: AddOnType;
  updatedAt: Scalars['Time'];
};

export type ProductAddOnTax = {
  __typename?: 'ProductAddOnTax';
  ppnPercentage: Scalars['Float'];
  ppnTypes: Array<PpnType>;
};

export type ProductAddOnVariant = {
  __typename?: 'ProductAddOnVariant';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  priceWithTax: Scalars['Float'];
  updatedAt: Scalars['Time'];
};

export type ProductBrand = {
  __typename?: 'ProductBrand';
  brand?: Maybe<Brand>;
};

export type ProductBrandInput = {
  applicationNumber: Scalars['String'];
};

export type ProductBrandResult = GenericError | ProductBrand;

export type ProductBulkAction = {
  __typename?: 'ProductBulkAction';
  completedAt?: Maybe<Scalars['Time']>;
  createdAt?: Maybe<Scalars['Time']>;
  createdBy: Scalars['ID'];
  downloadUrl?: Maybe<Scalars['String']>;
  failedCount: Scalars['Int'];
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  rowCount: Scalars['Int'];
  startedAt?: Maybe<Scalars['Time']>;
  status: ProductBulkActionStatus;
  successCount: Scalars['Int'];
  type: ProductBulkActionTemplate;
};

export type ProductBulkActionDetailItem = {
  __typename?: 'ProductBulkActionDetailItem';
  description?: Maybe<Scalars['String']>;
  product: Product;
  reason: Scalars['String'];
};

export type ProductBulkActionResult = GenericError | StatusResponse;

export enum ProductBulkActionStatus {
  Completed = 'COMPLETED',
  Processing = 'PROCESSING',
  Uploaded = 'UPLOADED'
}

export enum ProductBulkActionTemplate {
  Freeze = 'FREEZE',
  Unfreeze = 'UNFREEZE'
}

export type ProductBulkActionTemplateInput = {
  template: ProductBulkActionTemplate;
};

export type ProductBulkActionTemplateResponse = {
  __typename?: 'ProductBulkActionTemplateResponse';
  url?: Maybe<Scalars['String']>;
};

export type ProductBulkActionTemplateResult = GenericError | ProductBulkActionTemplateResponse;

export enum ProductBulkActionType {
  Freeze = 'FREEZE',
  Unfreeze = 'UNFREEZE'
}

export type ProductBulkUploadRequest = {
  __typename?: 'ProductBulkUploadRequest';
  createdAt: Scalars['Time'];
  createdByPersonaId: Scalars['String'];
  createdByUserId: Scalars['String'];
  id: Scalars['ID'];
  status: ProductBulkUploadRequestStatus;
};

export type ProductBulkUploadRequestFilter = {
  status?: InputMaybe<Array<ProductBulkUploadRequestStatus>>;
};

export type ProductBulkUploadRequestInput = {
  filter: ProductBulkUploadRequestFilter;
  pagination: PaginationInput;
};

export type ProductBulkUploadRequestResponse = {
  __typename?: 'ProductBulkUploadRequestResponse';
  currentPage?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<ProductBulkUploadRequest>>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductBulkUploadRequestResult = GenericError | ProductBulkUploadRequestResponse;

export enum ProductBulkUploadRequestStatus {
  Completed = 'COMPLETED',
  Processing = 'PROCESSING',
  Requested = 'REQUESTED'
}

export type ProductCategories = {
  __typename?: 'ProductCategories';
  currentPage: Scalars['Int'];
  items: Array<ProductCategory>;
  lastPage: Scalars['Int'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  additionalInfo?: Maybe<Array<ProductCategoryFormField>>;
  additionalInfoCount: Scalars['Int'];
  additionalInfoCustomFormId?: Maybe<Scalars['ID']>;
  allowedPpnPercentage?: Maybe<Array<Scalars['Float']>>;
  createdAt: Scalars['Time'];
  curationEnabled: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Time']>;
  documentCount: Scalars['Int'];
  documentCustomFormId?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<ProductCategoryFormField>>;
  /** @deprecated Now use ppnPercentage */
  hasVat?: Maybe<Scalars['Boolean']>;
  hetPriceEnabled: Scalars['Boolean'];
  icon: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  kbki?: Maybe<ProductCategoryKbkiCodes>;
  kbli?: Maybe<Array<Scalars['String']>>;
  level: Scalars['Int'];
  masterProductEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  nieEnabled: Scalars['Boolean'];
  parent?: Maybe<ProductCategory>;
  parentId?: Maybe<Scalars['ID']>;
  ppnPercentage: Scalars['Float'];
  primaryInfo?: Maybe<Array<ProductCategoryFormField>>;
  primaryInfoCount: Scalars['Int'];
  primaryInfoCustomFormId?: Maybe<Scalars['ID']>;
  productCount: Scalars['Int'];
  productType?: Maybe<ProductType>;
  slug: Scalars['String'];
  subcategoriesCount: Scalars['Int'];
  type?: Maybe<CategoryType>;
  updatedAt: Scalars['Time'];
  zoningPriceEnabled: Scalars['Boolean'];
};

export type ProductCategoryFormField = {
  __typename?: 'ProductCategoryFormField';
  additionalInfo?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  customFormId: Scalars['String'];
  defaultValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mandatory: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Int'];
  showOnLogin: Scalars['Boolean'];
  type: FormType;
  updatedAt: Scalars['Time'];
};

export type ProductCategoryKbkiCodes = {
  __typename?: 'ProductCategoryKBKICodes';
  classCodes?: Maybe<Array<Scalars['String']>>;
  commodityCodes?: Maybe<Array<Scalars['String']>>;
  commodityGroupCodes?: Maybe<Array<Scalars['String']>>;
  divisionCodes?: Maybe<Array<Scalars['String']>>;
  groupCodes?: Maybe<Array<Scalars['String']>>;
  sectionCodes?: Maybe<Array<Scalars['String']>>;
  subclassCodes?: Maybe<Array<Scalars['String']>>;
};

export type ProductCategoryPrimaryInfoInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['String']>;
  mandatory?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ProductCategoryResult = GenericError | ProductCategory;

export type ProductCategorySpec = {
  __typename?: 'ProductCategorySpec';
  curationEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ProductChanges = {
  __typename?: 'ProductChanges';
  after?: Maybe<Product>;
  before?: Maybe<Product>;
  changes?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['Time'];
  formattedCreatedDate?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type ProductChangesInput = {
  id: Scalars['ID'];
  version: Scalars['Int'];
};

export type ProductChangesListFilter = {
  fromDate?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
};

export type ProductChangesListInput = {
  filter: ProductChangesListFilter;
  id: Scalars['ID'];
  pagination: PaginationInput;
};

export type ProductChangesResult = GenericError | ProductChanges;

export type ProductChangesSummary = {
  __typename?: 'ProductChangesSummary';
  createdAt: Scalars['Time'];
  formattedCreatedDate?: Maybe<Scalars['String']>;
  statusChanges?: Maybe<ProductStatusChanges>;
  type: ProductChangesType;
  userInfo: PublicUserInfo;
  version: Scalars['Int'];
};

export enum ProductChangesType {
  Detail = 'DETAIL',
  Initial = 'INITIAL',
  Status = 'STATUS'
}

export type ProductCheckout = {
  __typename?: 'ProductCheckout';
  addOns?: Maybe<Array<ProductCheckoutAddOn>>;
  id: Scalars['ID'];
  updatedAt: Scalars['Time'];
  variant: ProductCheckoutVariant;
};

export type ProductCheckoutAddOn = {
  __typename?: 'ProductCheckoutAddOn';
  id: Scalars['ID'];
  type: Scalars['String'];
  variant: ProductCheckoutAddOnVariant;
};

export type ProductCheckoutAddOnInput = {
  price: Scalars['Float'];
  spec: ProductCheckoutAddOnSpec;
};

export type ProductCheckoutAddOnNegotiationInput = {
  negotiatedPrice: Scalars['Float'];
  price: Scalars['Float'];
  spec: ProductCheckoutAddOnSpec;
};

export type ProductCheckoutAddOnSpec = {
  productAddOnId: Scalars['ID'];
  productAddOnVariantId: Scalars['ID'];
  type: Scalars['String'];
};

export type ProductCheckoutAddOnVariant = {
  __typename?: 'ProductCheckoutAddOnVariant';
  id: Scalars['ID'];
  negotiatedPrice: Scalars['Float'];
  price: Scalars['Float'];
};

export type ProductCheckoutInput = {
  addOns: Array<ProductCheckoutAddOnInput>;
  price: Scalars['Float'];
  spec: ProductCheckoutSpec;
};

export type ProductCheckoutNegotiationInput = {
  addOns: Array<ProductCheckoutAddOnNegotiationInput>;
  negotiatedPrice: Scalars['Float'];
  price: Scalars['Float'];
  spec: ProductCheckoutSpec;
};

export type ProductCheckoutSpec = {
  productId: Scalars['ID'];
  productVariantId: Scalars['ID'];
  /** Last updated time of the product or its child fields. The updated at on the children will be passed to the top level product */
  updatedAt: Scalars['Time'];
};

export type ProductCheckoutVariant = {
  __typename?: 'ProductCheckoutVariant';
  id: Scalars['ID'];
  negotiatedPrice: Scalars['Float'];
  price: Scalars['Float'];
};

export enum ProductDimensionUnit {
  Cm = 'CM',
  M = 'M',
  Mm = 'MM'
}

export type ProductImage = {
  __typename?: 'ProductImage';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type ProductInformation = {
  __typename?: 'ProductInformation';
  additionalInformations?: Maybe<Array<ProductInformationDetail>>;
  documents?: Maybe<Array<ProductInformationDetail>>;
  mainInformations?: Maybe<Array<ProductInformationDetail>>;
};

export type ProductInformationDetail = {
  __typename?: 'ProductInformationDetail';
  createdAt: Scalars['Time'];
  customFormFieldId: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
  value: Scalars['String'];
};

export type ProductItem = {
  __typename?: 'ProductItem';
  name: Scalars['String'];
  orderItemId: Scalars['ID'];
  price: Scalars['Float'];
  productId: Scalars['ID'];
  qty: Scalars['Int'];
  variantId: Scalars['ID'];
  volumetric?: Maybe<ItemVolumetric>;
};

export type ProductNie = {
  __typename?: 'ProductNIE';
  nie?: Maybe<Nie>;
};

export type ProductNieResult = GenericError | ProductNie;

export type ProductNameAvailability = {
  __typename?: 'ProductNameAvailability';
  available: Scalars['Boolean'];
};

export type ProductNameAvailabilityResult = GenericError | ProductNameAvailability;

export type ProductOption = {
  __typename?: 'ProductOption';
  id: Scalars['ID'];
  option: Scalars['String'];
  optionLevel: Scalars['Int'];
  valueIds: Array<Scalars['ID']>;
  values: Array<Scalars['String']>;
};

export type ProductPdn = {
  __typename?: 'ProductPdn';
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  laborDescription: Scalars['String'];
  laborType: PdnDetailType;
  locationDescription: Scalars['String'];
  locationType: PdnDetailType;
  materialDescription: Scalars['String'];
  materialType: PdnDetailType;
  type: PdnType;
};

export type ProductPreOrder = {
  __typename?: 'ProductPreOrder';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  sla: Scalars['Int'];
  updatedAt: Scalars['Time'];
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  maxPrice: Scalars['Float'];
  maxPriceWithTax: Scalars['Float'];
  minPrice: Scalars['Float'];
  minPriceWithTax: Scalars['Float'];
  minPurchase: Scalars['Int'];
  productWholesalePrices?: Maybe<Array<ProductWholesalePrice>>;
  selectedRegionPrice?: Maybe<ProductRegionPrice>;
};

export type ProductPriceSnapshot = {
  __typename?: 'ProductPriceSnapshot';
  maxPrice: Scalars['Float'];
  maxPriceWithTax: Scalars['Float'];
  minPrice: Scalars['Float'];
  minPriceWithTax: Scalars['Float'];
  minPurchase: Scalars['Int'];
  productWholesalePrices?: Maybe<Array<ProductWholesalePrice>>;
  selectedRegionPrice?: Maybe<ProductRegionPrice>;
};

export type ProductPrimaryInfo = {
  __typename?: 'ProductPrimaryInfo';
  additionalInfo?: Maybe<Scalars['String']>;
  customFormId?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  mandatory?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type ProductRegionPrice = {
  __typename?: 'ProductRegionPrice';
  id: Scalars['ID'];
  parentRegionCode?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceWithTax: Scalars['Float'];
  regionCode: Scalars['String'];
  regionLevel: Scalars['String'];
  regionName: Scalars['String'];
};

export type ProductRegionPrices = {
  __typename?: 'ProductRegionPrices';
  items?: Maybe<Array<ProductRegionPrice>>;
};

export type ProductResponse = {
  __typename?: 'ProductResponse';
  data?: Maybe<Product>;
  errors?: Maybe<Array<Error>>;
};

export type ProductResult = GenericError | Product;

export enum ProductReviewAction {
  ApproveReview = 'APPROVE_REVIEW',
  RejectReview = 'REJECT_REVIEW'
}

export type ProductReviewInput = {
  action: ProductReviewAction;
  description?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  reasons?: InputMaybe<Array<ProductReviewReason>>;
};

export enum ProductReviewReason {
  CategoryNotMatch = 'CATEGORY_NOT_MATCH',
  Forbidden = 'FORBIDDEN',
  MissingCertificates = 'MISSING_CERTIFICATES',
  Others = 'OTHERS',
  SpecificationNotMatch = 'SPECIFICATION_NOT_MATCH',
  UnreasonablePrice = 'UNREASONABLE_PRICE'
}

export type ProductSearchAggregation = CategoryAllLevelAggregations;

export type ProductShipping = {
  __typename?: 'ProductShipping';
  dimensionUnit: ProductDimensionUnit;
  hasSellerShipping: Scalars['Boolean'];
  height: Scalars['Float'];
  length: Scalars['Float'];
  weight: Scalars['Float'];
  weightUnit: ProductWeightUnit;
  width: Scalars['Float'];
};

export type ProductSnapshot = {
  __typename?: 'ProductSnapshot';
  brand?: Maybe<Brand>;
  category: ProductCategorySpec;
  categoryType: CategoryType;
  createdAt: Scalars['Time'];
  createdBy: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['String']>;
  hasVat: Scalars['Boolean'];
  id: Scalars['ID'];
  images: Array<ProductImage>;
  isActive: Scalars['Boolean'];
  isSellerUMKK: Scalars['Boolean'];
  kbki: Scalars['String'];
  labels?: Maybe<Array<Scalars['String']>>;
  masterProductId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  nie?: Maybe<Nie>;
  pdn: ProductPdn;
  preOrder?: Maybe<ProductPreOrder>;
  prices: ProductPriceSnapshot;
  productAddOns?: Maybe<Array<ProductAddOn>>;
  productInformations: ProductInformation;
  productOptions?: Maybe<Array<ProductOption>>;
  sellerId: Scalars['ID'];
  sellerName: Scalars['String'];
  sellerVillageAreaCode: Scalars['String'];
  shipping: ProductShipping;
  shippingOption: ShippingOptionType;
  slug: Scalars['String'];
  sni?: Maybe<Sni>;
  status: ProductStatusType;
  stockAccumulation: Scalars['Int'];
  stockUnit?: Maybe<ProductStockUnit>;
  tkdn?: Maybe<Tkdn>;
  type: ProductType;
  unitSold: Scalars['Int'];
  updatedAt: Scalars['Time'];
  username: Scalars['String'];
  variants: Array<ProductVariant>;
  version: Scalars['Int'];
  videoSource?: Maybe<VideoSourceType>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type ProductSnapshotAddOn = {
  __typename?: 'ProductSnapshotAddOn';
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  productAddOnVariants: Array<ProductSnapshotAddOnVariant>;
  tax: OrderProductSnapshotAddOnTax;
  type: AddOnType;
};

export type ProductSnapshotAddOnVariant = {
  __typename?: 'ProductSnapshotAddOnVariant';
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type ProductSnapshotImage = {
  __typename?: 'ProductSnapshotImage';
  imageUrl: Scalars['String'];
};

export type ProductSnapshotPdn = {
  __typename?: 'ProductSnapshotPdn';
  type: PdnType;
};

export type ProductSnapshotPreOrder = {
  __typename?: 'ProductSnapshotPreOrder';
  sla: Scalars['Int'];
};

export type ProductSnapshotPrice = {
  __typename?: 'ProductSnapshotPrice';
  maxPrice: Scalars['Float'];
  minPrice: Scalars['Float'];
  minPurchase: Scalars['Float'];
  productWholesalePrices: Array<ProductSnapshotWholesalePrice>;
  selectedRegionPrice?: Maybe<ProductSnapshotRegionPrice>;
};

export type ProductSnapshotRegionPrice = {
  __typename?: 'ProductSnapshotRegionPrice';
  regionName: Scalars['String'];
};

export type ProductSnapshotShipping = {
  __typename?: 'ProductSnapshotShipping';
  dimensionUnit: ProductDimensionUnit;
  height: Scalars['Float'];
  length: Scalars['Float'];
  weight: Scalars['Float'];
  weightUnit: ProductWeightUnit;
  width: Scalars['Float'];
};

export type ProductSnapshotStockUnit = {
  __typename?: 'ProductSnapshotStockUnit';
  primaryUnit: Scalars['String'];
};

export type ProductSnapshotVariant = {
  __typename?: 'ProductSnapshotVariant';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  optionValues?: Maybe<Array<Scalars['String']>>;
  options?: Maybe<Array<Scalars['String']>>;
  price: Scalars['Float'];
  sku: Scalars['String'];
};

export type ProductSnapshotWholesalePrice = {
  __typename?: 'ProductSnapshotWholesalePrice';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  minQuantity: Scalars['Float'];
  price: Scalars['Float'];
};

export type ProductSnapshots = {
  __typename?: 'ProductSnapshots';
  items?: Maybe<Array<Maybe<ProductSnapshot>>>;
};

export type ProductSnapshotsResult = GenericError | ProductSnapshots;

export type ProductStatusChanges = {
  __typename?: 'ProductStatusChanges';
  after: ProductStatusType;
  before: ProductStatusType;
};

export type ProductStatusResponse = GenericError | StatusResponse;

export enum ProductStatusType {
  Active = 'ACTIVE',
  /** @deprecated deprecated */
  Blocked = 'BLOCKED',
  /** @deprecated deprecated */
  Curation = 'CURATION',
  Deleted = 'DELETED',
  Freeze = 'FREEZE',
  Inactive = 'INACTIVE',
  InReview = 'IN_REVIEW',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum ProductStatusUpdate {
  Ignore = 'IGNORE',
  UpdateToPending = 'UPDATE_TO_PENDING'
}

export type ProductStockUnit = {
  __typename?: 'ProductStockUnit';
  primaryUnit: StockUnitType;
  secondaryUnit?: Maybe<StockUnitType>;
  value?: Maybe<Scalars['Int']>;
};

export enum ProductTkdnStatus {
  Approved = 'APPROVED',
  NeedScoring = 'NEED_SCORING',
  OnVerification = 'ON_VERIFICATION',
  Rejected = 'REJECTED'
}

export type ProductTax = {
  __typename?: 'ProductTax';
  ppnBmId?: Maybe<Scalars['ID']>;
  ppnBmPercentage: Scalars['Float'];
  /** @deprecated PpnBm type now fetched from cms */
  ppnBmType?: Maybe<PpnBmType>;
  ppnPercentage: Scalars['Float'];
  ppnTypes: Array<PpnType>;
};

export type ProductTotalSummaryInput = {
  ppnBmPercentage: Scalars['Float'];
  ppnPercentage: Scalars['Float'];
  price: Scalars['Float'];
  qty: Scalars['Float'];
};

export enum ProductType {
  Digital = 'DIGITAL',
  Physical = 'PHYSICAL',
  Service = 'SERVICE'
}

export type ProductVariant = {
  __typename?: 'ProductVariant';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  isActive: Scalars['Boolean'];
  optionValues?: Maybe<Array<Scalars['String']>>;
  options?: Maybe<Array<Scalars['String']>>;
  price: Scalars['Float'];
  priceWithTax: Scalars['Float'];
  sellerId: Scalars['ID'];
  sku: Scalars['String'];
  sortOrder: Scalars['Int'];
  stock: Scalars['Int'];
  updatedAt: Scalars['Time'];
};

export enum ProductWeightUnit {
  Gr = 'GR',
  Kg = 'KG'
}

export type ProductWholesalePrice = {
  __typename?: 'ProductWholesalePrice';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  minQuantity: Scalars['Int'];
  price: Scalars['Float'];
  priceWithTax: Scalars['Float'];
  updatedAt: Scalars['Time'];
};

export type Products = {
  __typename?: 'Products';
  items: Array<Product>;
};

export type ProductsResult = GenericError | Products;

export enum Profession {
  Asn = 'ASN',
  NonAsn = 'NON_ASN'
}

export type Profile = {
  __typename?: 'Profile';
  certificatePbj?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailAlt?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  institutionID?: Maybe<Scalars['String']>;
  institutionName?: Maybe<Scalars['String']>;
  institutionType?: Maybe<Scalars['String']>;
  jabatan?: Maybe<Scalars['String']>;
  jabatanLevel?: Maybe<Scalars['String']>;
  nik?: Maybe<Scalars['String']>;
  nip?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumberMember>;
  phoneNumberAlt?: Maybe<PhoneNumberMember>;
  role?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  satKer?: Maybe<Scalars['String']>;
  satkerID?: Maybe<Scalars['String']>;
};

export type ProfileInfo = {
  __typename?: 'ProfileInfo';
  dob?: Maybe<Scalars['String']>;
  nik?: Maybe<Scalars['String']>;
  nip?: Maybe<Scalars['String']>;
};

export enum ProfileStatus {
  NikDuplicatedPending = 'NIK_DUPLICATED_PENDING',
  NipDuplicatedPending = 'NIP_DUPLICATED_PENDING',
  NipNikDuplicatedPending = 'NIP_NIK_DUPLICATED_PENDING',
  ProfileDocsUploaded = 'PROFILE_DOCS_UPLOADED',
  ProfileReject = 'PROFILE_REJECT',
  ProfileVerified = 'PROFILE_VERIFIED',
  ScheduleAssigned = 'SCHEDULE_ASSIGNED'
}

export type ProposeAddendumInput = {
  clauses?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<ProposeAddendumItemInput>>;
  orderId: Scalars['ID'];
  reason: Scalars['String'];
  requestArrivalDate?: InputMaybe<Scalars['Time']>;
};

export type ProposeAddendumItemInput = {
  componentId: Scalars['ID'];
  componentType: AddendumDetailType;
  newValue: Scalars['Float'];
};

export type ProposeAddendumResponse = {
  __typename?: 'ProposeAddendumResponse';
  id: Scalars['ID'];
};

export type ProposeNegotiationDetailInput = {
  componentId: Scalars['ID'];
  newPrice: Scalars['Float'];
};

export type ProposeNegotiationInput = {
  details: Array<ProposeNegotiationDetailInput>;
  orderKey: Scalars['String'];
  orderNegotiationId: Scalars['ID'];
};

export type ProposeNegotiationResponse = {
  __typename?: 'ProposeNegotiationResponse';
  id: Scalars['ID'];
};

export enum ProviderType {
  NonProvider = 'NON_PROVIDER',
  Provider = 'PROVIDER'
}

export type PublicCompany = {
  __typename?: 'PublicCompany';
  bentukUsaha?: Maybe<Scalars['String']>;
  bidangIndustri: Scalars['String'];
  companyAddresses: Array<Maybe<CompanyAddressesType>>;
  id: Scalars['String'];
  institusiId: Scalars['String'];
  isCabang: Scalars['Boolean'];
  isUmkk: Scalars['Boolean'];
  jenisPerusahaan: Scalars['String'];
  name: Scalars['String'];
  npwp: Scalars['String'];
  picChat: Array<Scalars['String']>;
  picTtd: Scalars['String'];
  rekanId: Scalars['Int'];
  shippingAddresses: Array<Maybe<AddressesType>>;
  skalaUsaha?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: Scalars['String'];
  website: Scalars['String'];
};

export type PublicPersonaNonPenyedia = {
  __typename?: 'PublicPersonaNonPenyedia';
  persona?: Maybe<PublicPersonaType>;
  personaId: Scalars['String'];
  satker?: Maybe<Satker>;
};

export type PublicPersonaType = {
  __typename?: 'PublicPersonaType';
  appUserName?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  institusiId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type PublicUserInfo = {
  __typename?: 'PublicUserInfo';
  id: Scalars['String'];
  name: Scalars['String'];
  username: Scalars['String'];
};

export type PublishProductBulkActionInput = {
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  activeLogisticConfiguration: LogisticConfigurationResult;
  activeRegionSellerCourier: ActiveRegionPriceResult;
  allMinifiedProductCategory: AllMinifiedProductCategoryResponse;
  autoCompleteProduct: AutoCompleteProductResult;
  bastWithToken: BastResult;
  bulkGetProductSnapshots: ProductSnapshotsResult;
  cartCheckoutMetadataV2: CartCheckoutMetadataV2Result;
  cartListV2: CartListResult;
  cartSetting: CartSettingResult;
  /** @deprecated Moved to cartSetting */
  cartSettings: CartSettings;
  chatCompany: ChatCompanyResponse;
  checkMasterProductAvailability: CheckMasterProductAvailabilityResult;
  checkNIB?: Maybe<CheckNibResponse>;
  checkProductNameAvailability: ProductNameAvailabilityResult;
  companyAdminTransferList?: Maybe<RoleChangeListResponse>;
  companyBankAccount: BankAccountListResponse;
  companyPublic: CompanyPublicResponse;
  countries: CountriesResult;
  deliveryOrder: DeliveryOrderResult;
  deliveryOrderStatusHistory: DeliveryOrderStatusHistoryResult;
  downloadAccount?: Maybe<DownloadAccountResponse>;
  dummyRequest?: Maybe<Scalars['String']>;
  echo?: Maybe<EchoResult>;
  echoRaw?: Maybe<EchoRawResult>;
  eselon: EselonResponse;
  generateInterviewSchedule?: Maybe<GenerateInterviewScheduleResponse>;
  getAllKBKI?: Maybe<KbkiList>;
  getAllKBLI?: Maybe<KbliList>;
  getAllProductCategory: ListProductCategoryResult;
  getAllRegion?: Maybe<RegionAllList>;
  getAllRegionV2?: Maybe<RegionAllResult>;
  getApplicationByFilter?: Maybe<ApplicationsResponse>;
  getBrand: BrandResult;
  getCOA: GetCoaResponse;
  getCompanyByFilter?: Maybe<GetCompanyByFilterResponse>;
  getCompanyMemberList?: Maybe<CompanyMemberListResponse>;
  getDocumentStatus?: Maybe<ContractDocumentStatus>;
  getEligibleApps: GetEligibleAppsResponse;
  getEsignByFilter?: Maybe<Array<Maybe<ContractUser>>>;
  getInstitutionByFilter: GetInstitutionByFilterResponse;
  getInstitutionByID: GetInstitutionByIdResponse;
  getInterviewSchedule?: Maybe<GetInterviewScheduleResponse>;
  getListMasterProduct: ListMasterProductResult;
  getMasterProduct: MasterProductResult;
  getNIE: NieResult;
  getPersonaListForApplication?: Maybe<GetPersonaListForApplicationResponse>;
  getPersonaNonpenyediaByID: GetPersonaNonPenyediaByIdResponse;
  getPersonaPenyediaByID: GetPersonaPenyediaByIdResponse;
  getPriceByDeliveryOrderId: GetPriceByDeliveryOrderIdResult;
  /** @deprecated No longer supported */
  getPrivyRegLink?: Maybe<GetPrivyLinkResponse>;
  getPrivyRegLinkAccount?: Maybe<GetPrivyLinkResponse>;
  getProduct?: Maybe<ProductResult>;
  getProductBySlug?: Maybe<ProductResult>;
  getProductCategory: ProductCategoryResult;
  getRup?: Maybe<Array<Rup>>;
  getSearchRegion?: Maybe<RegionSearchList>;
  getSearchRegionV2?: Maybe<RegionSearchResult>;
  getSearchSNI: SniListResult;
  getSearchTKDN: ListTkdnResult;
  getShippingLabel: GetShippingLabelResult;
  getSignImage?: Maybe<GetSignImageResponse>;
  getStatusEnterprise: GetStatusEnterpriseResponse;
  /** @deprecated No longer supported */
  getStatusEsign: ContractUser;
  getStatusEsignAccount: ContractUser;
  getStatusOnboarding: GetStatusOnboardingResponse;
  getTraceParentRegion?: Maybe<RegionTraceParentList>;
  getTraceParentRegionV2?: Maybe<RegionTraceParentResult>;
  getTransactionLogs?: Maybe<Array<Maybe<TransactionLog>>>;
  getVerificationStatus: GetVerificationStatusResponse;
  klpd: KlpdResponse;
  listPayment: ListPaymentResponse;
  listPersonaNonPenyedia: ListPersonaNonPenyediaResponse;
  listPersonaPenyedia: ListPersonaPenyediaResponse;
  listPpn: ListPpnResult;
  listProduct?: Maybe<ListProductResult>;
  listProductBulkAction: ListProductBulkActionResult;
  listProductBulkActionDetailItem: ListProductBulkActionDetailItemResult;
  listRup?: Maybe<ListRupResponse>;
  logistic: LogisticResult;
  logisticPricing: PricingResult;
  logisticRate: RateResult;
  logisticRates: AllRateResult;
  logistics: AllLogisticResult;
  lowestProductSoldPrice: LowestProductSoldPriceResult;
  me?: Maybe<MeResponse>;
  negotiationDetailV2: NegotiationDetailResult;
  negotiationListV2: NegotiationListResult;
  orderDetail: OrderDetailResult;
  orderDocumentDetailV2: OrderDocumentDetailResult;
  orderListV2: OrderListResult;
  orderStatusHistorySummary: OrderStatusHistorySummaryResult;
  orderTotalSummaryV2: OrderTotalSummaryResult;
  paymentDetail: PaymentDetailResponse;
  paymentDetailWithToken: PaymentDetailResponse;
  paymentInvoiceWithToken: InvoiceResponse;
  paymentMethodAvailability: PaymentMethodAvailabilityResponse;
  paymentMethods: PaymentMethodResponse;
  pendingPersonaNonPenyediaVerification: PendingPersonaNonPenyediaVerificationResponse;
  personaClaimLegacyApp: LegacyAppPersonaClaimResponse;
  personaValidateNPWP?: Maybe<PersonaValidateNpwpResponse>;
  pnbpTransaction: PnbpTransactionResponse;
  ppnBm: PpnBmResult;
  productBrand: ProductBrandResult;
  productBulkActionTemplate: ProductBulkActionTemplateResult;
  productBulkUploadRequest: ProductBulkUploadRequestResult;
  productChangesDetail: ProductChangesResult;
  productChangesList: ListProductChangesResult;
  productLogisticConfiguration: LogisticConfigurationResult;
  productNIE: ProductNieResult;
  products: ProductsResult;
  requestUploadSignedUrl: Array<RequestUploadSignedUrlResult>;
  reverseGeocode: ReverseGeocodeResult;
  satker: SatkerResponse;
  searchPersona: SearchPersonaResponse;
  searchPersonaNonPenyedia: SearchPersonaNonPenyediaResponse;
  searchProducts: ListSearchProductResult;
  sellerProductCount: SellerProductCountResult;
  shippingAddressBySnapshotId?: Maybe<ShippingAddressBySnapshotIdResponse>;
  shippingLabelStatus: ShippingLabelStatusResult;
  specialProductRegionPrice?: Maybe<GetProductRegionpriceResult>;
  storeInformation: StoreInformationResult;
  storeNotes: GetStoreNotesResponse;
  subscriberHash?: Maybe<NotificationSubscriberHash>;
  tax: TaxResult;
  tracePpnBm: PpnBmResult;
  userLogisticConfiguration: UserLogisticConfigurationResult;
  userLogisticRegionPrice: UserLogisticRegionPriceResult;
  userLogisticRegionPriceWithRegionInfo: UserLogisticRegionPriceWithRegionInfoResult;
  virtualAccount: Array<VaList>;
  virtualAccountAvailability: VirtualAccountAvailabilityResponse;
};


export type QueryActiveLogisticConfigurationArgs = {
  productId?: InputMaybe<Scalars['ID']>;
  sellerId?: InputMaybe<Scalars['ID']>;
};


export type QueryActiveRegionSellerCourierArgs = {
  parentRegionCode?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  regionLevel: Scalars['String'];
  sellerId?: InputMaybe<Scalars['String']>;
};


export type QueryAllMinifiedProductCategoryArgs = {
  input: AllMinifiedProductCategoryInput;
};


export type QueryAutoCompleteProductArgs = {
  keyword: Scalars['String'];
};


export type QueryBastWithTokenArgs = {
  token: Scalars['String'];
};


export type QueryBulkGetProductSnapshotsArgs = {
  input?: InputMaybe<Array<BulkProductSnapshotInput>>;
};


export type QueryCartCheckoutMetadataV2Args = {
  source: CheckoutSource;
};


export type QueryChatCompanyArgs = {
  filter: ChatCompanyFilterInput;
  pagination: AccountPagination;
};


export type QueryCheckMasterProductAvailabilityArgs = {
  masterProductId: Scalars['ID'];
};


export type QueryCheckNibArgs = {
  nib: Scalars['String'];
};


export type QueryCheckProductNameAvailabilityArgs = {
  name: Scalars['String'];
  productID?: InputMaybe<Scalars['String']>;
};


export type QueryCompanyAdminTransferListArgs = {
  institutionId?: InputMaybe<Scalars['String']>;
};


export type QueryCompanyBankAccountArgs = {
  institusiId: Scalars['String'];
};


export type QueryCompanyPublicArgs = {
  filter: CompanyPublicFilter;
};


export type QueryCountriesArgs = {
  query?: InputMaybe<CountriesInput>;
};


export type QueryDeliveryOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryDeliveryOrderStatusHistoryArgs = {
  deliveryOrderId: Scalars['ID'];
};


export type QueryDownloadAccountArgs = {
  tokens?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryEselonArgs = {
  filter?: InputMaybe<EselonFilter>;
  pagination: AccountPagination;
};


export type QueryGenerateInterviewScheduleArgs = {
  personaId?: InputMaybe<Scalars['String']>;
  type: VerificationType;
};


export type QueryGetAllKbkiArgs = {
  classification?: InputMaybe<Scalars['String']>;
  codes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  level?: InputMaybe<KbkiLevel>;
  page?: InputMaybe<Scalars['Int']>;
  parentCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllKbliArgs = {
  classification?: InputMaybe<Scalars['String']>;
  codes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  level?: InputMaybe<KbliLevel>;
  page?: InputMaybe<Scalars['Int']>;
  parentCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllProductCategoryArgs = {
  input?: InputMaybe<ListCategoryInput>;
};


export type QueryGetAllRegionArgs = {
  query?: InputMaybe<RegionAllInput>;
};


export type QueryGetAllRegionV2Args = {
  query?: InputMaybe<RegionAllInput>;
};


export type QueryGetApplicationByFilterArgs = {
  filter?: InputMaybe<ApplicationFilterInput>;
};


export type QueryGetBrandArgs = {
  applicationNumber: Scalars['String'];
};


export type QueryGetCoaArgs = {
  rupCode: Scalars['Int'];
};


export type QueryGetCompanyByFilterArgs = {
  filter?: InputMaybe<CompanyFilterInput>;
};


export type QueryGetCompanyMemberListArgs = {
  institutionId: Scalars['String'];
};


export type QueryGetDocumentStatusArgs = {
  input?: InputMaybe<GetContractDocumentStatusInput>;
  provider?: Scalars['String'];
};


export type QueryGetEligibleAppsArgs = {
  filter?: InputMaybe<GetEligibleAppsFilter>;
};


export type QueryGetEsignByFilterArgs = {
  provider?: InputMaybe<ContractProvider>;
};


export type QueryGetInstitutionByFilterArgs = {
  filter?: InputMaybe<InstitutionFilter>;
  pagination: AccountPagination;
};


export type QueryGetInstitutionByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetInterviewScheduleArgs = {
  personaId?: InputMaybe<Scalars['String']>;
  type: VerificationType;
};


export type QueryGetListMasterProductArgs = {
  categoryId: Scalars['ID'];
};


export type QueryGetMasterProductArgs = {
  id: Scalars['ID'];
};


export type QueryGetNieArgs = {
  query: NieFilterInput;
};


export type QueryGetPersonaListForApplicationArgs = {
  appId: Scalars['String'];
};


export type QueryGetPersonaNonpenyediaByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPersonaPenyediaByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPriceByDeliveryOrderIdArgs = {
  deliveryOrderId: Scalars['ID'];
  input: GetPriceByDeliveryOrderIdInput;
};


export type QueryGetProductArgs = {
  id: Scalars['ID'];
};


export type QueryGetProductBySlugArgs = {
  slug: Scalars['String'];
  username: Scalars['String'];
};


export type QueryGetProductCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryGetRupArgs = {
  isCOAFilled?: InputMaybe<Scalars['Boolean']>;
  rupCode?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryGetSearchRegionArgs = {
  query?: InputMaybe<RegionSearchInput>;
};


export type QueryGetSearchRegionV2Args = {
  query?: InputMaybe<RegionSearchInput>;
};


export type QueryGetSearchSniArgs = {
  query?: InputMaybe<SearchSniInput>;
};


export type QueryGetSearchTkdnArgs = {
  number?: InputMaybe<Scalars['String']>;
};


export type QueryGetShippingLabelArgs = {
  deliveryOrderId: Scalars['ID'];
};


export type QueryGetSignImageArgs = {
  userRole?: InputMaybe<UserRole>;
};


export type QueryGetStatusEnterpriseArgs = {
  input: GetStatusEnterpriseInput;
};


export type QueryGetStatusEsignArgs = {
  provider?: InputMaybe<ContractProvider>;
};


export type QueryGetStatusEsignAccountArgs = {
  provider?: InputMaybe<ContractProvider>;
};


export type QueryGetTraceParentRegionArgs = {
  query?: InputMaybe<RegionTraceParentInput>;
};


export type QueryGetTraceParentRegionV2Args = {
  query?: InputMaybe<RegionTraceParentInput>;
};


export type QueryGetTransactionLogsArgs = {
  input?: InputMaybe<TransactionLogRequest>;
  limit?: Scalars['Int'];
  page?: Scalars['Int'];
};


export type QueryGetVerificationStatusArgs = {
  personaId?: InputMaybe<Scalars['String']>;
  type: VerificationType;
};


export type QueryKlpdArgs = {
  filter?: InputMaybe<KlpdFilter>;
  pagination: AccountPagination;
};


export type QueryListPaymentArgs = {
  input?: InputMaybe<ListPaymentInput>;
};


export type QueryListPersonaNonPenyediaArgs = {
  filter?: InputMaybe<ListPersonaFilterInput>;
};


export type QueryListPersonaPenyediaArgs = {
  filter?: InputMaybe<ListPersonaFilterInput>;
};


export type QueryListProductArgs = {
  query?: InputMaybe<ListProductInput>;
};


export type QueryListProductBulkActionArgs = {
  input: ListProductBulkActionInput;
};


export type QueryListProductBulkActionDetailItemArgs = {
  input: ListProductBulkActionDetailItemInput;
};


export type QueryListRupArgs = {
  query?: InputMaybe<ListRupInput>;
};


export type QueryLogisticArgs = {
  id: Scalars['ID'];
};


export type QueryLogisticPricingArgs = {
  input: PricingInput;
};


export type QueryLogisticRateArgs = {
  id: Scalars['ID'];
};


export type QueryLowestProductSoldPriceArgs = {
  input: Array<FilterLowestProductSoldPriceInput>;
};


export type QueryNegotiationDetailV2Args = {
  input: NegotiationDetailInput;
};


export type QueryNegotiationListV2Args = {
  input: NegotiationListInput;
};


export type QueryOrderDetailArgs = {
  input: OrderInput;
};


export type QueryOrderDocumentDetailV2Args = {
  token: Scalars['String'];
};


export type QueryOrderListV2Args = {
  input: OrderListInput;
};


export type QueryOrderStatusHistorySummaryArgs = {
  id: Scalars['String'];
  sort?: InputMaybe<SortDirection>;
};


export type QueryOrderTotalSummaryV2Args = {
  input: OrderTotalSummaryInput;
};


export type QueryPaymentDetailArgs = {
  paymentID: Scalars['ID'];
};


export type QueryPaymentDetailWithTokenArgs = {
  paymentToken: Scalars['ID'];
};


export type QueryPaymentInvoiceWithTokenArgs = {
  input: InvoiceWithTokenInput;
};


export type QueryPaymentMethodAvailabilityArgs = {
  paymentId: Scalars['ID'];
};


export type QueryPaymentMethodsArgs = {
  input?: InputMaybe<PaymentMethodFilter>;
};


export type QueryPendingPersonaNonPenyediaVerificationArgs = {
  pagination: AccountPagination;
};


export type QueryPersonaClaimLegacyAppArgs = {
  app: ApplicationTypeEnum;
  lpseId?: InputMaybe<Scalars['Int']>;
  password: Scalars['String'];
  username: Scalars['String'];
};


export type QueryPersonaValidateNpwpArgs = {
  npwp?: InputMaybe<Scalars['String']>;
};


export type QueryPnbpTransactionArgs = {
  noDokumen: Scalars['String'];
};


export type QueryPpnBmArgs = {
  query?: InputMaybe<PpnBmInput>;
};


export type QueryProductBrandArgs = {
  input: ProductBrandInput;
};


export type QueryProductBulkActionTemplateArgs = {
  input: ProductBulkActionTemplateInput;
};


export type QueryProductBulkUploadRequestArgs = {
  input: ProductBulkUploadRequestInput;
};


export type QueryProductChangesDetailArgs = {
  input: ProductChangesInput;
};


export type QueryProductChangesListArgs = {
  input: ProductChangesListInput;
};


export type QueryProductLogisticConfigurationArgs = {
  productId: Scalars['ID'];
};


export type QueryProductNieArgs = {
  input: NieFilterInput;
};


export type QueryProductsArgs = {
  input: BulkProductInput;
};


export type QueryRequestUploadSignedUrlArgs = {
  input: RequestUploadSignedUrlInput;
};


export type QueryReverseGeocodeArgs = {
  query?: InputMaybe<ReverseGeocodeInput>;
};


export type QuerySatkerArgs = {
  filter?: InputMaybe<SatkerFilter>;
  pagination: AccountPagination;
};


export type QuerySearchPersonaArgs = {
  filter: PersonaFilterInput;
  pagination: AccountPagination;
};


export type QuerySearchPersonaNonPenyediaArgs = {
  filter: PersonaNonPenyediaInput;
  pagination: AccountPagination;
};


export type QuerySearchProductsArgs = {
  input: SearchProductInput;
};


export type QuerySellerProductCountArgs = {
  input?: InputMaybe<BaseProductFilter>;
};


export type QueryShippingAddressBySnapshotIdArgs = {
  snapshotId: Scalars['String'];
};


export type QueryShippingLabelStatusArgs = {
  deliveryOrderId: Scalars['ID'];
};


export type QuerySpecialProductRegionPriceArgs = {
  productId: Scalars['ID'];
};


export type QueryStoreInformationArgs = {
  filter?: InputMaybe<StoreInformationFilterInput>;
};


export type QueryStoreNotesArgs = {
  filter?: InputMaybe<GetStoreNotesFilter>;
};


export type QueryTracePpnBmArgs = {
  id: Scalars['ID'];
};


export type QueryUserLogisticRegionPriceArgs = {
  parentRegionCode?: InputMaybe<Scalars['String']>;
  regionLevel: RegionLevel;
};


export type QueryUserLogisticRegionPriceWithRegionInfoArgs = {
  pagination?: InputMaybe<Pagination>;
  parentRegionCode?: InputMaybe<Scalars['String']>;
  regionLevel: RegionLevel;
  search: Scalars['String'];
};


export type QueryVirtualAccountArgs = {
  input?: InputMaybe<BankFilter>;
};


export type QueryVirtualAccountAvailabilityArgs = {
  paymentId: Scalars['ID'];
};

export type QuestionnaireInput = {
  application?: InputMaybe<ApplicationTypeEnum>;
  applicationId: Scalars['String'];
  role?: InputMaybe<PersonaRoleEnum>;
};

export type QuestionnaireResponse = GenericError | QuestionnaireResult;

export type QuestionnaireResult = {
  __typename?: 'QuestionnaireResult';
  ids?: Maybe<Array<Scalars['String']>>;
};

export type Rup = {
  __typename?: 'RUP';
  auditUpdate?: Maybe<Scalars['String']>;
  fiscalYear: Scalars['Int'];
  fundingSourceDetails: Array<FundingSourceDetail>;
  fundingSources?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  instituteId?: Maybe<Scalars['String']>;
  instituteName?: Maybe<Scalars['String']>;
  instituteType?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isCOAFilled?: Maybe<Scalars['Boolean']>;
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthNeeds?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  procurementMethod?: Maybe<Scalars['String']>;
  procurementTypes?: Maybe<Array<Scalars['String']>>;
  rupCode: Scalars['Int'];
  status: Scalars['String'];
  totalBalance: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Time']>;
  workUnitId?: Maybe<Scalars['Int']>;
  workUnitName?: Maybe<Scalars['String']>;
};

export type RateData = {
  __typename?: 'RateData';
  id: Scalars['ID'];
  logistic: Logistic;
  maxWeight: Scalars['Int'];
  minWeight: Scalars['Int'];
  name: Scalars['String'];
  provider: Scalars['String'];
  providerRateId: Scalars['String'];
  type: Logistic_Service;
  volumetricFactor: Scalars['Int'];
};

export enum RateProvider {
  Govmart = 'GOVMART',
  Shipper = 'SHIPPER'
}

export type RateResult = GenericError | RateData;

export enum RateType {
  Cargo = 'CARGO',
  Custom = 'CUSTOM',
  Express = 'EXPRESS',
  Instant = 'INSTANT',
  Regular = 'REGULAR',
  SameDay = 'SAME_DAY'
}

export type Region = {
  __typename?: 'Region';
  areaCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFTZ: Scalars['Boolean'];
  level?: Maybe<RegionLevel>;
  name?: Maybe<Scalars['String']>;
  parentAreaCode?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  sirupId?: Maybe<Scalars['Int']>;
};

export type RegionAllInput = {
  filter: RegionFilter;
};

export type RegionAllList = {
  __typename?: 'RegionAllList';
  items: Array<Region>;
};

export type RegionAllResult = GenericError | RegionAllList;

export type RegionDetail = {
  __typename?: 'RegionDetail';
  cityName?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  provinceName?: Maybe<Scalars['String']>;
  villageName?: Maybe<Scalars['String']>;
};

export type RegionFilter = {
  areaCodes?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  level?: InputMaybe<RegionLevel>;
  parentAreaCodes?: InputMaybe<Array<Scalars['String']>>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  sirupId?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<RegionSortInput>>>;
};

export enum RegionLevel {
  City = 'CITY',
  District = 'DISTRICT',
  Province = 'PROVINCE',
  Village = 'VILLAGE'
}

export type RegionPagination = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type RegionSearchInput = {
  filter: RegionFilter;
  pagination: RegionPagination;
};

export type RegionSearchList = {
  __typename?: 'RegionSearchList';
  currentPage?: Maybe<Scalars['Int']>;
  items: Array<Region>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type RegionSearchResult = GenericError | RegionSearchList;

export enum RegionSortField {
  Name = 'NAME'
}

export type RegionSortInput = {
  field: RegionSortField;
  order: RegionSortOrder;
};

export enum RegionSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type RegionTraceParent = {
  __typename?: 'RegionTraceParent';
  cityAreaCode?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  districtAreaCode?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  isFTZ: Scalars['Boolean'];
  paramId: Scalars['String'];
  provinceAreaCode?: Maybe<Scalars['String']>;
  provinceId?: Maybe<Scalars['String']>;
  provinceName?: Maybe<Scalars['String']>;
  villageAreaCode?: Maybe<Scalars['String']>;
  villageId?: Maybe<Scalars['String']>;
  villageName?: Maybe<Scalars['String']>;
  villagePostalCode?: Maybe<Scalars['String']>;
};

export type RegionTraceParentInput = {
  areaCodes?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type RegionTraceParentList = {
  __typename?: 'RegionTraceParentList';
  items: Array<RegionTraceParent>;
};

export type RegionTraceParentResult = GenericError | RegionTraceParentList;

export type RegisterUserInternalInput = {
  email?: InputMaybe<Scalars['String']>;
  institutionID?: InputMaybe<Scalars['String']>;
  institutionType?: InputMaybe<JenisKlpdOptionMnC>;
  jabatan?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nip?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  roleID?: InputMaybe<Scalars['String']>;
  satkerID?: InputMaybe<Scalars['String']>;
  tingkatJabatan?: InputMaybe<TingkatJabatanInternal>;
};

export type RegisterUserInternalResponse = Error | UserInternal;

export type RejectAddendumInput = {
  addendumId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type RejectAddendumResponse = {
  __typename?: 'RejectAddendumResponse';
  id: Scalars['ID'];
};

export type RejectNegotiationInput = {
  orderNegotiationId: Scalars['ID'];
  reason: Scalars['String'];
  reasonDescription: Scalars['String'];
};

export type RejectNegotiationResponse = {
  __typename?: 'RejectNegotiationResponse';
  id: Scalars['ID'];
};

export type RejectPaymentInput = {
  paymentId: Scalars['ID'];
  reason: Scalars['String'];
};

export type RejectPaymentResult = GenericError | RejectPaymentSuccess;

export type RejectPaymentSuccess = {
  __typename?: 'RejectPaymentSuccess';
  paymentId: Scalars['ID'];
  status: PaymentStatus;
};

export type RejectReason = {
  __typename?: 'RejectReason';
  document: TFileUpload;
  reason: Scalars['String'];
};

export type RejectVerificationInput = {
  personaId?: InputMaybe<Scalars['String']>;
  rejectReason?: InputMaybe<Array<InputMaybe<DocumentRejection>>>;
  rejectionType: RejectionType;
  userId: Scalars['String'];
};

export type RejectVerificationResponse = GenericError | RejectVerificationSuccess;

export type RejectVerificationSuccess = {
  __typename?: 'RejectVerificationSuccess';
  PersonaId?: Maybe<Scalars['String']>;
  status: VerificationStatus;
  userId: Scalars['String'];
};

export enum RejectionType {
  HardReject = 'HARD_REJECT',
  SoftReject = 'SOFT_REJECT'
}

export type RemoveCompanyMembershipInput = {
  personaId: Scalars['String'];
};

export type RemoveCompanyMembershipRes = {
  __typename?: 'RemoveCompanyMembershipRes';
  id: Scalars['String'];
  status: Scalars['String'];
};

export type RemoveCompanyMembershipResponse = GenericError | RemoveCompanyMembershipRes;

export type ReqPaymentCreditCardInput = {
  email: Scalars['String'];
  paymentId: Scalars['String'];
};

export type ReqPaymentCreditCardResult = GenericError | ReqPaymentCreditCardSuccess;

export type ReqPaymentCreditCardSuccess = {
  __typename?: 'ReqPaymentCreditCardSuccess';
  isSuccess: Scalars['Boolean'];
};

export type RequestCancelInput = {
  orderId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export enum RequestDateType {
  Estimation = 'ESTIMATION',
  Fixed = 'FIXED'
}

export type RequestUploadSignedUrlInput = {
  type: UploadType;
  uploadSignedUrlReq: Array<UploadSignedUrlInput>;
};

export type RequestUploadSignedUrlRes = {
  __typename?: 'RequestUploadSignedUrlRes';
  expiry?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type RequestUploadSignedUrlResult = GenericError | RequestUploadSignedUrlRes;

export type ReuploadEnterpriseFileInput = {
  bidangIndustri: Scalars['String'];
  companyName: Scalars['String'];
  institusiId: Scalars['String'];
  npwp: Scalars['String'];
  npwpToken: Scalars['String'];
};

export type ReuploadEnterpriseFileRes = {
  __typename?: 'ReuploadEnterpriseFileRes';
  institusiId: Scalars['String'];
};

export type ReuploadEnterpriseFileResponse = GenericError | ReuploadEnterpriseFileRes;

export type ReuploadFileSoftRejectRequest = {
  bntToken?: InputMaybe<Scalars['String']>;
  nibToken?: InputMaybe<Scalars['String']>;
  nikToken?: InputMaybe<Scalars['String']>;
  nipToken?: InputMaybe<Scalars['String']>;
  npwpToken?: InputMaybe<Scalars['String']>;
  personaId?: InputMaybe<Scalars['String']>;
  suratKeteranganKerjaToken?: InputMaybe<Scalars['String']>;
  suratKuasaToken?: InputMaybe<Scalars['String']>;
  suratPkpToken?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ReuploadFileSoftRejectResponse = GenericError | ReuploadFileSoftRejectSuccess;

export type ReuploadFileSoftRejectSuccess = {
  __typename?: 'ReuploadFileSoftRejectSuccess';
  personaId: Scalars['String'];
  userId: Scalars['String'];
};

export type ReverseGeocode = {
  __typename?: 'ReverseGeocode';
  cityAreaCode: Scalars['String'];
  cityId: Scalars['String'];
  cityName: Scalars['String'];
  districtAreaCode: Scalars['String'];
  districtId: Scalars['String'];
  districtName: Scalars['String'];
  formattedAddress?: Maybe<Scalars['String']>;
  provinceAreaCode: Scalars['String'];
  provinceId: Scalars['String'];
  provinceName: Scalars['String'];
  villageAreaCode: Scalars['String'];
  villageId: Scalars['String'];
  villageName: Scalars['String'];
  villagePostalCode: Scalars['String'];
};

export type ReverseGeocodeInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ReverseGeocodeResult = GenericError | ReverseGeocode;

export enum Role {
  Buyer = 'BUYER',
  Seller = 'SELLER'
}

export type RoleChange = {
  __typename?: 'RoleChange';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['String'];
  institutionId: Scalars['String'];
  role: PenyediaRoleEnum;
  status: RoleChangeStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  userIdSource: Scalars['String'];
  userIdTarget: Scalars['String'];
};

export type RoleChangeList = {
  __typename?: 'RoleChangeList';
  roleChanges: Array<Maybe<RoleChange>>;
};

export type RoleChangeListResponse = GenericError | RoleChangeList;

export enum RoleChangeStatusEnum {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type Roles = {
  __typename?: 'Roles';
  app: AppType;
  auth0Id: Scalars['ID'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  permissions?: Maybe<Array<Permissions>>;
  roleDescription?: Maybe<Scalars['String']>;
  roleName: Scalars['String'];
  rolesCount: RolesCount;
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type RolesCount = {
  __typename?: 'RolesCount';
  internalUserCount: Scalars['Int'];
};

export type RolesFilter = {
  app?: InputMaybe<AppType>;
  auth0Ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  createdDateBegin?: InputMaybe<Scalars['String']>;
  createdDateEnd?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  roleName?: InputMaybe<Scalars['String']>;
};

export type RolesInput = {
  App: AppType;
  permissionsID?: InputMaybe<Array<Scalars['ID']>>;
  roleDescription?: InputMaybe<Scalars['String']>;
  roleName: Scalars['String'];
};

export type RolesResponse = Error | RolesResult;

export type RolesResult = {
  __typename?: 'RolesResult';
  items: Array<Roles>;
  paginationInfo: PaginationInfo;
};

export type Sni = {
  __typename?: 'SNI';
  accreditationScheme?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  certificateNumber: Scalars['String'];
  certificationScheme?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  productName: Scalars['String'];
  publishedDate?: Maybe<Scalars['String']>;
  sniNumber: Scalars['String'];
  spptNumber?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type SniFilterInput = {
  brand?: InputMaybe<Scalars['String']>;
  certificateNumber?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  sniNumber?: InputMaybe<Scalars['String']>;
  spptNumber?: InputMaybe<Scalars['String']>;
};

export type SniList = {
  __typename?: 'SNIList';
  currentPage?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Sni>>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type SniListResult = GenericError | SniList;

export type Satker = {
  __typename?: 'Satker';
  alamat?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  fax?: Maybe<Scalars['String']>;
  inTrx?: Maybe<Scalars['String']>;
  institutionId?: Maybe<Scalars['String']>;
  jenisSatker?: Maybe<Scalars['String']>;
  kanwil?: Maybe<Kanwil>;
  ketSatker?: Maybe<Scalars['String']>;
  kodeEselon?: Maybe<Scalars['String']>;
  kodeKlpd: Scalars['String'];
  kodeSatker: Scalars['String'];
  kodeSatkerAdj: Scalars['String'];
  kodeSatkerEcatalog: Scalars['String'];
  kodeSatkerPadi: Scalars['String'];
  kodeSatkerSakti: Scalars['String'];
  kodeSatkerSipd: Scalars['String'];
  kodeSatkerSirup: Scalars['String'];
  kodepos?: Maybe<Scalars['String']>;
  kppn?: Maybe<Kppn>;
  namaKlpd?: Maybe<Scalars['String']>;
  namaSatker: Scalars['String'];
  namaSatkerEcatalog: Scalars['String'];
  namaSatkerPadi: Scalars['String'];
  namaSatkerSakti: Scalars['String'];
  namaSatkerSipd: Scalars['String'];
  namaSatkerSirup: Scalars['String'];
  namaSatkerTrx?: Maybe<Scalars['String']>;
  npwp?: Maybe<Scalars['String']>;
  statusSatker?: Maybe<Scalars['String']>;
  telepon?: Maybe<Scalars['String']>;
};

export type SatkerFilter = {
  alamat?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  jenisSatker?: InputMaybe<Scalars['String']>;
  ketSatker?: InputMaybe<KetSatkerOption>;
  kodeEselons?: InputMaybe<Array<Scalars['String']>>;
  kodeKanwils?: InputMaybe<Array<Scalars['String']>>;
  kodeKanwilsAdj?: InputMaybe<Array<Scalars['String']>>;
  kodeKlpds?: InputMaybe<Array<Scalars['String']>>;
  kodeKppns?: InputMaybe<Array<Scalars['String']>>;
  kodeKppnsAdj?: InputMaybe<Array<Scalars['String']>>;
  kodeSatkers?: InputMaybe<Array<Scalars['String']>>;
  kodeSatkersAdj?: InputMaybe<Array<Scalars['String']>>;
  kodeSatkersSakti?: InputMaybe<Array<Scalars['String']>>;
  kodeSatkersSirup?: InputMaybe<Array<Scalars['String']>>;
  kodepos?: InputMaybe<Scalars['String']>;
  namaSatker?: InputMaybe<Scalars['String']>;
  statusSatker?: InputMaybe<Scalars['String']>;
  telepon?: InputMaybe<Scalars['String']>;
};

export type SatkerFilterMnC = {
  kodeEselons?: InputMaybe<Array<Scalars['String']>>;
  kodeKanwils?: InputMaybe<Array<Scalars['String']>>;
  kodeKanwilsAdj?: InputMaybe<Array<Scalars['String']>>;
  kodeKlpds?: InputMaybe<Array<Scalars['String']>>;
  kodeKppns?: InputMaybe<Array<Scalars['String']>>;
  kodeKppnsAdj?: InputMaybe<Array<Scalars['String']>>;
  kodeSatkers?: InputMaybe<Array<Scalars['String']>>;
  kodeSatkersAdj?: InputMaybe<Array<Scalars['String']>>;
  kodeSatkersSakti?: InputMaybe<Array<Scalars['String']>>;
  kodeSatkersSirup?: InputMaybe<Array<Scalars['String']>>;
  namaSatker?: InputMaybe<Scalars['String']>;
};

export type SatkerMnC = {
  __typename?: 'SatkerMnC';
  createdAt: Scalars['String'];
  inTrx?: Maybe<Scalars['String']>;
  kanwil?: Maybe<Kanwil>;
  kodeEselon: Scalars['String'];
  kodeKlpd: Scalars['String'];
  kodeSatker: Scalars['String'];
  kodeSatkerAdj: Scalars['String'];
  kodeSatkerEcatalog?: Maybe<Scalars['String']>;
  kodeSatkerPadi?: Maybe<Scalars['String']>;
  kodeSatkerSakti?: Maybe<Scalars['String']>;
  kodeSatkerSipd?: Maybe<Scalars['String']>;
  kodeSatkerSirup?: Maybe<Scalars['String']>;
  kppn?: Maybe<Kppn>;
  namaSatker: Scalars['String'];
  namaSatkerEcatalog?: Maybe<Scalars['String']>;
  namaSatkerPadi?: Maybe<Scalars['String']>;
  namaSatkerSakti?: Maybe<Scalars['String']>;
  namaSatkerSipd?: Maybe<Scalars['String']>;
  namaSatkerSirup?: Maybe<Scalars['String']>;
  namaSatkerTrx?: Maybe<Scalars['String']>;
};

export type SatkerResponse = GenericError | SatkerResult;

export type SatkerResponseMnC = Error | SatkerResultMnC;

export type SatkerResult = {
  __typename?: 'SatkerResult';
  items: Array<Satker>;
  paginationInfo: PaginationInfo;
};

export type SatkerResultMnC = {
  __typename?: 'SatkerResultMnC';
  items: Array<SatkerMnC>;
  paginationInfo: PaginationInfo;
};

export type SaveCartCheckoutMetadataInput = {
  items: Array<SaveCartCheckoutMetadataItem>;
  shipments: Array<ShipmentCheckoutSpec>;
  spec: CartCheckoutSpec;
};

export type SaveCartCheckoutMetadataItem = {
  productDetail: SaveCartCheckoutMetadataItemProduct;
  spec: ItemCheckoutSpec;
};

export type SaveCartCheckoutMetadataItemProduct = {
  productAddOnVariantIds: Array<Scalars['ID']>;
  productId: Scalars['ID'];
  productVariantId: Scalars['ID'];
};

export type SearchBrand = {
  __typename?: 'SearchBrand';
  brandName: Scalars['String'];
};

export type SearchCategoryLevel1 = {
  __typename?: 'SearchCategoryLevel1';
  child: SearchCategoryLevel2;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type SearchCategoryLevel2 = {
  __typename?: 'SearchCategoryLevel2';
  child: SearchCategoryLevel3;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type SearchCategoryLevel3 = {
  __typename?: 'SearchCategoryLevel3';
  curationEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type SearchInformation = {
  __typename?: 'SearchInformation';
  additional?: Maybe<Array<SearchNameValue>>;
  main?: Maybe<Array<SearchNameValue>>;
};

export type SearchLocation = {
  __typename?: 'SearchLocation';
  child: SearchLocationCity;
  name: Scalars['String'];
  regionCode: Scalars['String'];
};

export type SearchLocationCity = {
  __typename?: 'SearchLocationCity';
  child: SearchLocationDistrict;
  name: Scalars['String'];
  regionCode: Scalars['String'];
};

export type SearchLocationDistrict = {
  __typename?: 'SearchLocationDistrict';
  child: SearchLocationVillage;
  name: Scalars['String'];
  regionCode: Scalars['String'];
};

export type SearchLocationVillage = {
  __typename?: 'SearchLocationVillage';
  name: Scalars['String'];
  regionCode: Scalars['String'];
};

export type SearchLogistic = {
  __typename?: 'SearchLogistic';
  rateType?: Maybe<Array<RateType>>;
  type: ShippingOptionType;
};

export type SearchNie = {
  __typename?: 'SearchNIE';
  nie: Scalars['String'];
};

export type SearchNameValue = {
  __typename?: 'SearchNameValue';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type SearchPdn = {
  __typename?: 'SearchPDN';
  type: Scalars['String'];
};

export type SearchPersonaRes = {
  __typename?: 'SearchPersonaRes';
  companies: Array<CompanyType>;
  pageInfo: PaginationInfo;
  personas: Array<PersonaType>;
  users: Array<UserInfo>;
};

export type SearchPersonaResponse = GenericError | SearchPersonaRes;

export type SearchProduct = {
  __typename?: 'SearchProduct';
  brand?: Maybe<SearchBrand>;
  category: SearchCategoryLevel1;
  categoryType: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  defaultPrice: Scalars['Float'];
  defaultPriceWithTax: Scalars['Float'];
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isPreOrder: Scalars['Boolean'];
  isRegionPrice: Scalars['Boolean'];
  isSellerUMKK: Scalars['Boolean'];
  isWholesale: Scalars['Boolean'];
  labels?: Maybe<Array<Scalars['String']>>;
  location: SearchLocation;
  logistic: SearchLogistic;
  maxPrice: Scalars['Float'];
  maxPriceWithTax: Scalars['Float'];
  minPrice: Scalars['Float'];
  minPriceWithTax: Scalars['Float'];
  name: Scalars['String'];
  nie?: Maybe<SearchNie>;
  pdn?: Maybe<SearchPdn>;
  productInformations: SearchInformation;
  score: Scalars['Float'];
  scoreDetail: SearchProductScoreDetail;
  sellerId: Scalars['ID'];
  sellerName: Scalars['String'];
  slug: Scalars['String'];
  sni?: Maybe<SearchSni>;
  status: Scalars['String'];
  stockAccumulation: Scalars['Int'];
  stockAvailability: Scalars['Boolean'];
  tkdn?: Maybe<SearchTkdn>;
  type: Scalars['String'];
  unitSold: Scalars['Int'];
  username: Scalars['String'];
  variants?: Maybe<Array<SearchVariant>>;
};

export enum SearchProductAggregationTypeInput {
  CategoryAllLevel = 'CATEGORY_ALL_LEVEL'
}

export type SearchProductFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  keyword?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  maxPrice?: InputMaybe<Scalars['Float']>;
  minPrice?: InputMaybe<Scalars['Float']>;
  productTypes?: InputMaybe<Array<ProductType>>;
  rateTypes?: InputMaybe<Array<RateType>>;
  regionCode?: InputMaybe<Scalars['String']>;
  sellerId?: InputMaybe<Scalars['ID']>;
  sellerRegionCodes?: InputMaybe<Array<Scalars['String']>>;
  sellerTypes?: InputMaybe<Array<SellerType>>;
  status?: InputMaybe<Array<ProductStatusType>>;
  strategy?: SearchProductStrategy;
  tkdnBmp?: InputMaybe<Scalars['Float']>;
};

export type SearchProductInput = {
  aggregation?: InputMaybe<Array<SearchProductAggregationTypeInput>>;
  filter: SearchProductFilterInput;
  pagination: PaginationInput;
  sort?: InputMaybe<Array<SearchProductSortInput>>;
};

export type SearchProductScoreDetail = {
  __typename?: 'SearchProductScoreDetail';
  keywordScore: Scalars['Float'];
  locationScore: Scalars['Float'];
  priceScore: Scalars['Float'];
  ratingScore: Scalars['Float'];
  tkdnScore: Scalars['Float'];
  umkkScore: Scalars['Float'];
  unitSoldScore: Scalars['Float'];
};

export enum SearchProductSortField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Price = 'PRICE',
  Relevance = 'RELEVANCE',
  StockAccumulation = 'STOCK_ACCUMULATION',
  TkdnBmp = 'TKDN_BMP',
  UnitSold = 'UNIT_SOLD',
  UpdatedAt = 'UPDATED_AT'
}

export type SearchProductSortInput = {
  field: SearchProductSortField;
  order: SearchProductSortOrder;
};

export enum SearchProductSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SearchProductStrategy {
  Internal = 'INTERNAL',
  Local = 'LOCAL',
  SellerCatalogue = 'SELLER_CATALOGUE',
  Srp = 'SRP',
  Tkdn = 'TKDN',
  TkdnUmkk = 'TKDN_UMKK'
}

export type SearchSni = {
  __typename?: 'SearchSNI';
  certificateNumber: Scalars['String'];
};

export type SearchSniInput = {
  filter: SniFilterInput;
  pagination: PaginationInput;
};

export type SearchTkdn = {
  __typename?: 'SearchTKDN';
  bmpValue: Scalars['Float'];
  score?: Maybe<Scalars['Float']>;
  status?: Maybe<ProductTkdnStatus>;
  tkdnBmp: Scalars['Float'];
  value: Scalars['Float'];
};

export type SearchVariant = {
  __typename?: 'SearchVariant';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  options?: Maybe<Array<SearchNameValue>>;
  price: Scalars['Float'];
  sortOrder: Scalars['Int'];
  stock: Scalars['Int'];
};

export type SellerAddress = {
  __typename?: 'SellerAddress';
  createdAt?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isMainAddress?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumberMember>;
  postalCode?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  villageAreaCode?: Maybe<Scalars['String']>;
};

export type SellerAddressInput = {
  fullAddress?: InputMaybe<Scalars['String']>;
  isMainAddress?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  villageAreaCode?: InputMaybe<Scalars['String']>;
};

export type SellerAllResponse = Error | SellerList;

export type SellerBankDetail = {
  __typename?: 'SellerBankDetail';
  accountName?: Maybe<Scalars['String']>;
  accountNo?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isVerify?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type SellerBankInfo = {
  __typename?: 'SellerBankInfo';
  banks: Array<SellerBankDetail>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  userToken?: Maybe<Scalars['String']>;
};

export type SellerBlacklist = {
  __typename?: 'SellerBlacklist';
  address?: Maybe<Scalars['String']>;
  addressAdditional?: Maybe<Scalars['String']>;
  blacklistReason?: Maybe<Scalars['String']>;
  blacklistReasonDescription?: Maybe<Scalars['String']>;
  blacklistSk?: Maybe<Scalars['String']>;
  blacklistStatus?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  cityName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  director?: Maybe<Scalars['String']>;
  directorNpwp?: Maybe<Scalars['String']>;
  fiscalYear?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  inaprocId?: Maybe<Scalars['Int']>;
  institutionId?: Maybe<Scalars['String']>;
  institutionName?: Maybe<Scalars['String']>;
  isBlacklist?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  npwp?: Maybe<Scalars['String']>;
  provinceId?: Maybe<Scalars['Int']>;
  provinceName?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  repoId?: Maybe<Scalars['Int']>;
  satuanKerjaId?: Maybe<Scalars['String']>;
  satuanKerjaName?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['ID']>;
  sellerName?: Maybe<Scalars['String']>;
  tenders?: Maybe<Array<Maybe<SellerTender>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  violations?: Maybe<Array<Maybe<SellerViolation>>>;
  withdrawAt?: Maybe<Scalars['String']>;
  withdrawSk?: Maybe<Scalars['String']>;
  withdrawSkInputAt?: Maybe<Scalars['String']>;
  withdrawSkTemporary?: Maybe<Scalars['String']>;
};

export type SellerCourier = {
  __typename?: 'SellerCourier';
  dpp: Scalars['Float'];
  name: Scalars['String'];
  ppn: Scalars['Float'];
  ppnPercentage: Scalars['Float'];
  subTotal: Scalars['Float'];
};

export enum SellerCourierStatus {
  Delivered = 'DELIVERED',
  Shipping = 'SHIPPING'
}

export type SellerFilter = {
  sellerIDs?: InputMaybe<Array<Scalars['ID']>>;
  slugs?: InputMaybe<Array<Scalars['String']>>;
  userIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type SellerList = {
  __typename?: 'SellerList';
  sellers: Array<SellerMember>;
};

export type SellerMember = {
  __typename?: 'SellerMember';
  address?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<Maybe<SellerAddress>>>;
  akta?: Maybe<AktaInfo>;
  bentukUsaha?: Maybe<Scalars['String']>;
  blacklist?: Maybe<SellerBlacklist>;
  cityName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isUkm?: Maybe<Scalars['Boolean']>;
  isUmkk?: Maybe<Scalars['Boolean']>;
  izin?: Maybe<Array<Maybe<IzinInfo>>>;
  kantorCabang?: Maybe<Scalars['String']>;
  kbli?: Maybe<Array<Maybe<KbliInfo>>>;
  kswp?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  noPkp?: Maybe<Scalars['String']>;
  npwp?: Maybe<Scalars['String']>;
  pajak?: Maybe<Array<Maybe<PajakInfo>>>;
  pemilik?: Maybe<Array<Maybe<PemilikInfo>>>;
  pengalaman?: Maybe<Array<Maybe<PengalamanInfo>>>;
  pengurus?: Maybe<Array<Maybe<PengurusInfo>>>;
  peralatan?: Maybe<Array<Maybe<PeralatanInfo>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceName?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  tenagaAhli?: Maybe<Array<Maybe<TenagaAhliInfo>>>;
  updatedAt?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<SellerUser>>>;
  villageName?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type SellerPayment = {
  __typename?: 'SellerPayment';
  address: Scalars['String'];
  name: Scalars['String'];
  npwp: Scalars['String'];
};

export type SellerProductCountRes = {
  __typename?: 'SellerProductCountRes';
  active: Scalars['Int'];
  blocked: Scalars['Int'];
  curation: Scalars['Int'];
  freeze: Scalars['Int'];
  inReview: Scalars['Int'];
  inactive: Scalars['Int'];
  pending: Scalars['Int'];
  rejected: Scalars['Int'];
};

export type SellerProductCountResult = GenericError | SellerProductCountRes;

export type SellerResponse = Error | SellerMember;

export type SellerTender = {
  __typename?: 'SellerTender';
  id?: Maybe<Scalars['ID']>;
  packageId?: Maybe<Scalars['Int']>;
  sellerBlacklistId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};

export enum SellerType {
  NonUmkk = 'NON_UMKK',
  OfficialVendor = 'OFFICIAL_VENDOR',
  Umkk = 'UMKK'
}

export type SellerUser = {
  __typename?: 'SellerUser';
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sellerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SellerViolation = {
  __typename?: 'SellerViolation';
  blacklistBegin?: Maybe<Scalars['String']>;
  blacklistEnd?: Maybe<Scalars['String']>;
  blacklistPublishedAt?: Maybe<Scalars['String']>;
  blacklistSk?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sellerBlacklistId?: Maybe<Scalars['ID']>;
  violationCreatedAt?: Maybe<Scalars['String']>;
  violationDescription?: Maybe<Scalars['String']>;
  violationType?: Maybe<Scalars['String']>;
  violationTypeId?: Maybe<Scalars['Int']>;
};

export type SetAuthCodeRequest = {
  authCode: Scalars['String'];
};

export type SetBankDefaultInput = {
  bankCode?: InputMaybe<Scalars['String']>;
  bankId?: InputMaybe<Scalars['String']>;
  sellerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type SetCompanyPicTtdInput = {
  institutionId: Scalars['String'];
  userIdTarget: Scalars['String'];
};

export type SetMainCompanyBankResponse = CompanyBankInfo | GenericError;

export type SetSellerBankDefaultResponse = Error | SellerBankInfo;

export type ShipmentCheckout = {
  __typename?: 'ShipmentCheckout';
  destination: Destination;
  fee: Scalars['Float'];
  isInsurance: Scalars['Boolean'];
  items: Array<ShipmentCheckoutItem>;
  negotiatedFee: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  origin: Origin;
  rateId: Scalars['ID'];
  requestDateArrival: Scalars['Time'];
  requestDateType: CheckoutRequestDateType;
};

export type ShipmentCheckoutInput = {
  fee: Scalars['Float'];
  insuranceFee: Scalars['Float'];
  spec: ShipmentCheckoutSpec;
};

export type ShipmentCheckoutItem = {
  __typename?: 'ShipmentCheckoutItem';
  productAddOnVariantIds?: Maybe<Array<Scalars['ID']>>;
  productId: Scalars['ID'];
  productVariantId: Scalars['ID'];
  qty: Scalars['Float'];
};

export type ShipmentCheckoutItemSpec = {
  productAddOnVariantIds?: InputMaybe<Array<Scalars['ID']>>;
  productId: Scalars['ID'];
  productVariantId: Scalars['ID'];
  qty: Scalars['Float'];
};

export type ShipmentCheckoutMetadata = {
  __typename?: 'ShipmentCheckoutMetadata';
  /** @deprecated Moved to shipmentAddress */
  destinationAddress: UserAddress;
  /** @deprecated Moved to destinationAddress */
  destinationAddressId: Scalars['String'];
  isInsurance: Scalars['Boolean'];
  items: Array<ShipmentCheckoutItem>;
  notes?: Maybe<Scalars['String']>;
  originAddressId: Scalars['String'];
  rateId: Scalars['ID'];
  requestDateArrival: Scalars['Time'];
  requestDateType: CheckoutRequestDateType;
  shipmentAddress: AddressesType;
};

export type ShipmentCheckoutNegotiationInput = {
  fee: Scalars['Float'];
  insuranceFee: Scalars['Float'];
  negotiatedFee: Scalars['Float'];
  spec: ShipmentCheckoutSpec;
};

export type ShipmentCheckoutSpec = {
  buyerAddressId: Scalars['ID'];
  isInsurance: Scalars['Boolean'];
  items: Array<ShipmentCheckoutItemSpec>;
  notes?: InputMaybe<Scalars['String']>;
  rateId: Scalars['ID'];
  requestDateArrival: Scalars['Time'];
  requestDateType: CheckoutRequestDateType;
  sellerAddressId: Scalars['ID'];
};

export type ShipmentPriceChangeNotification = {
  __typename?: 'ShipmentPriceChangeNotification';
  finalPrice: Scalars['Float'];
  originalPrice: Scalars['Float'];
};

export type ShipmentTotalSummaryInput = {
  insuranceFee: Scalars['Float'];
  ppnPercentage: Scalars['Float'];
  ppnPercentageInsurance: Scalars['Float'];
  price: Scalars['Float'];
};

export type ShippingAddressBySnapshotIdResponse = GenericError | ShippingAddressBySnapshotIdResult;

export type ShippingAddressBySnapshotIdResult = {
  __typename?: 'ShippingAddressBySnapshotIdResult';
  address?: Maybe<AddressesType>;
};

export type ShippingLabelStatusResponse = {
  __typename?: 'ShippingLabelStatusResponse';
  status: Scalars['String'];
};

export type ShippingLabelStatusResult = GenericError | ShippingLabelStatusResponse;

export enum ShippingOptionType {
  Custom = 'CUSTOM',
  NoShipment = 'NO_SHIPMENT',
  Standard = 'STANDARD'
}

export type Sign = {
  __typename?: 'Sign';
  documentToken: Scalars['String'];
};

export type SignAgreementInfo = {
  __typename?: 'SignAgreementInfo';
  app?: Maybe<AppType>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  relationId?: Maybe<Scalars['String']>;
  trueClientIp?: Maybe<Scalars['String']>;
  typeSign?: Maybe<TypeSignAgreement>;
  userAgent?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SignAgreementInput = {
  app?: InputMaybe<AppType>;
  typeSign?: InputMaybe<TypeSignAgreement>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SignAgreementResponse = Error | SignAgreementInfo;

export type SignResponse = {
  __typename?: 'SignResponse';
  referenceNumber: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type SkalaUsaha = {
  __typename?: 'SkalaUsaha';
  aktaPerubahanModalTerakhir?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  laporanKeuangan?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['String']>;
  skalaUsaha?: Maybe<TSkalaUsaha>;
  sptPajakTerakhir?: Maybe<Scalars['String']>;
  suratPernyataanPenyedia?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SkalaUsahaPenyedia = {
  __typename?: 'SkalaUsahaPenyedia';
  aktaPerubahanModalTerakhirUrl: MemberFile;
  laporanKeuanganUrl: MemberFile;
  sptPajakTerakhirUrl: MemberFile;
  suratPernyataanPenyediaUrl: MemberFile;
  type?: Maybe<TSkalaUsaha>;
};

export enum SortBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export enum SortField {
  CreatedAt = 'CREATED_AT',
  OrderedAt = 'ORDERED_AT',
  Total = 'TOTAL'
}

export type Status = {
  __typename?: 'Status';
  status?: Maybe<Scalars['String']>;
};

export type StatusHistoryMetadata = {
  __typename?: 'StatusHistoryMetadata';
  cancelReason?: Maybe<Scalars['String']>;
};

export type StatusOnBoardingInfo = {
  __typename?: 'StatusOnBoardingInfo';
  currentStep?: Maybe<OnboardingStep>;
};

export type StatusOnBoardingInfoMnC = {
  __typename?: 'StatusOnBoardingInfoMnC';
  isOnboarding?: Maybe<Scalars['Boolean']>;
  rules?: Maybe<Array<Maybe<OnBoardingRuleInfo>>>;
  step?: Maybe<OnBoardingInfo>;
};

export type StatusResponse = {
  __typename?: 'StatusResponse';
  data?: Maybe<Status>;
};

export enum StockUnitType {
  Botol = 'BOTOL',
  Dus = 'DUS',
  Gelas = 'GELAS',
  Karung = 'KARUNG',
  Liter = 'LITER',
  Lusin = 'LUSIN',
  Pcs = 'PCS',
  Porsi = 'PORSI',
  SatuanUkur = 'SATUAN_UKUR'
}

export type StoreInformation = {
  __typename?: 'StoreInformation';
  createdAt?: Maybe<Scalars['Time']>;
  createdBy: Actor;
  description?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sellerId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Time']>;
  updatedBy: Actor;
};

export type StoreInformationFilterInput = {
  sellerIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type StoreInformationInput = {
  description?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['ID']>;
  imageToken?: InputMaybe<Scalars['String']>;
};

export type StoreInformationResult = Error | StoreInformations;

export type StoreInformations = {
  __typename?: 'StoreInformations';
  items?: Maybe<Array<StoreInformation>>;
};

export type StoreNote = {
  __typename?: 'StoreNote';
  content: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Actor;
  id: Scalars['ID'];
  noteType: StoreNoteType;
  sellerId: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Actor;
};

export enum StoreNoteType {
  Agreement = 'AGREEMENT',
  Notes = 'NOTES'
}

export type StoreNotesList = {
  __typename?: 'StoreNotesList';
  notes?: Maybe<Array<StoreNote>>;
};

export enum SubfeatureCategoryType {
  Negotiation = 'Negotiation',
  Order = 'Order'
}

export type Subscription = {
  __typename?: 'Subscription';
  getUploadStatus: Scalars['String'];
};


export type SubscriptionGetUploadStatusArgs = {
  input?: InputMaybe<GetUploadStatusInput>;
};

export type SyncRolesResponse = Error | Roles;

export type TFile = {
  token?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export enum TFileUpload {
  Bnt = 'BNT',
  Liveness = 'LIVENESS',
  Nib = 'NIB',
  Nik = 'NIK',
  Nip = 'NIP',
  Npwp = 'NPWP',
  Pkp = 'PKP',
  SuratKeteranganKerja = 'SURAT_KETERANGAN_KERJA',
  SuratKuasaPenyedia = 'SURAT_KUASA_PENYEDIA'
}

export enum TFileUploadMnC {
  SkalaUsahaPenyedia = 'SKALA_USAHA_PENYEDIA',
  StoreImage = 'STORE_IMAGE'
}

export type Tkdn = {
  __typename?: 'TKDN';
  bmpDate?: Maybe<Scalars['String']>;
  bmpExpirationDate?: Maybe<Scalars['String']>;
  bmpValue: Scalars['Float'];
  brand?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  hs?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  producerName: Scalars['String'];
  productType?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  specificationId: Scalars['ID'];
  standard?: Maybe<Scalars['String']>;
  status?: Maybe<ProductTkdnStatus>;
  tkdnBmp: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  value: Scalars['Float'];
};

export enum TSkalaUsaha {
  Besar = 'BESAR',
  Kecil = 'KECIL',
  Menengah = 'MENENGAH',
  Mikro = 'MIKRO'
}

export type Tax = {
  __typename?: 'Tax';
  pph22: Scalars['Float'];
  pph22Percentage: Scalars['Float'];
  pph23: Scalars['Float'];
  pph23Percentage: Scalars['Float'];
  ppn: Scalars['Float'];
  ppnLogistic: Scalars['Float'];
  ppnLogisticLs: Scalars['Float'];
  ppnLogisticLsPercentage: Scalars['Float'];
  ppnLogisticPercentage: Scalars['Float'];
  ppnLogisticProxyLs: Scalars['Float'];
  ppnLogisticProxyLsPercentage: Scalars['Float'];
  ppnLogisticProxyUp: Scalars['Float'];
  ppnLogisticProxyUpPercentage: Scalars['Float'];
  ppnLogisticUp: Scalars['Float'];
  ppnLogisticUpPercentage: Scalars['Float'];
  ppnPercentage: Scalars['Float'];
};

export type TaxResult = GenericError | Tax;

export type TenagaAhliInfo = {
  __typename?: 'TenagaAhliInfo';
  address?: Maybe<Scalars['String']>;
  auditUpdate?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  jabatan?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  npwp?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  rekanId?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  sikapCityId?: Maybe<Scalars['String']>;
  sikapCreatedAt?: Maybe<Scalars['String']>;
  sikapDistrictId?: Maybe<Scalars['String']>;
  sikapProvinceId?: Maybe<Scalars['String']>;
  sikapTAId?: Maybe<Scalars['Int']>;
  sikapVillageId?: Maybe<Scalars['String']>;
  sourceData?: Maybe<Scalars['String']>;
  specialist?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  typeId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
  userToken?: Maybe<Scalars['String']>;
  workExperience?: Maybe<Scalars['String']>;
};

export enum TingkatJabatanInternal {
  Eselon_1 = 'Eselon_1',
  Eselon_2 = 'Eselon_2',
  Eselon_3 = 'Eselon_3',
  Eselon_4 = 'Eselon_4',
  Staff = 'Staff'
}

export type Tracking = {
  __typename?: 'Tracking';
  detail?: Maybe<TrackingDetail>;
  trackings?: Maybe<Array<DeliveryOrderStatusHistory>>;
};

export type TrackingDetail = {
  __typename?: 'TrackingDetail';
  driverName?: Maybe<Scalars['String']>;
  pickupTime?: Maybe<Scalars['String']>;
  sla?: Maybe<Scalars['Int']>;
  slaMax?: Maybe<Scalars['Int']>;
  slaMin?: Maybe<Scalars['Int']>;
  vehicleNumber?: Maybe<Scalars['String']>;
};

export type TransactionLog = {
  __typename?: 'TransactionLog';
  createdAt: Scalars['String'];
  documentFile: Scalars['String'];
  documentToken: Scalars['String'];
  id: Scalars['Int'];
  info: Scalars['String'];
  orderId: Scalars['String'];
  price?: Maybe<PriceTransactionLog>;
  process: Scalars['String'];
  provider: Scalars['String'];
  referenceID: Scalars['String'];
  signUrl: Scalars['String'];
  status: Scalars['String'];
  users: Array<Maybe<UserTransactionLog>>;
};

export type TransactionLogRequest = {
  documentToken?: Scalars['String'];
  orderId?: Scalars['String'];
  referenceId?: Scalars['String'];
};

export enum TypeSignAgreement {
  Login = 'Login',
  OnBoarding = 'OnBoarding'
}

export enum UmkEnum {
  Besar = 'BESAR',
  Kecil = 'KECIL',
  Menengah = 'MENENGAH',
  Mikro = 'MIKRO'
}

export type UpdateBastNoResponse = {
  __typename?: 'UpdateBASTNoResponse';
  success: Scalars['Boolean'];
};

export type UpdateBastNoResult = GenericError | UpdateBastNoResponse;

export type UpdateBankConfigInput = {
  bankId: Scalars['ID'];
  isActive: Scalars['Boolean'];
};

export type UpdateBankConfigRes = {
  __typename?: 'UpdateBankConfigRes';
  failed?: Maybe<Array<FailedBankConfig>>;
  success?: Maybe<Array<Scalars['String']>>;
};

export type UpdateBankConfigResponse = GenericError | UpdateBankConfigRes;

export type UpdateBankStatusInput = {
  bankCode?: InputMaybe<Scalars['String']>;
  bankId?: InputMaybe<Scalars['String']>;
  sellerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBuyerAddressResponse = Error | UpdateBuyerAddressResult;

export type UpdateBuyerAddressResult = {
  __typename?: 'UpdateBuyerAddressResult';
  addressUpdate?: Maybe<UpsertBuyerAddressId>;
};

export type UpdateBuyerInstitutionInput = {
  addressSatuanKerja?: InputMaybe<Scalars['String']>;
  npwpSatuanKerja?: InputMaybe<Scalars['String']>;
  placeIdAddressSatuanKerja?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type UpdateCoaInput = {
  itemCOA?: InputMaybe<Array<ItemCoaInput>>;
  paymentId: Scalars['ID'];
};

export type UpdateCoaRes = {
  __typename?: 'UpdateCOARes';
  id: Scalars['String'];
};

export type UpdateCoaResponse = GenericError | UpdateCoaRes;

export type UpdateCategoryInput = {
  additionalInfo?: InputMaybe<Array<UpdateFormFieldInput>>;
  additionalInfoCustomFormId?: InputMaybe<Scalars['ID']>;
  allowedPpnPercentage?: InputMaybe<Array<Scalars['Float']>>;
  curationEnabled: Scalars['Boolean'];
  documentCustomFormId?: InputMaybe<Scalars['ID']>;
  documents?: InputMaybe<Array<UpdateFormFieldInput>>;
  hasVat?: InputMaybe<Scalars['Boolean']>;
  hetPriceEnabled: Scalars['Boolean'];
  icon?: InputMaybe<Scalars['String']>;
  iconToken?: InputMaybe<Scalars['String']>;
  kbki: CategoryKbkiCodesInput;
  kbli?: InputMaybe<Array<Scalars['String']>>;
  level: Scalars['Int'];
  masterProductEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  nieEnabled: Scalars['Boolean'];
  parentId?: InputMaybe<Scalars['String']>;
  ppnPercentage?: Scalars['Float'];
  primaryInfo?: InputMaybe<Array<UpdateFormFieldInput>>;
  primaryInfoCustomFormId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<CategoryType>;
  updateProductStatus?: ProductStatusUpdate;
  zoningPriceEnabled: Scalars['Boolean'];
};

export type UpdateCompanyBankStatusInput = {
  id: Scalars['String'];
  institusiId: Scalars['String'];
  status: Scalars['Boolean'];
};

export type UpdateCompanyBankStatusResponse = CompanyBankInfo | GenericError;

export type UpdateCompanyShippingAddressResponse = AddressesType | GenericError;

export type UpdateDeliveryHistorySellerCourierResponse = {
  __typename?: 'UpdateDeliveryHistorySellerCourierResponse';
  CourierName: Scalars['String'];
  DeliveryOrderId: Scalars['ID'];
  Description: Scalars['String'];
  DriverName: Scalars['String'];
  ProviderStatusCode: Scalars['Int'];
  ProviderStatusDescription: Scalars['String'];
  ProviderStatusName: Scalars['String'];
  Status: Scalars['String'];
  VehicleNumber: Scalars['String'];
};

export type UpdateDeliveryHistorySellerCourierResult = GenericError | UpdateDeliveryHistorySellerCourierResponse;

export type UpdateDocumentPaymentStatusInput = {
  documentType: DocumentType;
  paymentId: Scalars['ID'];
  status: PaymentDocumentStatus;
};

export type UpdateDocumentPaymentStatusResponse = GenericError | UpdateDocumentPaymentStatusResult;

export type UpdateDocumentPaymentStatusResult = {
  __typename?: 'UpdateDocumentPaymentStatusResult';
  isSuccess: Scalars['Boolean'];
};

export type UpdateFormFieldInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mandatory: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Int'];
  showOnLogin: Scalars['Boolean'];
  type: FormType;
};

export type UpdateInternalUserStatusResponse = Error | UpdateInternalUserStatusResult;

export type UpdateInternalUserStatusResult = {
  __typename?: 'UpdateInternalUserStatusResult';
  status?: Maybe<Scalars['String']>;
};

export type UpdateMasterProductImageInput = {
  id?: InputMaybe<Scalars['ID']>;
  imageToken?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateMasterProductInformationDetailInput = {
  customFormFieldId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateMasterProductInformationInput = {
  additionalInformations?: InputMaybe<Array<UpdateMasterProductInformationDetailInput>>;
  mainInformations?: InputMaybe<Array<UpdateMasterProductInformationDetailInput>>;
};

export type UpdateMasterProductInput = {
  brand?: InputMaybe<UpsertMasterProductBrandInput>;
  category: UpsertMasterProductCategoryInput;
  id?: InputMaybe<Scalars['ID']>;
  images: Array<UpdateMasterProductImageInput>;
  informations: UpdateMasterProductInformationInput;
  kbki: Scalars['String'];
  name: Scalars['String'];
  nationalMaximumRetailPrice?: InputMaybe<Scalars['Float']>;
  nie?: InputMaybe<UpsertMasterProductNieInput>;
  pdn: UpsertMasterProductPdnInput;
  regionPrices?: InputMaybe<Array<MasterProductRegionPriceInput>>;
  sni?: InputMaybe<UpsertMasterProductSniInput>;
  tkdn?: InputMaybe<UpsertMasterProductTkdnInput>;
  type?: InputMaybe<ProductType>;
  videoSource?: InputMaybe<VideoSourceType>;
  videoToken?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateNextOnboardingStepResponse = GenericError | GetOnboardingStatus;

export type UpdateOnBoardingInput = {
  app?: InputMaybe<AppType>;
  ruleId?: InputMaybe<Scalars['String']>;
  ruleName?: InputMaybe<Scalars['String']>;
  stepId?: InputMaybe<Scalars['String']>;
  stepName?: InputMaybe<Scalars['String']>;
};

export type UpdateOnBoardingResponse = Error | OnBoardingInfo;

export type UpdateOrderReviewerResponse = {
  __typename?: 'UpdateOrderReviewerResponse';
  orderId: Scalars['String'];
};

export type UpdatePaymentMethodConfigInput = {
  code: Scalars['ID'];
  isActive: Scalars['Boolean'];
};

export type UpdatePaymentMethodConfigRes = {
  __typename?: 'UpdatePaymentMethodConfigRes';
  failed?: Maybe<Array<FailedPaymentMethodConfig>>;
  success?: Maybe<Array<Scalars['String']>>;
};

export type UpdatePaymentMethodConfigResponse = GenericError | UpdatePaymentMethodConfigRes;

export type UpdatePaymentMethodInput = {
  PaymentMethodCode: PaymentMethodCode;
  paymentId: Scalars['ID'];
};

export type UpdatePaymentMethodRes = {
  __typename?: 'UpdatePaymentMethodRes';
  paymentId: Scalars['ID'];
  paymentStatus: PaymentStatus;
};

export type UpdatePaymentMethodResult = GenericError | UpdatePaymentMethodRes;

export type UpdatePaymentStatusWithSimulatorInput = {
  paymentId: Scalars['ID'];
};

export type UpdatePaymentStatusWithSimulatorRes = {
  __typename?: 'UpdatePaymentStatusWithSimulatorRes';
  paymentId: Scalars['ID'];
};

export type UpdatePaymentStatusWithSimulatorResponse = GenericError | UpdatePaymentStatusWithSimulatorRes;

export type UpdatePersonaShippingAddressResponse = AddressesType | GenericError;

export type UpdateProductAddOnInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  objectType?: AddOnObjectType;
  ppnPercentage?: Scalars['Float'];
  productAddOnVariants: Array<UpdateProductAddOnVariantInput>;
  type: AddOnType;
};

export type UpdateProductAddOnVariantInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type UpdateProductCategoryResult = GenericError | ProductCategory;

export type UpdateProductFromMasterProductInput = {
  masterProductId: Scalars['ID'];
  product: UpdateSpecialProductInput;
};

export type UpdateProductImageInput = {
  id?: InputMaybe<Scalars['ID']>;
  imageToken?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateProductInformationDetailInput = {
  customFormFieldId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateProductInformationInput = {
  additionalInformations?: InputMaybe<Array<UpdateProductInformationDetailInput>>;
  documents?: InputMaybe<Array<UpdateProductInformationDetailInput>>;
  mainInformations?: InputMaybe<Array<UpdateProductInformationDetailInput>>;
};

export type UpdateProductInput = {
  brand?: InputMaybe<UpsertProductBrandInput>;
  category?: InputMaybe<UpsertProductCategoryInput>;
  description?: InputMaybe<Scalars['String']>;
  images: Array<UpdateProductImageInput>;
  isActive: Scalars['Boolean'];
  kbki: Scalars['String'];
  logisticConfigurations?: InputMaybe<Array<UpsertProductLogisticConfigurationInput>>;
  name: Scalars['String'];
  nie?: InputMaybe<UpsertProductNieInput>;
  pdn: UpsertProductPdnInput;
  preOrder?: InputMaybe<UpsertProductPreOrderInput>;
  prices: UpdateProductPriceInput;
  productAddOns?: InputMaybe<Array<UpdateProductAddOnInput>>;
  productInformations: UpdateProductInformationInput;
  productOptions?: InputMaybe<Array<UpdateProductOptionInput>>;
  shipping?: InputMaybe<UpsertProductShippingInput>;
  shippingOption?: InputMaybe<ShippingOptionType>;
  sni?: InputMaybe<UpsertProductSniInput>;
  stockUnit?: InputMaybe<UpsertProductStockUnitInput>;
  tax?: UpsertProductTax;
  tkdn?: InputMaybe<UpsertProductTkdnInput>;
  type?: InputMaybe<ProductType>;
  variants: Array<UpdateProductVariantInput>;
  videoSource?: InputMaybe<VideoSourceType>;
  videoToken?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateProductOptionInput = {
  id?: InputMaybe<Scalars['ID']>;
  option: Scalars['String'];
  optionLevel: Scalars['Int'];
  valueIds?: InputMaybe<Array<Scalars['ID']>>;
  values: Array<Scalars['String']>;
};

export type UpdateProductPriceInput = {
  minPurchase: Scalars['Int'];
  productRegionPrices?: InputMaybe<Array<UpdateProductRegionPriceInput>>;
  productWholesalePrices?: InputMaybe<Array<UpdateProductWholesalePriceInput>>;
};

export type UpdateProductRegionPriceInput = {
  id?: InputMaybe<Scalars['ID']>;
  price: Scalars['Float'];
  regionCode: Scalars['String'];
};

export type UpdateProductReviewResponse = GenericError | StatusResponse;

export type UpdateProductVariantInput = {
  id?: InputMaybe<Scalars['ID']>;
  imageUrl: Scalars['String'];
  isActive: Scalars['Boolean'];
  optionValues?: InputMaybe<Array<Scalars['String']>>;
  options?: InputMaybe<Array<Scalars['String']>>;
  price: Scalars['Float'];
  sku: Scalars['String'];
  stock: Scalars['Int'];
};

export type UpdateProductWholesalePriceInput = {
  id?: InputMaybe<Scalars['ID']>;
  minQuantity: Scalars['Int'];
  price: Scalars['Float'];
};

export type UpdateRolesResponse = Error | Roles;

export type UpdateSellerAddressId = {
  __typename?: 'UpdateSellerAddressId';
  id?: Maybe<Scalars['ID']>;
};

export type UpdateSellerAddressResponse = Error | UpdateSellerAddressResult;

export type UpdateSellerAddressResult = {
  __typename?: 'UpdateSellerAddressResult';
  addressUpdate?: Maybe<UpdateSellerAddressId>;
};

export type UpdateSellerBankStatusResponse = Error | SellerBankInfo;

export type UpdateSignRequest = {
  contentType: Scalars['String'];
  filename: Scalars['String'];
};

export type UpdateSignResponse = {
  __typename?: 'UpdateSignResponse';
  jwtToken: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type UpdateSkalaUsahaRequest = {
  aktaPerubahanModalTerakhir?: InputMaybe<TFile>;
  laporanKeuangan?: InputMaybe<TFile>;
  sptPajakTerakhir?: InputMaybe<TFile>;
  suratPernyataanPenyedia?: InputMaybe<TFile>;
  type: TSkalaUsaha;
};

export type UpdateSkalaUsahaResponse = Error | UpdateSkalaUsahaResult;

export type UpdateSkalaUsahaResult = {
  __typename?: 'UpdateSkalaUsahaResult';
  result?: Maybe<MemberMessageResponse>;
};

export type UpdateSpecialProductInput = {
  description?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<UpdateProductInformationDetailInput>>;
  isActive: Scalars['Boolean'];
  logisticConfigurations?: InputMaybe<Array<UpsertProductLogisticConfigurationInput>>;
  preOrder?: InputMaybe<UpsertProductPreOrderInput>;
  prices: UpdateProductPriceInput;
  productAddOns?: InputMaybe<Array<UpdateProductAddOnInput>>;
  productOptions?: InputMaybe<Array<UpdateProductOptionInput>>;
  shipping?: InputMaybe<UpsertProductShippingInput>;
  shippingOption?: InputMaybe<ShippingOptionType>;
  stockUnit?: InputMaybe<UpsertProductStockUnitInput>;
  tax?: UpsertProductTax;
  variants: Array<UpdateProductVariantInput>;
};

export type UpdateUserAddressResponse = Error | UpdateUserAddressResult;

export type UpdateUserAddressResult = {
  __typename?: 'UpdateUserAddressResult';
  addressUpdate: UpsertUserAddressId;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  isWhatsApp?: InputMaybe<Scalars['Boolean']>;
  jabatan?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInternalInput = {
  institutionID?: InputMaybe<Scalars['String']>;
  institutionType?: InputMaybe<JenisKlpdOptionMnC>;
  jabatan?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nip?: InputMaybe<Scalars['String']>;
  roleID?: InputMaybe<Scalars['String']>;
  satkerID?: InputMaybe<Scalars['String']>;
  tingkatJabatan?: InputMaybe<TingkatJabatanInternal>;
  userID?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInternalResponse = Error | UpdateUserInternalResult;

export type UpdateUserInternalResult = {
  __typename?: 'UpdateUserInternalResult';
  institutionID?: Maybe<Scalars['String']>;
  institutionType?: Maybe<Scalars['String']>;
  jabatan?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nip?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  satkerID?: Maybe<Scalars['String']>;
  tingkatJabatan?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};

export type UpdateUserLogisticConfigurationResponse = {
  __typename?: 'UpdateUserLogisticConfigurationResponse';
  data: Array<LogisticUpdateUserLogisticConfiguration>;
};

export type UpdateUserLogisticConfigurationResult = GenericError | UpdateUserLogisticConfigurationResponse;

export type UpdateUserLogisticRegionPriceInput = {
  active: Scalars['Boolean'];
  childLevelState: Scalars['Boolean'];
  isIndeterminate: Scalars['Boolean'];
  maxSla?: InputMaybe<Scalars['Int']>;
  minSla?: InputMaybe<Scalars['Int']>;
  parentRegionCode?: InputMaybe<Scalars['String']>;
  pricePerKg: Scalars['Float'];
  regionCode?: InputMaybe<Scalars['String']>;
  regionLevel: RegionLevel;
  sla?: InputMaybe<Scalars['Int']>;
};

export type UpdateUserLogisticRegionPriceResult = GenericError | UserLogisticRegionPriceResponse;

export type UpdateUserResponse = Error | UpdateUserResult;

export type UpdateUserResult = {
  __typename?: 'UpdateUserResult';
  status?: Maybe<Scalars['String']>;
};

export type UpdateVerificationProcessInput = {
  dateOfBirth: Scalars['String'];
  isSeller: Scalars['Boolean'];
  ktpToken?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nik: Scalars['String'];
  nip?: InputMaybe<Scalars['String']>;
  selfieToken?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Profession>;
};

export type UploadAccountRequest = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  identifier: Scalars['String'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  type: TFileUpload;
};

export type UploadAccountResp = {
  __typename?: 'UploadAccountResp';
  identifier: Scalars['String'];
  signedUrl: Scalars['String'];
  token: Scalars['String'];
};

export type UploadAccountResponse = GenericError | UploadAccountSuccess;

export type UploadAccountSuccess = {
  __typename?: 'UploadAccountSuccess';
  result?: Maybe<Array<UploadAccountResp>>;
};

export type UploadChatFileInput = {
  channelUrl: Scalars['String'];
  parentMessageId?: InputMaybe<Scalars['String']>;
  token: Array<Scalars['String']>;
};

export type UploadChatFileResp = {
  __typename?: 'UploadChatFileResp';
  result: Array<UploadChatFileResult>;
};

export type UploadChatFileResponse = GenericError | UploadChatFileResp;

export type UploadChatFileResult = {
  __typename?: 'UploadChatFileResult';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UploadChatUrlInput = {
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
};

export type UploadChatUrlResp = {
  __typename?: 'UploadChatUrlResp';
  jwtToken: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type UploadChatUrlResponse = GenericError | UploadChatUrlResp;

export type UploadDocumentPaymentInput = {
  contentType: Scalars['String'];
  documentNumber: Scalars['String'];
  documentType: DocumentType;
  fileName: Scalars['String'];
  paymentId: Scalars['ID'];
};

export type UploadDocumentPaymentResponse = GenericError | UploadDocumentPaymentResult;

export type UploadDocumentPaymentResult = {
  __typename?: 'UploadDocumentPaymentResult';
  documentId: Scalars['ID'];
  expiry: Scalars['Int'];
  identifier: Scalars['String'];
  paymentId: Scalars['ID'];
  signedUrl: Scalars['String'];
  token: Scalars['String'];
};

export type UploadMemberRequest = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  identifier?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  type: TFileUploadMnC;
};

export type UploadMemberResp = {
  __typename?: 'UploadMemberResp';
  identifier: Scalars['String'];
  jwtToken: Scalars['String'];
  signedUrl: Scalars['String'];
  token: Scalars['String'];
};

export type UploadMemberSuccess = {
  __typename?: 'UploadMemberSuccess';
  result?: Maybe<Array<UploadMemberResp>>;
};

export type UploadSignedUrlInput = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  identifier: Scalars['String'];
  isPublic: Scalars['Boolean'];
};

export enum UploadType {
  CategoryIconImage = 'CATEGORY_ICON_IMAGE',
  MasterProductImage = 'MASTER_PRODUCT_IMAGE',
  MasterProductVideo = 'MASTER_PRODUCT_VIDEO',
  ProductBulkAction = 'PRODUCT_BULK_ACTION',
  ProductDocument = 'PRODUCT_DOCUMENT',
  ProductImage = 'PRODUCT_IMAGE',
  ProductVideo = 'PRODUCT_VIDEO'
}

export type UploadmemberResponse = Error | UploadMemberSuccess;

export type UpsertBuyerAddressId = {
  __typename?: 'UpsertBuyerAddressId';
  id?: Maybe<Scalars['ID']>;
};

export type UpsertMasterProductBrandInput = {
  applicationNumber: Scalars['String'];
};

export type UpsertMasterProductCategoryInput = {
  id: Scalars['ID'];
};

export type UpsertMasterProductNieInput = {
  nie: Scalars['String'];
};

export type UpsertMasterProductPdnInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  laborType: PdnDetailType;
  locationType: PdnDetailType;
  materialType: PdnDetailType;
  type: PdnType;
};

export type UpsertMasterProductSniInput = {
  brand: Scalars['String'];
  certificateNumber: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  sniNumber: Scalars['String'];
  spptNumber?: InputMaybe<Scalars['String']>;
};

export type UpsertMasterProductTkdnInput = {
  number?: InputMaybe<Scalars['String']>;
  specificationId?: InputMaybe<Scalars['String']>;
};

export type UpsertProductBrandInput = {
  applicationNumber: Scalars['String'];
};

export type UpsertProductCategoryInput = {
  id: Scalars['ID'];
};

export type UpsertProductLogisticConfigurationInput = {
  active: Scalars['Boolean'];
  rateId: Scalars['ID'];
};

export type UpsertProductNieInput = {
  nie: Scalars['String'];
};

export type UpsertProductPdnInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  laborType: PdnDetailType;
  locationType: PdnDetailType;
  materialType: PdnDetailType;
  type: PdnType;
};

export type UpsertProductPreOrderInput = {
  sla: Scalars['Int'];
};

export type UpsertProductSniInput = {
  brand: Scalars['String'];
  certificateNumber: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  sniNumber: Scalars['String'];
  spptNumber?: InputMaybe<Scalars['String']>;
};

export type UpsertProductShippingInput = {
  dimensionUnit?: ProductDimensionUnit;
  hasSellerShipping: Scalars['Boolean'];
  height: Scalars['Float'];
  length: Scalars['Float'];
  weight: Scalars['Float'];
  weightUnit?: ProductWeightUnit;
  width: Scalars['Float'];
};

export type UpsertProductStockUnitInput = {
  primaryUnit: StockUnitType;
  secondaryUnit?: InputMaybe<StockUnitType>;
  value?: InputMaybe<Scalars['Int']>;
};

export type UpsertProductTkdnInput = {
  number?: InputMaybe<Scalars['String']>;
  specificationId?: InputMaybe<Scalars['String']>;
};

export type UpsertProductTax = {
  ppnBmId?: InputMaybe<Scalars['ID']>;
  ppnBmType?: InputMaybe<PpnBmType>;
  ppnPercentage?: Scalars['Float'];
};

export type UpsertStoreInformationResult = Error | StoreInformation;

export type UpsertStoreNoteInput = {
  content: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  noteType: StoreNoteType;
  title: Scalars['String'];
};

export type UpsertStoreNotesResponse = Error | StoreNotesList;

export type UpsertUserAddressId = {
  __typename?: 'UpsertUserAddressId';
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  addresses?: Maybe<Array<UserAddress>>;
  authProviders?: Maybe<Array<Maybe<AuthProvider>>>;
  authenticateStatus?: Maybe<Array<Maybe<AuthenticateStatus>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastLogin?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  loginsCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameHash?: Maybe<Scalars['String']>;
  onBoarding?: Maybe<StatusOnBoardingInfoMnC>;
  phone?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  userToken?: Maybe<Scalars['String']>;
};

export enum UserAccessOpt {
  AdminAgency = 'AdminAgency',
  AdminPpe = 'AdminPPE',
  AnggotaPokmil = 'AnggotaPokmil',
  Auditor = 'Auditor',
  Helpdesk = 'Helpdesk',
  Kupbj = 'KUPBJ',
  Pp = 'PP',
  Ppk = 'PPK',
  Verifikator = 'Verifikator'
}

export type UserAddress = {
  __typename?: 'UserAddress';
  createdAt: Scalars['String'];
  fullAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isMainAddress?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumberMember>;
  postalCode?: Maybe<Scalars['String']>;
  receiverName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  userId: Scalars['String'];
  villageAreaCode?: Maybe<Scalars['String']>;
};

export type UserAddressInput = {
  fullAddress?: InputMaybe<Scalars['String']>;
  isMainAddress?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  receiverName?: InputMaybe<Scalars['String']>;
  villageAreaCode?: InputMaybe<Scalars['String']>;
};

export type UserDataType = {
  __typename?: 'UserDataType';
  agcId?: Maybe<Scalars['Int']>;
  idkpaSirup?: Maybe<Scalars['Int']>;
  jenisSirup?: Maybe<Scalars['Int']>;
  legacyRole?: Maybe<Scalars['String']>;
  legacyUserId?: Maybe<Scalars['Int']>;
  legacyUsername?: Maybe<Scalars['String']>;
  lpseId?: Maybe<Scalars['Int']>;
  noskSirup?: Maybe<Scalars['String']>;
  nrp?: Maybe<Scalars['String']>;
  pegGolongan?: Maybe<Scalars['String']>;
  pegJabatan?: Maybe<Scalars['String']>;
  satkerId?: Maybe<Scalars['Int']>;
  subsatkerId?: Maybe<Scalars['Int']>;
};

export type UserFilter = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userTokens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  auth0UserID?: Maybe<Scalars['String']>;
  authProviders?: Maybe<Array<Maybe<AuthProvider>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isSeller?: Maybe<Scalars['Boolean']>;
  login?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nikHashed?: Maybe<Scalars['String']>;
  nikToken?: Maybe<Scalars['String']>;
  nipHashed?: Maybe<Scalars['String']>;
  nipToken?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileInfo>;
  profileStatus?: Maybe<ProfileStatus>;
  status?: Maybe<UserStatus>;
  type?: Maybe<Profession>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedIP?: Maybe<Scalars['String']>;
  updatedUserAgent?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type UserInternal = {
  __typename?: 'UserInternal';
  createdBy?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  institutionID?: Maybe<Scalars['String']>;
  institutionType?: Maybe<Scalars['String']>;
  jabatan?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nip?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  satkerID?: Maybe<Scalars['String']>;
  tingkatJabatan?: Maybe<Scalars['String']>;
};

export type UserInternalResendVerificationResponse = Error | UserInternalResendVerificationResult;

export type UserInternalResendVerificationResult = {
  __typename?: 'UserInternalResendVerificationResult';
  status?: Maybe<Scalars['String']>;
};

export type UserList = {
  __typename?: 'UserList';
  users?: Maybe<Array<Maybe<User>>>;
};

export type UserLogisticConfiguration = {
  __typename?: 'UserLogisticConfiguration';
  code: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isShown: Scalars['Boolean'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  rates?: Maybe<Array<LogisticRateConfiguration>>;
};

export type UserLogisticConfigurationInput = {
  active: Scalars['Boolean'];
  rateId: Scalars['ID'];
};

export type UserLogisticConfigurationResponse = {
  __typename?: 'UserLogisticConfigurationResponse';
  data?: Maybe<Array<UserLogisticConfiguration>>;
};

export type UserLogisticConfigurationResult = GenericError | UserLogisticConfigurationResponse;

export type UserLogisticRegionPrice = {
  __typename?: 'UserLogisticRegionPrice';
  active: Scalars['Boolean'];
  childLevelState: Scalars['Boolean'];
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isIndeterminate: Scalars['Boolean'];
  maxSla: Scalars['Int'];
  minSla: Scalars['Int'];
  parentRegionCode: Scalars['String'];
  pricePerKg: Scalars['Float'];
  regionCode: Scalars['String'];
  regionLevel: RegionLevel;
  sla: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type UserLogisticRegionPriceResponse = {
  __typename?: 'UserLogisticRegionPriceResponse';
  data: Array<UserLogisticRegionPrice>;
};

export type UserLogisticRegionPriceResult = GenericError | UserLogisticRegionPriceResponse;

export type UserLogisticRegionPriceWithRegionInfo = {
  __typename?: 'UserLogisticRegionPriceWithRegionInfo';
  active: Scalars['Boolean'];
  childLevelState: Scalars['Boolean'];
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isIndeterminate: Scalars['Boolean'];
  parentRegionCode: Scalars['String'];
  pricePerKg: Scalars['Float'];
  regionCode: Scalars['String'];
  regionInfo: Region;
  regionLevel: RegionLevel;
  slaMax: Scalars['Int'];
  slaMin: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type UserLogisticRegionPriceWithRegionInfoResponse = {
  __typename?: 'UserLogisticRegionPriceWithRegionInfoResponse';
  currentPage: Scalars['Int'];
  data: Array<UserLogisticRegionPriceWithRegionInfo>;
  lastPage: Scalars['Int'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
};

export type UserLogisticRegionPriceWithRegionInfoResult = GenericError | UserLogisticRegionPriceWithRegionInfoResponse;

export type UserPayment = {
  __typename?: 'UserPayment';
  name: Scalars['String'];
  nip: Scalars['String'];
};

export type UserPersonaNonPenyedia = {
  __typename?: 'UserPersonaNonPenyedia';
  appId: Scalars['String'];
  id: Scalars['String'];
  institusiId: Scalars['String'];
  institusiMemberId: Scalars['String'];
  role: NonPenyediaRoleEnum;
  satkerKodeAdj: Scalars['String'];
  satkerName: Scalars['String'];
  userRoleId: Scalars['String'];
};

export type UserPersonaPenyedia = {
  __typename?: 'UserPersonaPenyedia';
  appId: Scalars['String'];
  companyId: Scalars['String'];
  companyName: Scalars['String'];
  id: Scalars['String'];
  institusiId: Scalars['String'];
  institusiMemberId: Scalars['String'];
  role: PenyediaRoleEnum;
  userRoleId: Scalars['String'];
};

export type UserResponse = Error | User;

export enum UserStatus {
  AsyncPending = 'ASYNC_PENDING',
  EmailSubmitted = 'EMAIL_SUBMITTED',
  EmailVerified = 'EMAIL_VERIFIED',
  PhoneVerified = 'PHONE_VERIFIED'
}

export type UserTransactionLog = {
  __typename?: 'UserTransactionLog';
  createdAt: Scalars['String'];
  providerUserId: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['String'];
};

export type VaList = {
  __typename?: 'VAList';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  maxAmount: Scalars['Float'];
  minAmount: Scalars['Float'];
  name: Scalars['String'];
  reason: Scalars['String'];
};

export enum VerificationStatus {
  Accepted = 'ACCEPTED',
  HardReject = 'HARD_REJECT',
  SoftReject = 'SOFT_REJECT',
  Waiting = 'WAITING'
}

export type VerificationStatusSuccess = {
  __typename?: 'VerificationStatusSuccess';
  ipAddress: Scalars['String'];
  reason?: Maybe<Array<Maybe<RejectReason>>>;
  status: VerificationStatus;
  updatedAt: Scalars['String'];
  userId: Scalars['String'];
  verificationRequestId: Scalars['String'];
  verificationType: VerificationType;
  verificatorScheduleId: Scalars['String'];
};

export enum VerificationType {
  Persona = 'PERSONA',
  User = 'USER'
}

export enum VideoSourceType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

export type VirtualAccountAvailabilityRes = {
  __typename?: 'VirtualAccountAvailabilityRes';
  virtualAccounts: Array<VaList>;
};

export type VirtualAccountAvailabilityResponse = GenericError | VirtualAccountAvailabilityRes;

export type WhitelistedInstanceIdList = {
  __typename?: 'WhitelistedInstanceIDList';
  instanceIds?: Maybe<Array<Scalars['ID']>>;
};

export type WhitelistedInstanceIdResponse = Error | WhitelistedInstanceIdList;

export type WhitelistedUserAccessList = {
  __typename?: 'WhitelistedUserAccessList';
  userAccesses?: Maybe<Array<UserAccessOpt>>;
};

export type WhitelistedUserAccessResponse = Error | WhitelistedUserAccessList;

export type CheckNibResponse = CheckNibType | GenericError;

export enum ContractEsignStatus {
  Aktif = 'AKTIF',
  BelumAdaStatus = 'BELUM_ADA_STATUS',
  BelumTerdaftar = 'BELUM_TERDAFTAR',
  Dicabut = 'DICABUT',
  Ditolak = 'DITOLAK',
  Kedaluwarsa = 'KEDALUWARSA',
  MenungguAktivasi = 'MENUNGGU_AKTIVASI'
}

export enum ContractProcess {
  Meterai = 'meterai',
  Sign = 'sign'
}

export enum ContractProvider {
  Bsre = 'bsre',
  Privy = 'privy'
}

export type CreateBuyerInstitutionInput = {
  kodeSatuanKerjaAdj: Scalars['String'];
  unit: Scalars['String'];
};

export type DeliveryOrderStatusHistoryResult = DeliveryOrderStatusHistoryResponse | GenericError;

export type ESignBastInput = {
  deliveryOrderId: Scalars['String'];
  passphrase?: InputMaybe<Scalars['String']>;
  role: Role;
};

export type ESignBastResponse = {
  __typename?: 'eSignBASTResponse';
  referenceNumber: Scalars['String'];
  signingUrl: Scalars['String'];
};

export type ESignBastResult = GenericError | ESignBastResponse;

export type InvoicePaymentDetail = {
  __typename?: 'invoicePaymentDetail';
  address: Scalars['String'];
  bankBranch: Scalars['String'];
  bankName: Scalars['String'];
  vaNumber: Scalars['String'];
};

export type OrderStatusHistorySummaryResult = GenericError | OrderStatusHistorySummaryResponse;

export type PickUpInput = {
  deliveryOrderId: Scalars['String'];
  requestPickupTime?: InputMaybe<Scalars['String']>;
};

export type SearchPersonaNonPenyediaResponse = GenericError | PersonaNonPenyediaPage;

export type UpdateDeliveryHistorySellerCourierInput = {
  courier?: InputMaybe<Courier>;
  deliveryOrderId: Scalars['String'];
  proofOfDelivery?: InputMaybe<Scalars['String']>;
  receivedBy?: InputMaybe<Scalars['String']>;
  status: SellerCourierStatus;
  statusCreatedAt?: InputMaybe<Scalars['String']>;
};

export type UploadDocumentPaymentWithReasonInput = {
  contentType: Scalars['String'];
  documentNumber: Scalars['String'];
  documentType: DocumentType;
  fileName: Scalars['String'];
  paymentId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  uploadDocument: Scalars['Boolean'];
};

export enum UserRole {
  Buyer = 'buyer',
  Seller = 'seller'
}

export type UploadChatUrlMutationVariables = Exact<{
  input: UploadChatUrlInput;
}>;


export type UploadChatUrlMutation = { __typename?: 'Mutation', uploadChatUrl: { __typename: 'GenericError', reqId: string, code: string, message: string } | { __typename: 'UploadChatUrlResp', uploadUrl: string, jwtToken: string } };

export type UploadChatFileMutationVariables = Exact<{
  input: UploadChatFileInput;
}>;


export type UploadChatFileMutation = { __typename?: 'Mutation', uploadChatFile: { __typename: 'GenericError', reqId: string, code: string, message: string } | { __typename: 'UploadChatFileResp', result: Array<{ __typename: 'UploadChatFileResult', success: boolean, message?: string | null }> } };

export type GetInstitutionByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetInstitutionByIdQuery = { __typename?: 'Query', getInstitutionByID: { __typename: 'GenericError', reqId: string, code: string, message: string } | { __typename: 'Institution', company?: { __typename?: 'CompanyType', name?: string | null, slug?: string | null } | null } };


export const UploadChatUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadChatUrl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UploadChatUrlInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadChatUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UploadChatUrlResp"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadUrl"}},{"kind":"Field","name":{"kind":"Name","value":"jwtToken"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GenericError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reqId"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]}}]} as unknown as DocumentNode<UploadChatUrlMutation, UploadChatUrlMutationVariables>;
export const UploadChatFileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadChatFile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UploadChatFileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadChatFile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UploadChatFileResp"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GenericError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reqId"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]}}]} as unknown as DocumentNode<UploadChatFileMutation, UploadChatFileMutationVariables>;
export const GetInstitutionByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInstitutionById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getInstitutionByID"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Institution"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GenericError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reqId"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]}}]} as unknown as DocumentNode<GetInstitutionByIdQuery, GetInstitutionByIdQueryVariables>;