import type {
  AdminMessage,
  FileMessage,
  UserMessage,
} from "@sendbird/chat/message"
import { formatDate } from "../../utils/common"

interface ChannelSeparator {
  message: AdminMessage | UserMessage | FileMessage
}

const ChannelSeparator = ({ message }: ChannelSeparator) => {
  const date = new Date(message.createdAt)
  return (
    <div className="my-3 flex">
      <span className="rounded-4 border-tertiary50 bg-primary25 text-tertiary500 mx-auto border px-2 py-[6px] text-xs font-semibold">
        {formatDate(date)}
      </span>
    </div>
  )
}

export default ChannelSeparator
