import { array, literal, number, object, string, union } from "zod"

export const productMessageSchema = object({
  product: object({
    name: string().optional(),
    imageUrl: string().url().optional(),
    price: number().optional(),
    slug: string().optional(),
    sellerSlug: string().optional(),
  }),
})

export const orderMessageSchema = object({
  orderId: string().optional(),
  orderNumber: string().optional(),
  orderPrice: number().optional(),
  orderKey: string().optional(),
  productData: object({
    name: string().optional(),
    imageUrl: string().url().optional(),
    price: number().optional(),
  }),
  type: union([
    literal("negotiation"),
    literal("order"),
    literal("review"),
    literal("negotiation-review"),
  ]).optional(),
  negotiationId: string().optional(),
})

export const fileMessageSchema = object({
  fileName: string(),
  fileSize: number(),
  mimeType: string(),
})

export const negotiationStatusSchema = union([
  literal("BUYER_REQUEST"),
  literal("SELLER_NEW_REQUEST"),
  literal("BUYER_NEW_REQUEST"),
  literal("BUYER_APPROVED"),
  literal("SELLER_APPROVED"),
  literal("SELLER_REJECTED"),
  literal("NEGO_CANCELED"),
])

export const negotiationItemStatusSchema = union([
  literal("ACCEPTED"),
  literal("REJECTED"),
  literal("NEW_REQUEST"),
])

export const productSchema = object({
  name: string(),
  imageUrl: string(),
  negotationStatus: negotiationItemStatusSchema,
  initialPrice: number(),
  lastOffers: number(),
  additionalService: object({
    warranty: object({
      negotationStatus: negotiationItemStatusSchema,
      initialPrice: number(),
      lastOffers: number(),
    }).optional(),
    installation: object({
      negotationStatus: negotiationItemStatusSchema,
      initialPrice: number(),
      lastOffers: number(),
    }).optional(),
  }).optional(),
})

export const negotiationMessageSchema = object({
  status: negotiationStatusSchema,
  products: array(productSchema),
  initialPrice: number(),
  lastOffers: number(),
})
