import React, { useEffect } from "react"
import { cx } from "class-variance-authority"

const ChannelListEmpty = ({
  hasSearch,
  setIsEmptyChannel,
  showOneInstance,
}: {
  hasSearch: boolean
  setIsEmptyChannel?: React.Dispatch<React.SetStateAction<boolean>>
  showOneInstance?: boolean
}) => {
  useEffect(() => {
    if (setIsEmptyChannel) {
      setIsEmptyChannel(true)
    }
  }, [])
  if (hasSearch)
    return (
      <div className={cx("px-4 text-center", showOneInstance && "absolute")}>
        <h1 className="text-tertiary500 font-bold">
          Maaf, hasil tidak ditemukan
        </h1>
        <p className="text-tertiary300 mt-1 text-sm">
          Coba cari dengan kata kunci lain
        </p>
      </div>
    )

  return (
    <div className="px-4 text-center">
      <h1 className="text-tertiary500 font-bold">
        Anda Belum Memiliki Percakapan
      </h1>
      <p className="text-tertiary300 mt-2 text-sm">
        Mulai percakapan dengan penyedia/pembeli di sini
      </p>
    </div>
  )
}

export default ChannelListEmpty
