// Message Validation
const maxMessageLength = 4000

// File validation
const maxFileSize = 5242880
const maxFileSizeMb = maxFileSize / 1048576
const fileTypes = ["pdf", "doc", "docx", "xls", "xlsx"]
const imageTypes = ["png", "jpg", "jpeg"]
const maxFilePixels = 10000 * 10000

const config = {
  maxMessageLength,
  maxFileSize,
  maxFileSizeMb,
  fileTypes,
  imageTypes,
  maxFilePixels,
}

export default config
