import {
  type AdminMessage,
  type FileMessage,
  type UserMessage,
} from "@sendbird/chat/message"

export type CustomMessageType =
  | "PRODUCT"
  | "NEGOTIATION"
  | "SENSITIVE"
  | "ORDER"
  | "TEXT"
  | "FILE"
  | "SENSITIVE_URL"
  | "SENSITIVE_IMAGE"
  | "HIDDEN"
  | null

export const getCustomMessageType = (
  message: AdminMessage | UserMessage | FileMessage
): CustomMessageType => {
  const CustomMessageType = {
    product: "PRODUCT",
    negotiation: "NEGOTIATION",
    sensitive: "SENSITIVE",
    order: "ORDER",
    sensitiveUrl: "SENSITIVE_URL",
    sensitiveImage: "SENSITIVE_IMAGE",
    hidden: "HIDDEN",
  }

  if (message.isUserMessage?.()) {
    switch (message.customType) {
      case CustomMessageType.product:
      case CustomMessageType.negotiation:
      case CustomMessageType.sensitive:
      case CustomMessageType.order:
      case CustomMessageType.sensitiveUrl:
      case CustomMessageType.sensitiveImage:
      case CustomMessageType.hidden:
        return message.customType as CustomMessageType
      default:
        if (message.messageType === "file") {
          return "FILE"
        } else {
          return "TEXT"
        }
    }
  }

  if (message.messageType === "file") {
    return "FILE"
  } else {
    return "TEXT"
  }
}
