type AppType = {
  buyer: "BUYER"
  seller: "SELLER"
  admin: "ADMIN"
  account: "ACCOUNT"
  internal: "INTERNAL"
}

export const APP_TYPE: AppType = {
  buyer: "BUYER",
  seller: "SELLER",
  admin: "ADMIN",
  account: "ACCOUNT",
  internal: "INTERNAL",
}
