export const getTimeDifference = (date: Date) => {
  const now = new Date()
  const differenceInSeconds = Math.floor(
    (now.getTime() - date.getTime()) / 1000
  )
  const minute = 60
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7

  switch (true) {
    case differenceInSeconds < minute:
      return "Baru saja"
    case differenceInSeconds < hour:
      const minutesAgo = Math.floor(differenceInSeconds / minute)
      return `${minutesAgo} menit yang lalu`
    case differenceInSeconds < day:
      const hoursAgo = Math.floor(differenceInSeconds / hour)
      return `${hoursAgo} jam yang lalu`
    case differenceInSeconds < week:
      const daysAgo = Math.floor(differenceInSeconds / day)
      return `${daysAgo} hari yang lalu`
    default:
      return date.toLocaleDateString()
  }
}
