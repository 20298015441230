import jwtDecode from "jwt-decode"
import { getCookie, deleteCookie } from "cookies-next"
import {
  EXPIRED_TIME_TOKEN_COOKIE_NAME,
  TSession,
  USER_INFO_COOKIE_NAME,
} from "../type"
import { accountSchema, govmartSchema } from "./schema"
import { OptionsType } from "cookies-next/lib/types"

/**
 * Note: `maxAge` or `expires`.
 * That attribute for cookies is not implemented.
 * When a cookie expires (which is determined by the `expires` attribute), it is automatically deleted by the browser. This deletion triggers a page refresh.
 * This approach provides more control over when the page refresh is triggered.
 */
export const cookieCSROptions: OptionsType = {
  secure: true,
  sameSite: "lax",
  path: "/",
}

export const getUserInfoCookie = (props?: OptionsType) => {
  try {
    const cookie = getCookie(USER_INFO_COOKIE_NAME, {
      ...cookieCSROptions,
      ...props,
    }) as string
    return cookie
  } catch (e) {
    return null
  }
}

/**
 * type of getAccountSession compatible with the Account Application.
 * @return {Object} accountSchema
 */
export const getAccountUserInfo = () => {
  try {
    const cookie = getUserInfoCookie()
    const tokenSession = cookie
      ? (JSON.parse(jwtDecode(cookie)) as TSession)
      : null
    return tokenSession && tokenSession?.user
      ? accountSchema.parse(tokenSession)
      : null
  } catch (e) {
    console.error("Error:", e)
    deleteCookie(USER_INFO_COOKIE_NAME)
    return null
  }
}

/**
 * type of getSession compatible with the Govmart Application.
 * Govmart Application (Buyer, Seller, Internal)
 * @return {Object} govmartSchema
 */
export const getUserInfo = () => {
  try {
    const cookie = getUserInfoCookie()
    const tokenSession = cookie
      ? (JSON.parse(jwtDecode(cookie)) as TSession)
      : null
    return tokenSession && tokenSession.user
      ? govmartSchema.parse(tokenSession)
      : null
  } catch (e) {
    console.error("Error:", "Invalid token session")
    deleteCookie(USER_INFO_COOKIE_NAME)
    return null
  }
}

export const getExpiredTimeToken = getCookie(EXPIRED_TIME_TOKEN_COOKIE_NAME, {
  ...cookieCSROptions,
}) as string as unknown as number

export const clearUserInfo = () => {
  deleteCookie(USER_INFO_COOKIE_NAME)
}
