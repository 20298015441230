import { type GenericExtractor } from "shared-utils"
import { federatedGqlClient } from "../utils/graphqlClient"

import { GetInstitutionByIdQuery } from "../federatedGql/graphql"
import { GET_INSTITUTION_BY_ID } from "../graphql/queries"

export type TGetInstitutionById = GenericExtractor<
  GetInstitutionByIdQuery["getInstitutionByID"]
>

export const useGetInstitutionById = async ({ id }: { id: string }) => {
  const data = await federatedGqlClient.request(GET_INSTITUTION_BY_ID, {
    id,
  })

  if (data?.getInstitutionByID?.__typename === "Institution") {
    return data.getInstitutionByID as TGetInstitutionById
  }

  throw new Error("Error Institution")
}
