import React, { useRef } from "react"
import { Search } from "react-feather"

interface IInputSearchProps {
  placeholder: string
  onChange: (value: string) => void
  isLabelShown: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
}

const InputSearch = ({
  placeholder,
  onChange,
  isLabelShown,
  onClick,
}: IInputSearchProps) => {
  const searchChannelRef = useRef<HTMLInputElement>(null)
  return (
    <div
      className="rounded-4 border-tertiary50 flex w-full items-center border px-3"
      onClick={(e) => {
        onClick(e)
        if (!isLabelShown)
          setTimeout(() => searchChannelRef?.current?.focus(), 250)
      }}
    >
      <Search size={20} />
      {isLabelShown && (
        <input
          ref={searchChannelRef}
          className="ml-2 w-full flex-grow text-ellipsis break-words text-sm outline-none"
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  )
}

export default InputSearch
