import dynamic from "next/dynamic"
import { type ReactNode } from "react"

const SBProvider = dynamic(() => import("./SBProvider"), {
  ssr: false,
})

const SendbirdWrapper = ({
  children,
  appId,
  userId,
  name,
}: {
  children: ReactNode
  appId: string
  userId: string
  name: string
}) => (
  <SBProvider appId={appId} userId={userId} name={name}>
    {children}
  </SBProvider>
)

export default SendbirdWrapper
