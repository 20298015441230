import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface IPersonaNotification {
  userData: {
    personaId: string
    institutionId: string
    satker: string
    role: string
  }
  notifData: {
    personaId: string
    satker: string
    role: string
  }
  openModal: boolean
  setUser: (userData: IPersonaNotification["userData"]) => void
  setOpenModal: (
    openModal: boolean,
    notifData: IPersonaNotification["notifData"]
  ) => void
}

const usePersonaNotification = create<IPersonaNotification>()(
  devtools(
    (set) => ({
      userData: {
        personaId: "",
        institutionId: "",
        satker: "",
        role: "",
      },
      notifData: {
        personaId: "",
        satker: "",
        role: "",
      },
      openModal: false,
      setUser: (userData) => set(() => ({ userData })),
      setOpenModal: (openModal, notifData) =>
        set(() => ({ openModal, notifData })),
    }),
    {
      name: "persona-notification-storage",
    }
  )
)

export default usePersonaNotification
