import { graphql } from "../federatedGql"

export const GET_INSTITUTION_BY_ID = graphql(`
  query GetInstitutionById($id: String!) {
    getInstitutionByID(id: $id) {
      ... on Institution {
        __typename
        company {
          name
          slug
        }
      }
      ... on GenericError {
        reqId
        code
        message
        __typename
      }
    }
  }
`)
