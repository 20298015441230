import type {
  AdminMessage,
  FileMessage,
  UserMessage,
} from "@sendbird/chat/message"
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from "@sendbird/uikit-react"
import type { GetSdk } from "SendbirdUIKitGlobal"

export const CustomMessageType = {
  PRODUCT: "PRODUCT",
  NEGOTIATION: "NEGOTIATION",
  SENSITIVE: "SENSITIVE",
  ORDER: "ORDER",
  SENSITIVE_URL: "SENSITIVE_URL",
  SENSITIVE_IMAGE: "SENSITIVE_IMAGE",
  HIDDEN: "HIDDEN",
}

export const useSendbirdSdk = (): GetSdk => {
  const context = useSendbirdStateContext()
  return sendbirdSelectors.getSdk(context)
}

export const useCurrentUser = () => {
  const sdk = useSendbirdSdk()
  return sdk?.currentUser
}

export const profanityErrorCode = 900060
export const bannedUrlErrorCode = 900065

type MessageType = AdminMessage | UserMessage | FileMessage

export const isUserMessage = (message: MessageType) =>
  message.isUserMessage?.() || message.messageType === "user"

export const isFileMessage = (message: MessageType) =>
  message.isFileMessage?.() || message.messageType === "file"

export const isAdminMessage = (message: MessageType) =>
  message.isAdminMessage?.() || message.messageType === "admin"

export const isProductMessage = (message: MessageType) =>
  message.isUserMessage?.() && message.customType === CustomMessageType.PRODUCT

export const isOrderMessage = (message: MessageType) =>
  message.isUserMessage?.() && message.customType === CustomMessageType.ORDER

export const isSensitiveMessage = (message: MessageType) =>
  message.isUserMessage?.() &&
  message.customType === CustomMessageType.SENSITIVE

export const isSensitiveUrlMessage = (message: MessageType) =>
  message.isUserMessage?.() &&
  message.customType === CustomMessageType.SENSITIVE_URL

export const isNegotiationMessage = (message: MessageType) =>
  message.isUserMessage?.() &&
  message.customType === CustomMessageType.NEGOTIATION
