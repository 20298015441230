import { cx } from 'class-variance-authority'
import type { FC } from 'react'
import { TSkeletonProps } from './Skeleton.type'

const Skeleton: FC<TSkeletonProps> = ({
  variant = 'text',
  animation = true,
  width = 'w-full',
  height,
  bgColor = 'bg-tertiary200',
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const skeletonStyles = cx({
    'rounded-1': variant === 'text',
    'rounded-full': variant === 'circular',
    'rounded-none': variant === 'rectangular',
    'rounded-3': variant === 'rounded',
  })

  const skeletonAnimation = animation ? 'animate-pulse' : ''
  const skeletonHeight = (): string => {
    if (height) return height
    else if (children && !height) return 'h-auto'
    return 'h-full'
  }

  return (
    <div data-test="skeleton" className={cx(bgColor, width, skeletonHeight(), skeletonStyles, skeletonAnimation)}>
      {children}
    </div>
  )
}

export default Skeleton
