"use client"

import Image from "next/image"
import React, { type MouseEvent, type RefObject } from "react"
import { Plus, XCircle } from "react-feather"

import { SelectedOrder, SelectedProduct } from "../../store/chatStore"
import ChannelFilesPreviewUpload from "./ChannelFilesPreviewUpload"
import { MAX_FILE_IN_SINGLE_CHAT_UPLOAD } from "../../utils/common"

interface IChatFIle {
  files?: FileList
  inputRef: RefObject<HTMLInputElement>
  onRemoveFile: (id: string, e?: MouseEvent<HTMLButtonElement>) => void
  onAddedFileToken: (token: string) => void
  selectedProductToAsk?: SelectedProduct | null
  onRemoveProduct: () => void
  order: SelectedOrder | null
  onRemoveOrder: () => void
}

const ChannelInputPreview = ({
  files,
  onRemoveFile,
  inputRef,
  onAddedFileToken,
  selectedProductToAsk,
  onRemoveProduct,
  order,
  onRemoveOrder,
}: IChatFIle) => {
  const preview: File[] = files ? Array.from(files) : []

  const handleClickAdd = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  if (!preview?.length && !selectedProductToAsk && !order?.orderId) return null

  return (
    <div className="rounded-1 border-tertiary50 border border-x-0 border-b-0 px-3 text-sm">
      <div className="flex items-center overflow-x-auto whitespace-nowrap py-3">
        <div className="flex flex-nowrap">
          {selectedProductToAsk && (
            <div className="rounded-4 border-tertiary100 bg-tertiary25 w-27 group relative flex justify-between border p-2">
              <div className="flex">
                <div className="relative mr-3 h-10 w-10">
                  <Image
                    alt="product-image"
                    className="rounded-2 h-full w-full object-cover"
                    fill
                    src={selectedProductToAsk.productImage ?? ""}
                  />
                </div>
                <div>
                  <p className="text-balance line-clamp-1 text-ellipsis break-words text-start font-medium">
                    {selectedProductToAsk.productName}
                  </p>
                  <p className="font-bold">{`Rp. ${selectedProductToAsk.productPrice?.toLocaleString(
                    "id"
                  )}`}</p>
                </div>
              </div>
              <button
                className="text-tertiary50 absolute right-0 top-0 z-10 hidden -translate-y-1/2 translate-x-1/2 cursor-pointer group-hover:block"
                onClick={onRemoveProduct}
              >
                <XCircle fill="gray" className="h-5 w-5" />
              </button>
            </div>
          )}
          {order && (
            <div className="rounded-4 border-tertiary100 bg-tertiary25 w-27 group relative flex justify-between border p-2">
              <div className="flex">
                <div className="relative mr-3 h-10 w-10">
                  <Image
                    alt="product-image"
                    className="rounded-2 h-full w-full object-cover"
                    fill
                    src={order.productData.productImage ?? ""}
                  />
                </div>
                <div className="w-20">
                  <p className="text-xs">{`#${order.orderNumber}`}</p>
                  <p className="text-balance line-clamp-1 text-ellipsis break-words text-start font-medium">
                    {order.productData.productName}
                  </p>
                  <p className="font-bold">{`Rp. ${order.orderPrice?.toLocaleString(
                    "id"
                  )}`}</p>
                </div>
              </div>
              <button
                className="text-tertiary50 absolute right-0 top-0 z-10 hidden -translate-y-1/2 translate-x-1/2 cursor-pointer group-hover:block"
                onClick={onRemoveOrder}
              >
                <XCircle fill="gray" className="h-5 w-5" />
              </button>
            </div>
          )}
          {preview.map((item) => (
            <ChannelFilesPreviewUpload
              key={item.name}
              item={item}
              onRemoveFile={onRemoveFile}
              onAddedFileToken={onAddedFileToken}
            />
          ))}
          {!!preview?.length &&
            preview?.length < MAX_FILE_IN_SINGLE_CHAT_UPLOAD && (
              <div className="inline-block">
                <button
                  onClick={handleClickAdd}
                  className="rounded-3 border-tertiary50 bg-tertiary25 hover:shadow-3 relative h-14 w-14 cursor-pointer border-2 p-2 transition-shadow"
                >
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                    <Plus className="h-5 w-5" />
                  </div>
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default ChannelInputPreview
