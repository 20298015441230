import type {
  AdminMessage,
  FileMessage,
  UserMessage,
} from "@sendbird/chat/message"
import { useChannelContext } from "@sendbird/uikit-react/Channel/context"
import dynamic from "next/dynamic"
import { profanityErrorCode, useCurrentUser } from "../../utils/sendbird"
import { type CustomMessageType } from "./hooks/useMessages"

const FileMessageItemBody = dynamic(() => import("./FileMessageItemBody"))
const ProductMessageBody = dynamic(() => import("./ProductMessageBody"))
const TextMessageItemBody = dynamic(() => import("./TextMessageItemBody"))
const NegotiationMessageBody = dynamic(
  () => import("./negotiation/NegotiationMessageBody")
)
const BannedUrlMessageItemBody = dynamic(
  () => import("./BannedUrlMessageItemBody")
)
const OrderMessageBody = dynamic(() => import("./OrderMessageItemBody"))
const ProfanityMessageItemBody = dynamic(
  () => import("./ProfanityMessageItemBody")
)
const ImageModerationMessageItemBody = dynamic(
  () => import("./ImageModerationMessageItemBody")
)

interface IMessageContentProps {
  message: AdminMessage | FileMessage | UserMessage
  type: CustomMessageType
}

const MessageContent = ({ message, type }: IMessageContentProps) => {
  const { currentGroupChannel } = useChannelContext()
  const currentId = useCurrentUser()?.userId

  if (message.isAdminMessage()) {
    return <div></div>
  }
  const isByMe = currentId === message?.sender?.userId

  const messageData = message as UserMessage | FileMessage

  if (!messageData.messageId || messageData.errorCode === profanityErrorCode) {
    return null
  }

  const renderMessage = ({
    msg,
  }: {
    msg: UserMessage | FileMessage | AdminMessage
  }) => {
    switch (type) {
      case "ORDER":
        return (
          <OrderMessageBody
            message={msg as UserMessage}
            isByMe={isByMe}
            channel={currentGroupChannel}
          />
        )
      case "NEGOTIATION":
        return (
          <NegotiationMessageBody
            message={msg as UserMessage}
            isByMe={isByMe}
            channel={currentGroupChannel}
          />
        )
      case "PRODUCT":
        return (
          <ProductMessageBody
            message={msg as UserMessage}
            isByMe={isByMe}
            channel={currentGroupChannel}
          />
        )
      case "FILE":
        return (
          <FileMessageItemBody
            message={msg as FileMessage}
            isByMe={isByMe}
            channel={currentGroupChannel}
          />
        )
      case "TEXT":
        return (
          <TextMessageItemBody
            message={msg as UserMessage}
            isByMe={isByMe}
            channel={currentGroupChannel}
          />
        )
      case "SENSITIVE":
        return <ProfanityMessageItemBody isByMe={isByMe} />
      case "SENSITIVE_URL":
        return <BannedUrlMessageItemBody isByMe={isByMe} />
      case "SENSITIVE_IMAGE":
        return <ImageModerationMessageItemBody isByMe={isByMe} />
      case "HIDDEN":
        return <div></div>
      default:
        return <div></div>
    }
  }

  return (
    <div className={`my-3 flex items-end gap-2 ${isByMe ? "justify-end" : ""}`}>
      <div className="group grid gap-4">{renderMessage({ msg: message })}</div>
    </div>
  )
}

export default MessageContent
