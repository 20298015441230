import { GraphQLClient } from "graphql-request"
import { RequestMiddleware } from "graphql-request/build/esm/types"
import {
  IS_DEVELOPMENT,
  TRequest,
  graphqlClientMiddlewareV2,
} from "shared-utils"

export const GRAPHQL_URL = IS_DEVELOPMENT
  ? "https://account.eproc.dev/graphql"
  : "/graphql"

const middleware: RequestMiddleware = (request) => {
  const resultMiddleware = graphqlClientMiddlewareV2({
    request: request as TRequest,
    signOut: () => {
      return
    },
  })
  return resultMiddleware
}

export const federatedGqlClient = new GraphQLClient(GRAPHQL_URL, {
  requestMiddleware: middleware,
  credentials: "include",
})
