"use client"

import SendBirdChannel from "@sendbird/uikit-react/Channel"
import SendBirdChannelList from "@sendbird/uikit-react/ChannelList"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { cx } from "class-variance-authority"
import React, { useState } from "react"

import ChannelEmpty from "./components/channel/ChannelEmpty"
import ChannelHeader from "./components/channel/ChannelHeader"
import ChannelInput from "./components/channel/ChannelInput"
import ChannelSeparator from "./components/channel/ChannelSeparator"
import ChannelListEmpty from "./components/channelList/ChannelListEmpty"
import ChannelListHeader from "./components/channelList/ChannelListHeader"
import ChannelListLoading from "./components/channelList/ChannelListLoading"
import ChannelListPreview from "./components/channelList/ChannelListPreview"
import { getCustomMessageType } from "./components/message/hooks/useMessages"
import MessageContent from "./components/message/MessageContent"

import { useLocalStorageMessaging } from "notifications"
import useChat from "./hooks/useChat"
import { useChatStore } from "./store/chatStore"

export type SelectedProduct = {
  productId?: string
  productName?: string
  productImage?: string
  productPrice?: number
  seller: {
    sellerId: string
    sellerImage: string
    sellerName: string
  }
}

export interface IChatProps {
  noChannels: {
    title: string
    description: string
  }
  noSelectedChannel: {
    title: string
    description: string
  }
  placeholderSearchChannel: string
  wordingsTemplateChat: string[]
  sellerData?: {
    sellerId: string | undefined
    setSellerId: React.Dispatch<React.SetStateAction<string | undefined>>
  }
  slug?: string | null
}
const queryClient = new QueryClient()

const Chat = ({
  wordingsTemplateChat,
  noChannels,
  noSelectedChannel,
  placeholderSearchChannel,
  sellerData,
}: IChatProps) => {
  const { appType } = useLocalStorageMessaging()
  const {
    channelUrl,
    channelListQueries,
    latestMsgTimeStamp,
    currentPartner,
    searchKeyword,
    isEmptyChannel,
    seller,
    buyer,
    selectedChannelFilter,
    slug: currentSlug,
    setCurrentPartner,
    setSearchKeyword,
    setSelectedChannel,
    setChannelUrl,
    setIsEmptyChannel,
    setSelectedChannelFilter,
  } = useChat({
    sellerData,
  })

  const { setSelectedProductToAsk, setOrder, setQuotedMessage } = useChatStore()
  const showChannelList = !isEmptyChannel || !!buyer || !!seller
  const [isChannelRendered, setIsChannelRendered] = useState(false)
  const [isMaxPin, setIsMaxPin] = useState(false)

  return (
    <div className={cx("flex", !showChannelList && "h-full")}>
      {showChannelList && (
        <div className="rounded-l-6 border-tertiary50 w-[292px] overflow-hidden border">
          <SendBirdChannelList
            className="sendbird-channel-list-provider"
            disableAutoSelect
            queries={channelListQueries}
            renderHeader={() => (
              <ChannelListHeader
                placeholderSearchChannel={placeholderSearchChannel}
                onChangeSearch={setSearchKeyword}
                selectedChannelFilter={selectedChannelFilter}
                setSelectedChannelFilter={setSelectedChannelFilter}
                setIsChannelRendered={setIsChannelRendered}
              />
            )}
            renderPlaceHolderEmptyList={() => {
              return (
                <ChannelListEmpty
                  hasSearch={searchKeyword !== ""}
                  setIsEmptyChannel={
                    !!searchKeyword && !!selectedChannelFilter
                      ? setIsEmptyChannel
                      : undefined
                  }
                />
              )
            }}
            sortChannelList={(channelList) => {
              const pinnedChannelCount = channelList.filter(
                (channel) => channel.customType === "PINNED"
              ).length

              setIsMaxPin(pinnedChannelCount >= 3)

              return channelList.sort((a, b) => {
                const pinnedA = a.customType === "PINNED"
                const pinnedB = b.customType === "PINNED"
                if (pinnedA === pinnedB) {
                  return 0
                } else if (pinnedA) {
                  return -1
                } else {
                  return 1
                }
              })
            }}
            renderPlaceHolderLoading={ChannelListLoading}
            renderChannelPreview={({ channel }) => {
              const handleChannelChange = () => {
                setSelectedChannel(channel)
                setChannelUrl(channel.url)
                setSelectedProductToAsk(null, null)
                setOrder(null, null)
                setIsEmptyChannel(false)
                setQuotedMessage(null)
              }
              const isUnread = selectedChannelFilter?.value === "unread"
              const isRead = selectedChannelFilter?.value === "read"
              const isAll =
                !selectedChannelFilter || selectedChannelFilter.value === "all"

              if (
                (isUnread && !!channel.unreadMessageCount) ||
                (isRead && !channel.unreadMessageCount) ||
                isAll
              ) {
                return (
                  <ChannelListPreview
                    channel={channel}
                    active={channelUrl === channel.url}
                    onChange={handleChannelChange}
                    selectedChannelFilter={selectedChannelFilter}
                    setIsChannelRendered={setIsChannelRendered}
                    canPin={!isMaxPin}
                  />
                )
              }
              return (
                !isChannelRendered && (
                  <ChannelListEmpty
                    hasSearch={true}
                    setIsEmptyChannel={undefined}
                    showOneInstance={true}
                  />
                )
              )
            }}
          />
        </div>
      )}
      <div
        className={cx(
          `sendbird-room-chat-provider rounded-r-6 border-tertiary50 border-y border-r`,
          appType === "SELLER"
            ? "w-[51vw] max-w-[51vw]"
            : "w-[45vw] max-w-[45vw]",
          !showChannelList && "h-3/4 w-full max-w-none border-l"
        )}
      >
        <SendBirdChannel
          channelUrl={channelUrl}
          renderChannelHeader={() => (
            <ChannelHeader
              slug={currentSlug}
              user={currentPartner}
              setUser={setCurrentPartner}
            />
          )}
          renderCustomSeparator={({ message }) => (
            <ChannelSeparator message={message} />
          )}
          renderMessageInput={() => (
            <QueryClientProvider client={queryClient}>
              <ChannelInput
                channelUrl={channelUrl}
                chatTemplateOption={wordingsTemplateChat}
              />
            </QueryClientProvider>
          )}
          renderPlaceholderInvalid={() => (
            <ChannelEmpty
              urlImage="/assets/images/empty-chat.svg"
              title={noSelectedChannel.title}
              description={noSelectedChannel.description}
            />
          )}
          renderPlaceholderEmpty={() => (
            <ChannelEmpty
              urlImage="/assets/images/empty-channel-list.svg"
              title={noChannels.title}
              description={noChannels.description}
            />
          )}
          startingPoint={latestMsgTimeStamp}
          scrollBehavior="smooth"
          renderMessage={({ message }) => {
            const type = getCustomMessageType(message)
            return <MessageContent message={message} type={type} />
          }}
          replyType="QUOTE_REPLY"
        />
      </div>
    </div>
  )
}

export default Chat
