import FEEDS_IDENTIFIER from "../feeds/feeds"
import { type MessagingPayload } from "../types"

function useLocalStorageMessaging(
  appValue?: MessagingPayload
): MessagingPayload {
  const key = "notif-messaging"

  const deafultValue: MessagingPayload = {
    appType: "BUYER",
    feeds: FEEDS_IDENTIFIER.govmart,
  }

  const getLocalStorageData = (): MessagingPayload => {
    if (typeof window !== "undefined") {
      const item = window.localStorage.getItem(key)

      if (!item && appValue) {
        window.localStorage.setItem(key, JSON.stringify(appValue))

        return appValue as MessagingPayload
      }

      return item
        ? (JSON.parse(item) as MessagingPayload)
        : (appValue as MessagingPayload)
    }

    return deafultValue as MessagingPayload
  }

  return getLocalStorageData()
}

export default useLocalStorageMessaging
