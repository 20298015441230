import {
  useFeedUnseenCount,
  useFetchNotifications,
  useNotifications,
  type IMessage,
  type IStoreQuery,
} from "@novu/notification-center"
import useLocalStorageMessaging from "./useLocalstorage"

const useNotificationByFeed = (feeds: string | string[] | undefined) => {
  const localStorageData = useLocalStorageMessaging()

  const getFeedIdentifier = () => {
    if (feeds) {
      return feeds
    }
    return localStorageData.feeds
  }

  const query: IStoreQuery = {
    feedIdentifier: getFeedIdentifier(),
    limit: 10,
  }

  const { markAllNotificationsAsRead, markAllNotificationsAsSeen } =
    useNotifications()

  const markNotifications = () => {
    markAllNotificationsAsRead()
    markAllNotificationsAsSeen()
  }

  const { data, hasNextPage, fetchNextPage, isLoading } = useFetchNotifications(
    { query }
  )
  const feedUnseen = useFeedUnseenCount({ query })

  const pages = data?.pages || []

  const notifications = pages.reduce<IMessage[]>((acc, page) => {
    return [...acc, ...page.data]
  }, [])

  const isNotificationEmpty = notifications.length === 0

  return {
    notifications: notifications,
    unseenCount: feedUnseen.data?.count || 0,
    hasNextPage,
    fetchNextPage,
    isLoading,
    markNotifications,
    isNotificationEmpty,
  }
}

export default useNotificationByFeed
