import { NovuProvider } from "@novu/notification-center"
import dynamic from "next/dynamic"
import { PropsWithChildren } from "react"
import { useLocalStorageMessaging } from "../../hooks"
import { type AppType } from "../../types"

const ModalConfirmation = dynamic(() => import("../confirmation-modal"))

interface NotificationProviderProps extends PropsWithChildren {
  novuConfiguration: {
    novuAppId: string
    novuBackendUrl: string
    novuSocketUrl: string
  }
  subscriberId?: string
  subscriberHash?: string
  appType: AppType
  feeds: string | string[]
}

const NotificationProvider = (props: NotificationProviderProps) => {
  const { novuConfiguration, subscriberHash, subscriberId, appType, feeds } =
    props

  useLocalStorageMessaging({
    appType,
    feeds,
  })

  return (
    <NovuProvider
      applicationIdentifier={novuConfiguration.novuAppId}
      backendUrl={novuConfiguration.novuBackendUrl}
      socketUrl={novuConfiguration.novuSocketUrl}
      subscriberId={subscriberId}
      subscriberHash={subscriberHash}
      i18n="id"
      initialFetchingStrategy={{
        fetchNotifications: true,
        fetchUnseenCount: true,
      }}
    >
      {props.children}
      <ModalConfirmation />
    </NovuProvider>
  )
}

export default NotificationProvider
