"use client"

import { useNotifications, type IMessage } from "@novu/notification-center"
import { useRouter } from "next/navigation"

import usePersonaNotification from "../../store/usePersonaNotification"
import { getTimeDifference } from "../../utils/common"

interface Notification extends IMessage {
  payload: {
    targetUrl: string
    invoiceNumber: string
    nomorPesanan: string
    personaId?: string
    satkerName?: string
    role?: string
  }
}

interface INotificationCardProps {
  notification: IMessage
  isOpenModalNotification?: boolean
  handleCloseModalNotification?: () => void
}

const NotificationCard = ({
  notification,
  isOpenModalNotification,
  handleCloseModalNotification,
}: INotificationCardProps) => {
  const router = useRouter()
  const { markNotificationAsSeen } = useNotifications()
  const date = new Date(notification.createdAt)
  const isSeen = notification.seen
  const { userData, setOpenModal } = usePersonaNotification()

  const newNotification = notification as Notification

  // Check if notification has payload
  if (!newNotification.payload) {
    return null
  }

  const isPersonaNotif =
    newNotification?.payload.personaId &&
    newNotification?.payload.satkerName &&
    newNotification?.payload.role

  const isYourPersonaNotif =
    isPersonaNotif && newNotification?.payload?.personaId === userData.personaId

  const handleClickNotification = (id: string) => {
    if (!isPersonaNotif || isYourPersonaNotif) {
      if (isOpenModalNotification) {
        handleCloseModalNotification?.()
      }
      markNotificationAsSeen(id)

      const targetUrl =
        newNotification?.payload?.targetUrl ||
        newNotification.cta.data.url ||
        "/"

      router.push(targetUrl)
    } else {
      setOpenModal(true, {
        personaId: newNotification.payload?.personaId || "",
        satker: newNotification.payload?.satkerName || "",
        role: newNotification.payload?.role || "",
      })
    }
  }

  return (
    <button
      className={`rounded-2 flex max-w-full cursor-pointer flex-col gap-1 p-2 ${
        !isSeen && "bg-secondary50"
      } ${isPersonaNotif && !isYourPersonaNotif && "opacity-30"}`}
      onClick={() => handleClickNotification(notification._id)}
    >
      {isPersonaNotif && (
        <span className="text-tertiary300 bg-tertiary25 rounded-4 w-max px-1 text-sm font-semibold">
          {newNotification.payload?.role?.toUpperCase()}
        </span>
      )}
      <p className="text-caption-sm-regular text-tertiary300 mb-auto">
        {newNotification.payload.invoiceNumber ||
          newNotification.payload.nomorPesanan}
      </p>

      <h1
        className={`text-tertiary500 line-clamp-2 w-full text-left text-sm font-semibold`}
      >
        {newNotification.content.toString()}
      </h1>

      <p className="text-caption-sm-regular text-tertiary300 mt-auto">
        {getTimeDifference(date)}
      </p>
    </button>
  )
}

export default NotificationCard
