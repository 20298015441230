"use client"

import type { MouseEvent } from "react"

interface IChatOption {
  options?: string[]
  onClick?: (item: string) => void
}

const ChannelInputOptions = ({
  options = [],
  onClick = () => {
    return
  },
}: IChatOption) => {
  if (!options.length) return <></>

  const handleClickTemplate = (e: MouseEvent<HTMLDivElement>, item: string) => {
    e.preventDefault()
    e.stopPropagation()
    onClick(item)
  }

  return (
    <div className="rounded-1 border-tertiary50 border border-x-0 border-b-0 p-3 text-sm">
      <div className="flex overflow-x-auto whitespace-nowrap">
        <div className="flex flex-nowrap">
          {options.map((item, idx) => (
            <div key={`${item}-${idx}`} className="inline-block">
              <div
                onClick={(e) => handleClickTemplate(e, item)}
                className="w-18 rounded-3 border-tertiary50 text-tertiary500 hover:border-tertiary300 hover:bg-tertiary25 mr-4 inline-block cursor-pointer truncate border px-3 py-2"
              >
                <p className="truncate text-center">{item}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChannelInputOptions
