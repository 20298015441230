import React, { MouseEvent } from "react"
import Image from "next/image"
import Link from "next/link"
import { ChevronRight } from "react-feather"
import { useLocalStorageMessaging, useNotificationByFeed } from "../../hooks"

import NotificationCard from "../notification-card"
import { CDN_URL } from "shared-config/clientConfig"
import { useRouter } from "next/navigation"

interface INotificationModalProps {
  isOpen: boolean
  onClose: () => void
  isBendahara?: boolean
}

const NotificationModal = (props: INotificationModalProps) => {
  const { isOpen, onClose, isBendahara = false } = props
  const {
    fetchNextPage,
    hasNextPage,
    notifications,
    markNotifications,
    isNotificationEmpty,
  } = useNotificationByFeed(undefined)
  const localStorageData = useLocalStorageMessaging()
  const router = useRouter()

  const isBuyer = localStorageData.appType === "BUYER"

  const handleFetchOnScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    const scrolling = Math.floor(target.scrollHeight - target.scrollTop)

    if (scrolling <= target.clientHeight && hasNextPage) {
      fetchNextPage()
    }
  }

  const handleClickCta = (
    event: MouseEvent<HTMLButtonElement>,
    path: string
  ) => {
    event.preventDefault()
    onClose()
    router.push(path)
  }

  return (
    <div className="grid w-[430px] gap-4 p-4">
      <div className="flex h-12 items-center justify-between">
        <h1 className="text-body-lg-bold text-tertiary500">Notifikasi</h1>

        {!isNotificationEmpty && (
          <button
            className="text-caption-sm-semibold text-secondary500"
            onClick={() => markNotifications()}
          >
            Tandai Semua Dibaca
          </button>
        )}
      </div>

      {!isNotificationEmpty && (
        <section className="flex h-[30vh] max-h-full w-full flex-col gap-3">
          <div
            className="notification_wrapper divide-tertiary25 flex flex-grow flex-col gap-1 divide-y-2 overflow-hidden overflow-y-auto"
            onScroll={handleFetchOnScroll}
          >
            {notifications?.map((item) => (
              <NotificationCard
                key={item._id}
                isOpenModalNotification={isOpen}
                handleCloseModalNotification={onClose}
                notification={item}
              />
            ))}
          </div>

          <p className="text-caption-sm-regular text-tertiary300 text-center">
            Semua notifikasi Anda didasarkan pada periode 30 hari terakhir.
          </p>
        </section>
      )}

      {isNotificationEmpty && (
        <div className="flex flex-col items-center justify-center gap-6 text-center">
          <Image
            alt="empty-notification-image"
            src={`${CDN_URL}/images/empty-notification.svg`}
            width={392}
            height={235}
          />
          <div>
            <h1 className="text-body-lg-bold text-tertiary500">
              Belum ada notifikasi
            </h1>
            <p className="text-body-sm-regular text-tertiary300 mt-4">
              Notifikasi transaksi akan muncul di sini.
            </p>
          </div>
          {isBuyer && (
            <button
              className="text-caption-lg-semibold rounded-4 bg-primary500 text-primary25 flex h-10 items-center gap-2 px-3"
              onClick={(e) => handleClickCta(e, "/")}
            >
              Mulai Belanja Sekarang <ChevronRight size={20} />
            </button>
          )}
        </div>
      )}

      <Link
        className="text-caption-lg-semibold text-secondary500 flex h-10 w-full items-center justify-center"
        href={isBendahara ? "/bendahara/notification" : "/notification"}
        onClick={onClose}
      >
        Lihat Selengkapnya
      </Link>
    </div>
  )
}

export default NotificationModal
