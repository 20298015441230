"use client"

import { AlertTriangle, FilePlus, Send } from "react-feather"

import { cx } from "class-variance-authority"
import React from "react"
import useChannelInput from "../../hooks/useChannelInput"
import ChannelInputPreview from "./ChannelInputPreview"
import ChannelInputOptions from "./ChannelInputOptions"
import ChannelQuoteReplyPreview from "./ChannelQuoteReplyPreview"
import { ACCEPTED_INPUT } from "../../utils/common"

export type IChannelInputValue = {
  message: string
  files?: File[]
}

interface IChannelInputProps {
  channelUrl: string
  chatTemplateOption?: string[]
}

const ChannelInput = ({
  chatTemplateOption = [],
  channelUrl,
}: IChannelInputProps) => {
  const {
    buttonRef,
    fileRef,
    files,
    message,
    messageRef,
    selectedProductToAsk,
    order,
    isMessageInputError,
    handleRemoveOrder,
    handleButtonDisabled,
    handleClickChatTemplate,
    handleDeleteFile,
    handleRemoveProduct,
    handleUploadFile,
    onSubmit,
    handleMessage,
    handleAddFileToken,
    handleOnPressEnter,
    handlePaste,
  } = useChannelInput({
    channelUrl,
  })

  return (
    <form noValidate onSubmit={onSubmit}>
      <ChannelInputOptions
        options={chatTemplateOption}
        onClick={handleClickChatTemplate}
      />
      <ChannelQuoteReplyPreview />
      <ChannelInputPreview
        files={files}
        onRemoveFile={handleDeleteFile}
        inputRef={fileRef}
        onAddedFileToken={handleAddFileToken}
        selectedProductToAsk={selectedProductToAsk}
        onRemoveProduct={handleRemoveProduct}
        order={order}
        onRemoveOrder={handleRemoveOrder}
      />
      <div className="rounded-2 rounded-br-6 border-tertiary50 rounded-t-none border border-x-0 text-sm">
        <div
          className={cx("flex", "w-full", "flex-row", "items-center", {
            "px-3 pt-3": isMessageInputError,
            "p-3": !isMessageInputError,
          })}
        >
          <div className="relative flex-1">
            <textarea
              value={message}
              ref={messageRef}
              onChange={handleMessage}
              onKeyDown={(e) => handleOnPressEnter(e)}
              placeholder="Tulis pesan di sini.."
              className={cx(
                "rounded-2",
                "focus:shadow-0",
                "w-full",
                "resize-none",
                "border",
                "py-2",
                "pl-4",
                "pr-14",
                "text-gray-900",
                "focus:outline-none",
                {
                  "border-error500": isMessageInputError,
                  "border-tertiary50": !isMessageInputError,
                  "focus:border-tertiary300": !isMessageInputError,
                }
              )}
              onPaste={handlePaste}
            />
            <div
              style={{
                top: "2px",
                bottom: "2px",
              }}
              className="absolute right-[10px] flex items-center"
            >
              <label
                htmlFor="upload-file"
                className="border-l-tertiary50 cursor-pointer border-l-2"
              >
                <FilePlus className="text-tertiary500 mx-2 h-5 w-5" />
              </label>
              <input
                accept={ACCEPTED_INPUT.join(",")}
                ref={fileRef}
                id="upload-file"
                onChange={handleUploadFile}
                multiple
                type="file"
                className="hidden"
              />
            </div>
          </div>
          <div className=" pl-3">
            <button
              type="submit"
              ref={buttonRef}
              id="chat-send-message"
              disabled={handleButtonDisabled()}
              className="rounded-2 bg-primary500 text-warning25 hover:bg-primary700 disabled:bg-tertiary25 disabled:text-tertiary200 flex cursor-pointer items-center p-3 font-bold transition-colors ease-in-out disabled:cursor-default"
            >
              <Send className="h-6 w-6" />
            </button>
          </div>
        </div>
        {isMessageInputError && (
          <div className="flex items-center px-3 py-2">
            <AlertTriangle size={14} className="text-error500" />
            <p className="text-error500 ml-2">
              Karakter mencapai batas maksimal (4000) karakter
            </p>
          </div>
        )}
      </div>
    </form>
  )
}

export default ChannelInput
