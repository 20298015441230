import { ChannelListProvider as CHListProvider } from "@sendbird/uikit-react/ChannelList/context"
import { PropsWithChildren, useEffect } from "react"
import { useCurrentUser } from "../../utils/sendbird"

type ChannelListProviderProps = {
  slug?: string
} & PropsWithChildren

type UserMetaData = {
  slug: string
}

const ChannelListProvider = (props: ChannelListProviderProps) => {
  const { slug } = props
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (slug && currentUser) {
      const metadata = currentUser.metaData as UserMetaData

      if (metadata?.slug !== slug) {
        currentUser.updateMetaData({ slug })
      }

      if (!metadata?.slug) {
        currentUser.createMetaData({ slug })
      }
    }
  }, [slug, currentUser])

  return (
    <CHListProvider className={"sendbird-channel-list-reset"}>
      {props.children}
    </CHListProvider>
  )
}

export default ChannelListProvider
