import { cx } from "class-variance-authority"
import React from "react"
import { ChevronDown, Filter } from "react-feather"
import { Dropdown, DropdownItem } from "shared-ui"

import { OptionsType } from "../../hooks/useChat"
import ChannelListEmpty from "./ChannelListSearch"

interface IChannelListHeaderProps {
  placeholderSearchChannel: string
  onChangeSearch: (value: string) => void
  selectedChannelFilter?: OptionsType
  setSelectedChannelFilter: React.Dispatch<
    React.SetStateAction<OptionsType | undefined>
  >
  setIsChannelRendered: React.Dispatch<React.SetStateAction<boolean>>
}

const channelFilterOptions: OptionsType[] = [
  { label: "Semua percakapan", value: "all" },
  { label: "Sudah dibaca", value: "read" },
  { label: "Belum dibaca", value: "unread" },
]

const ChannelListHeader = ({
  placeholderSearchChannel,
  onChangeSearch,
  selectedChannelFilter,
  setSelectedChannelFilter,
  setIsChannelRendered,
}: IChannelListHeaderProps) => {
  return (
    <div className="pt-4">
      <p className="text-tertiary500 text-lg font-bold">Chat</p>
      <div className="mt-4 flex h-10 flex-row gap-2">
        <ChannelListEmpty
          placeholder={placeholderSearchChannel}
          onChange={onChangeSearch}
          isLabelShown={!selectedChannelFilter}
          onClick={() => setSelectedChannelFilter(undefined)}
        />
        <Dropdown
          trigger={
            <button
              className={cx(
                "rounded-4 border-tertiary50 h-10 items-center justify-center border p-2",
                selectedChannelFilter ? "min-w-[208px]" : "w-10"
              )}
            >
              {selectedChannelFilter ? (
                <div className="flex min-w-max items-center justify-between gap-4 px-2">
                  <span>{selectedChannelFilter.label}</span>
                  <ChevronDown size={16} />
                </div>
              ) : (
                <Filter size={20} />
              )}
            </button>
          }
          position="bottom"
          positionAlign="end"
        >
          {channelFilterOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => {
                setSelectedChannelFilter(option)
                setIsChannelRendered(false)
              }}
              classes={{
                content: "hover:text-secondary500 cursor-pointer",
              }}
            >
              {option.label}
            </DropdownItem>
          ))}
        </Dropdown>
      </div>
      <hr className="border-tertiary100 my-6 border-r" />
    </div>
  )
}

export default ChannelListHeader
