"use client"
import { useLocalStorageMessaging } from "notifications"
import { useEffect, useState } from "react"
import { useChatStore } from "../store/chatStore"
import { useGetInstitutionById } from "./useGetInstitutionById"

const useChatProvider = ({
  name,
  userId,
}: {
  name?: string
  userId: string
}) => {
  const { appType } = useLocalStorageMessaging()
  const { setIsLoading, setSenderName } = useChatStore()
  const [slug, setSlug] = useState("")

  useEffect(() => {
    const fetchStore = async () => {
      const institution = await useGetInstitutionById({ id: userId })
  
      if (institution) {
        setSlug(institution?.company?.slug ?? "")
        setIsLoading(false)
      }
    }
    if (appType === "SELLER") {
      fetchStore()
    } else {
      setIsLoading(false)
    }
    setSenderName(name ?? "")
  }, [appType, userId, name])

  return { slug }
}

export default useChatProvider
