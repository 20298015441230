import type { GroupChannel } from "@sendbird/chat/groupChannel"
import type { BaseMessage } from "@sendbird/chat/message"
import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context"
import Image from "next/image"
import React, { useEffect, useMemo, useState, type MouseEvent } from "react"

import { useSnackbar, Dropdown } from "shared-ui"
import { MoreVertical } from "react-feather"

import PinIcon from "shared-assets/icons/pin-icon.webp"
import { OptionsType } from "../../hooks/useChat"
import { getTimestampChat } from "../../utils/common"
import { useCurrentUser } from "../../utils/sendbird"

interface CustomMessage extends BaseMessage {
  message?: string
  name?: string
}

interface IChannelListPreviewProps {
  channel: GroupChannel
  active: boolean
  onChange: () => void
  selectedChannelFilter?: OptionsType
  setIsChannelRendered?: React.Dispatch<React.SetStateAction<boolean>>
  canPin?: boolean
}

interface PinMetadata {
  pinned: "true" | "false"
}

const ChannelListPreview = ({
  channel,
  active,
  onChange,
  selectedChannelFilter,
  setIsChannelRendered,
  canPin = true,
}: IChannelListPreviewProps) => {
  const [isItemHovered, setIsItemHovered] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { enqueueSnackbar } = useSnackbar()
  const { channelListQuery } = useChannelListContext()
  const currentId = useCurrentUser()?.userId
  const userChatOpponent = channel.members.find(
    (item) => item.userId !== currentId
  )

  const latestMessage = channel.lastMessage as CustomMessage

  const channelActive = active ? "bg-tertiary25" : ""

  const channelName = useMemo(() => {
    const keyword = channelListQuery?.nicknameContainsFilter
    const nickname = userChatOpponent?.nickname
    const idx = nickname
      ?.toLocaleLowerCase()
      .indexOf(keyword?.toLocaleLowerCase() || "")

    if (typeof idx == "number" && idx >= 0) {
      const newText = keyword && [
        nickname?.substring(0, idx),
        <span key={idx} className="text-primary500">
          {nickname?.substring(idx, idx + keyword?.length)}
        </span>,
        nickname?.substring(idx + keyword?.length),
      ]

      return newText
    }
  }, [channelListQuery?.nicknameContainsFilter, userChatOpponent?.nickname])

  const handleChannelChange = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    onChange()
  }

  const handlePreviewMessage = (msg: CustomMessage) => {
    const { customType, message, name } = msg
    const sensitiveType = ["SENSITIVE", "SENSITIVE_URL", "SENSITIVE_IMAGE"]
    const productType = ["PRODUCT", "ORDER", "NEGOTIATION"]

    if (sensitiveType.includes(customType)) return "-"
    if (productType.includes(customType)) return message ?? name ?? "-"
    return message ?? name
  }

  const handlePinChannel = () => {
    if (isPinned) {
      channel.updateChannel({
        customType: "UNPINNED",
      })
      enqueueSnackbar({
        type: "success",
        message: "Pin pada percakapan berhasil dilepas.",
      })
    } else if (canPin && !isPinned) {
      channel.updateChannel({
        customType: "PINNED",
      })
      enqueueSnackbar({
        type: "success",
        message: "Percakapan berhasil dipin.",
      })
    } else {
      enqueueSnackbar({
        type: "error",
        message: "Maksimal pin 3 percakapan.",
      })
    }
  }

  const toogle = () => setIsOpen((prev) => !prev)
  const onClose = () => {
    setIsOpen(false)
    setIsItemHovered(false)
  }

  useEffect(() => {
    if (channel.customType !== "PINNED") {
      channel.updateChannel({
        customType: "UNPINNED",
      })
    }
  }, [])

  useEffect(() => {
    if (setIsChannelRendered) {
      setIsChannelRendered(true)
    }
  }, [selectedChannelFilter])

  const isPinned = channel.customType === "PINNED"

  return (
    <div
      className={`${channelActive} ${
        isOpen ? "bg-tertiary25" : ""
      } rounded-4 bg-primary25 hover:bg-tertiary25 relative mx-auto mb-2 flex h-[69px] w-full cursor-pointer items-center gap-2 p-3`}
      onClick={handleChannelChange}
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
    >
      <div className="relative flex-shrink-0">
        <Image
          className="h-10 w-10 rounded-full"
          src={userChatOpponent?.plainProfileUrl || channel?.coverUrl}
          alt="cover-channel"
          width={40}
          height={40}
        />
        {isPinned && (
          <div className="bg-primary25 absolute bottom-0 right-0 flex h-4 w-4 transform items-center justify-center rounded-full">
            <div className="bg-tertiary50 h-3 w-3 rounded-full">
              <Image src={PinIcon} alt="pin" width={20} height={20} />
            </div>
          </div>
        )}
      </div>

      <div style={{ width: "188px" }}>
        <div className="flex h-6 items-center gap-2">
          <h1 className="text-tertiary500 overflow-hidden text-ellipsis whitespace-nowrap font-semibold">
            {channelName || userChatOpponent?.nickname}
          </h1>
          <div className="text-tertiary300 ml-auto whitespace-nowrap text-right text-xs">
            {getTimestampChat(latestMessage.createdAt)}
          </div>
        </div>

        <div className="mt-0 flex gap-2">
          <p
            className="text-tertiary300 overflow-hidden text-ellipsis whitespace-nowrap text-sm"
            style={{ width: "163px" }}
          >
            {handlePreviewMessage(latestMessage)}
          </p>
          {channel.unreadMessageCount > 0 && (
            <p
              className="bg-primary400 text-primary25 rounded-full px-1 text-center text-sm"
              style={{ minWidth: "21px", height: "21px" }}
            >
              {channel.unreadMessageCount}
            </p>
          )}
          {(isItemHovered || isOpen) && (
            <div className="right-2">
              <Dropdown
                trigger={
                  <button
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      toogle()
                    }}
                    className="outline-none"
                  >
                    <MoreVertical size={15} />
                  </button>
                }
                open={isOpen}
                onOpenChange={(isOpen) => {
                  setIsOpen(isOpen)
                }}
                positionAlign="end"
                positionOffset={25}
              >
                <div className=" w-[245px] p-2">
                  <button
                    className="hover:bg-tertiary25 hover:text-secondary500 text-tertiary500 rounded-2 w-full px-3 py-2 text-start"
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      handlePinChannel()
                      onClose()
                    }}
                  >
                    <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                      {isPinned ? "Lepaskan Pin" : "Pin Percakapan"}
                    </p>
                  </button>
                  <button
                    className="hover:bg-tertiary25 hover:text-secondary500 text-tertiary500 rounded-2 w-full px-3 py-2 text-start"
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      channel.markAsRead()
                      onClose()
                    }}
                  >
                    <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                      Tandai sudah dibaca
                    </p>
                  </button>
                </div>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChannelListPreview
