import Image from "next/image"
interface IChannelEmptyProps {
  urlImage: string
  title: string
  description: string
}

const ChannelEmpty = ({ urlImage, title, description }: IChannelEmptyProps) => {
  return (
    <div className="bg-tertiary25 rounded-r-6 flex h-full w-full flex-col items-center justify-center gap-6 px-4 text-center">
      <Image
        className="chat-illus h-auto"
        src={urlImage}
        alt="image empty channel list"
        width={0}
        height={0}
        priority
      />
      <div>
        <h1 className=" text-tertiary500 text-lg font-bold">{title}</h1>
        <p className="text-tertiary300 mt-2 text-sm">{description}</p>
      </div>
    </div>
  )
}

export default ChannelEmpty
