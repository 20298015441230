import Image from "next/image"
import React from "react"
import { X, File as IconFile } from "react-feather"
import type { FileMessage, UserMessage } from "@sendbird/chat/message"

import { useChatStore } from "../../store/chatStore"
import { isFile as checkIsFile, formatBytes } from "../../utils/common"
import { fileMessageSchema } from "../message/messageSchema"

const ChannelQuoteReplyPreview = () => {
  const { quotedMessage, setQuotedMessage } = useChatStore()

  const isUserMessage = (
    message: typeof quotedMessage
  ): message is UserMessage => (message as UserMessage)?.message !== undefined
  const isText = isUserMessage(quotedMessage)

  const isFileMessage = (
    message: typeof quotedMessage
  ): message is FileMessage => (message as FileMessage)?.url !== undefined
  const isFile = isFileMessage(quotedMessage) && checkIsFile(quotedMessage.name)
  const isImage =
    isFileMessage(quotedMessage) && !checkIsFile(quotedMessage.name)

  const fileMetaData = isFile
    ? fileMessageSchema.parse(JSON.parse(quotedMessage?.data ?? ""))
    : undefined
  const fileExtension = fileMetaData
    ? fileMetaData.fileName.split(".").pop()?.toUpperCase()
    : undefined
  const fileSize = fileMetaData
    ? formatBytes(fileMetaData.fileSize, 0).toUpperCase()
    : undefined

  return (
    quotedMessage && (
      <div className="px-3 pb-3">
        <div className="rounded-2 bg-secondary50 relative flex max-h-[120px] w-full flex-row p-2">
          {isImage && (
            <Image
              className="!relative !mr-4 !h-12 !w-12"
              src={quotedMessage.url}
              alt="image-sendbird-quote-preview"
              layout="fill"
              objectFit="contain"
            />
          )}
          <div className="flex w-full flex-col">
            <span className="text-secondary400 text-2xs">
              {quotedMessage?.sender.nickname}
            </span>
            <div className="line-clamp-2">
              {isText || isImage ? (
                <span className="font-xs text-tertiary300 leading-4">
                  {isText ? quotedMessage.message : quotedMessage.name}
                </span>
              ) : (
                isFile && (
                  <div className="bg-primary25 rounded-2 mt-3 flex w-full flex-row p-2">
                    <div className="flex h-10 w-10 items-center justify-center">
                      <IconFile className="mr-4 h-5 w-5" />
                    </div>
                    <div>
                      <p className="text-tertiary500 text-balance line-clamp-2 max-w-full overflow-hidden text-ellipsis whitespace-nowrap break-words text-sm font-semibold">
                        {quotedMessage.name}
                      </p>
                      <p className="text-tertiary500 break-words text-xs">
                        {`${fileExtension}`} &#x2022; {`${fileSize}`}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
            <button
              className="absolute right-1 top-1"
              onClick={() => setQuotedMessage(null)}
            >
              <X size="16px" className="text-tertiary300" />
            </button>
          </div>
        </div>
      </div>
    )
  )
}

export default ChannelQuoteReplyPreview
