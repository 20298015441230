import { FileMessage, UserMessage } from "@sendbird/chat/message"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

export type SellerChat = {
  sellerId: string
  sellerName: string
  sellerImage?: string
}

export type BuyerChat = {
  buyerId: string
  buyerName?: string
  buyerImage?: string
}

export type SelectedProduct = {
  productId?: string
  productName?: string
  productImage?: string
  productPrice?: number
  productSlug?: string
  sellerSlug?: string
}

export type OrderType =
  | "negotiation"
  | "order"
  | "review"
  | "negotiation-review"

export type SelectedOrder = {
  orderId: string
  orderNumber: string
  orderPrice: number
  orderKey: string
  productData: SelectedProduct
  type: OrderType
  negotiationId?: string
}

export type ChatStore = {
  isLoading: boolean
  senderName: string
  seller: SellerChat | null
  buyer: BuyerChat | null
  selectedProductToAsk: SelectedProduct | null
  order: SelectedOrder | null
  quotedMessage: UserMessage | FileMessage | null
  messageToSent: string
  setIsLoading: (params: boolean) => void
  setSenderName: (params: string) => void
  setSelectedProductToAsk: (
    product: SelectedProduct | null,
    seller: SellerChat | null
  ) => void
  setSeller: (params: SellerChat | null) => void
  setBuyer: (params: BuyerChat | null) => void
  setOrder: (
    target: BuyerChat | SellerChat | null,
    params: SelectedOrder | null
  ) => void
  setQuotedMessage: (params: UserMessage | FileMessage | null) => void
  setMessageToSent: (
    target: BuyerChat | SellerChat | null,
    params: string
  ) => void
}

export const useChatStore = create<ChatStore>()(
  devtools(
    (set) => ({
      isLoading: true,
      senderName: "",
      seller: null,
      buyer: null,
      selectedProductToAsk: null,
      order: null,
      quotedMessage: null,
      messageToSent: "",
      setIsLoading: (params) => set(() => ({ isLoading: params })),
      setSenderName: (params) => set(() => ({ senderName: params })),
      setSelectedProductToAsk: (product, seller) => {
        set(() => ({ selectedProductToAsk: product }))
        if (seller) {
          set(() => ({ seller }))
        }
      },
      setSeller: (params) => set(() => ({ seller: params })),
      setBuyer: (params) => set(() => ({ buyer: params })),
      setOrder: (target, params) => {
        set(() => ({ order: params, selectedProductToAsk: null }))
        if (target) {
          if ("buyerId" in target) {
            set(() => ({ buyer: target }))
          } else {
            set(() => ({ seller: target }))
          }
        } else {
          set(() => ({ buyer: null, seller: null }))
        }
      },
      setQuotedMessage: (params) => set(() => ({ quotedMessage: params })),
      setMessageToSent: (target, params) => {
        set(() => ({ messageToSent: params }))
        if (target) {
          if ("buyerId" in target) {
            set(() => ({ buyer: target }))
          } else {
            set(() => ({ seller: target }))
          }
        }
      },
    }),
    {
      name: "chat-storage",
    }
  )
)
