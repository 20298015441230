"use client"

import { GroupChannel } from "@sendbird/chat/groupChannel"
import { useEffect, useState } from "react"

const useChatNotification = (allChannels: GroupChannel[]) => {
  const [notificationCount, setNotificationCount] = useState<number>(0)

  useEffect(() => {
    let totalNotification = 0

    allChannels.map((item) => {
      const hasUnreadMessage = item.unreadMessageCount > 0
      if (hasUnreadMessage) totalNotification += 1
    })

    setNotificationCount(totalNotification)
  }, [allChannels])

  return notificationCount
}

export default useChatNotification
