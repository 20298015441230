import { createMutation } from "react-query-kit"

import { graphql } from "../federatedGql"
import type {
  UploadChatFileInput,
  UploadChatUrlInput,
} from "../federatedGql/graphql"
import { federatedGqlClient } from "../utils/graphqlClient"

export const UPLOAD_CHAT_URL = graphql(`
  mutation UploadChatUrl($input: UploadChatUrlInput!) {
    uploadChatUrl(input: $input) {
      ... on UploadChatUrlResp {
        uploadUrl
        jwtToken
        __typename
      }
      ... on GenericError {
        reqId
        code
        message
        __typename
      }
    }
  }
`)

export const UPLOAD_CHAT_FILE = graphql(`
  mutation UploadChatFile($input: UploadChatFileInput!) {
    uploadChatFile(input: $input) {
      ... on UploadChatFileResp {
        result {
          success
          message
          __typename
        }
        __typename
      }
      ... on GenericError {
        reqId
        code
        message
        __typename
      }
    }
  }
`)

export const useUploadChatUrl = createMutation({
  mutationFn: async (input: UploadChatUrlInput) => {
    const res = await federatedGqlClient.request(UPLOAD_CHAT_URL, {
      input,
    })
    return res
  },
})

export const useUploadChatFile = createMutation({
  mutationFn: async (input: UploadChatFileInput) => {
    const res = await federatedGqlClient.request(UPLOAD_CHAT_FILE, {
      input,
    })
    if (res.uploadChatFile.__typename === "UploadChatFileResp") {
      const errorMessage = res.uploadChatFile.result.find(
        (v) => !v.success
      )?.message
      if (errorMessage) {
        throw Error(errorMessage)
      }
    }
    return res
  },
})
