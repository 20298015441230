import "./Chat.css"

import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider"
import { PropsWithChildren } from "react"

import ChannelListProvider from "./components/channelList/ChannelListProvider"
import useChatProvider from "./hooks/useChatProvider"
import { useChatStore } from "./store/chatStore"

interface ISendbirdProvider {
  appId: string
  userId: string
  name: string
}

const SBProvider = ({
  appId,
  userId,
  children,
  name,
}: ISendbirdProvider & PropsWithChildren) => {
  const { isLoading } = useChatStore()
  const { slug } = useChatProvider({ userId, name })

  if (!userId || isLoading) {
    return <>{children}</>
  }

  return (
    <SendbirdProvider appId={appId} userId={userId ?? ""}>
      <ChannelListProvider slug={slug}>{children}</ChannelListProvider>
    </SendbirdProvider>
  )
}

export default SBProvider
