"use client"

import Image from "next/image"
import React, { MouseEvent } from "react"
import { ChevronRight } from "react-feather"
import EmptyNotification from "shared-assets/images/empty-notification.webp"

import NotificationCard from "../notification-card"

import { useRouter } from "next/navigation"
import { Skeleton } from "shared-ui"
import { useLocalStorageMessaging, useNotificationByFeed } from "../../hooks"
import "./styles.css"

const NotificationList = () => {
  const { notifications, isLoading, fetchNextPage, hasNextPage } =
    useNotificationByFeed(undefined)

  const localStorageData = useLocalStorageMessaging()
  const router = useRouter()

  const { appType } = localStorageData

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    const scrolling = Math.floor(target.scrollHeight - target.scrollTop)

    if (scrolling <= target.clientHeight && hasNextPage) {
      fetchNextPage()
    }
  }

  const isEmptyNotification = !notifications?.length

  const handleClickCta = (
    event: MouseEvent<HTMLButtonElement>,
    path: string
  ) => {
    event.preventDefault()
    router.push(path)
  }

  return (
    <>
      {isEmptyNotification ? (
        <div className=" flex flex-col items-center justify-center gap-6 text-center">
          <Image
            alt="empty-notification-image"
            src={EmptyNotification}
            width={392}
            height={235}
          />
          <div>
            <h1 className="text-body-lg-bold text-tertiary500">
              Belum ada notifikasi
            </h1>
            <p className="text-body-sm-regular text-tertiary300 mt-4">
              Notifikasi transaksi akan muncul di sini.
            </p>
          </div>
          {appType === "BUYER" && (
            <button
              className="text-caption-lg-semibold rounded-4 bg-primary500 text-primary25 flex h-10 items-center gap-2 px-3"
              onClick={(e) => handleClickCta(e, "/")}
            >
              Mulai Belanja Sekarang <ChevronRight size={20} />
            </button>
          )}
        </div>
      ) : (
        <section className="rounded-4 shadow-2 mt-4 overflow-hidden p-8">
          <div
            className="divide-tertiary25 h-50vh notification-list-wrapper flex flex-col gap-1 divide-y-2 overflow-y-scroll"
            onScroll={handleScroll}
          >
            {isLoading
              ? Array.from({ length: 8 }).map((_, i) => (
                  <div key={i} className="rounded-2 h-16 overflow-hidden">
                    <Skeleton bgColor="bg-tertiary50" />
                  </div>
                ))
              : notifications?.map((item) => (
                  <NotificationCard key={item._id} notification={item} />
                ))}
          </div>
        </section>
      )}
    </>
  )
}

export default NotificationList
